import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import classNames from 'classnames/bind';
import styles from './chat.module.scss';
const cx = classNames.bind(styles);

function SendMessage({ onhandleOk }) {
    const [sendRequest, setSendRequest] = useState(false);
    const tasknameInputRef = React.useRef(null);
    React.useEffect(() => {
        tasknameInputRef.current.focus();
        if (sendRequest) {
            setSendRequest(false);
        }
    }, [sendRequest]);
    const layout = {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 },
    };
    const [form] = Form.useForm();
    const onFinish = (value) => {
        if (value.content !== undefined) {
            form.resetFields();
            onhandleOk(value); 
            setSendRequest(true);
        }
    };
    function handleKeyDown(event) {
        if (event.keyCode === 13) {
            form.submit(()=>{
				tasknameInputRef.current.focus();
				setSendRequest(true);
			});
			
        }
    }
    return (
        <div className={cx('sendmessage')}>
            <div>
                <Form
                    {...layout}
                    layout="vertical "
                    form={form}
                    onFinish={onFinish}
                    className={cx('formsend')}
                    onKeyDown={handleKeyDown}
                    tabIndex={0}
                >
                    <Form.Item name="content">
                        <Input.TextArea className={cx('inputsendmess')} ref={tasknameInputRef} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}
export default SendMessage;
