import { Select, Form, Avatar } from 'antd';
import React from 'react';
import latinize from 'latinize';
import classNames from 'classnames/bind';
import styles from './Dropdown_status.module.scss';
const cx = classNames.bind(styles);
const { Option } = Select;

const DropdownUsers = ({ labeltitle, data }) => (
    <Form.Item name="task_assign" label={labeltitle}>
        <Select
            mode="multiple"
            className = {cx('listusers')}
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
            filterOption={(input, option) =>
                latinize(option.children.props['data-search'].toLowerCase()).includes(latinize(input.toLowerCase()))
            }
        >
            {data.map((e) => {
                return (
                    <Option value={e.email} key={e.id}>
                        <div data-search={e.last_name} className={cx('item')}>
                            <Avatar src={e.avatar} className={cx('avatar')} /> {e.last_name}
                        </div>
                    </Option>
                );
            })}
        </Select>
    </Form.Item>
);

export default DropdownUsers;
