import * as httpRequest from '~/utils/httpRequest';

export const partners = async () => {
    try {
        const res = await httpRequest.get(`/partners`, {
            params: {},
        });
        return res.partner;
    } catch (error) {
        console.log(error);
    }
};
export const partnersdetail = async (id) => {
    try {
        const res = await httpRequest.get(`/partner/${id}`, {
            params: {},
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const editProject = async (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('prj_name', data.prj_name);
    bodyFormData.append('prj_desc', data.prj_desc);
    bodyFormData.append('prj_partner', data.prj_partner);
    try {
        const res = await httpRequest.post(
            `/editpartner/${data.id}`,
            bodyFormData,
        );
        return res;
    } catch (error) {
        console.log(error);
    }
};
