import React from 'react';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
const cx = classNames.bind(styles);

var myTips = ['Welcome to Workmanager for design webteam', 'Have a goodday!', 'Work hard play smart!'];
var randomTip = myTips[Math.floor(Math.random() * myTips.length)];
function Loading() {
    return (
        <div className={cx('progressbar')}>
            <span className={cx('loading')}></span>
            <div className={cx('loadText')}>Loading...</div>
        </div>
    );
}
export default Loading;
