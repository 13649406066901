import { Table, Input, Space, Button, Spin, Breadcrumb } from 'antd';
import { DemoBlock, getAvatarFromArr } from '~/components/until';
import { SearchOutlined } from '@ant-design/icons';
import { SearchOutline } from 'antd-mobile-icons';
import { Grid, NavBar } from 'antd-mobile';
import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as projectsServices from '~/services/projectsService';
import Highlighter from 'react-highlight-words';

function Projects() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        const fetchApi = async () => {
            const result = await projectsServices.projects();
            const resultArray = result.map((elm) => ({ key: elm.id, name: elm.name, partner: elm.partner })); //console.log(resultArray)
            setProjects(resultArray);
            setLoading(false);
        };
        fetchApi();
    }, []);
    const addNewProject = () => {
        navigate('/createproject');
    };
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns1 = [
        {
            title: 'Project name',
            dataIndex: 'name',
            id: 'key',
            ...getColumnSearchProps('name'),
            render: (text, key) => <Link to={`/projects/${key.key}`}>{text}</Link>,
        },
        {
            title: 'Partner',
            dataIndex: 'partner',
            id: 'khachhang',
            ...getColumnSearchProps('partner'),
        },
    ];
    const backtop = () => {
        return navigate(-1);
    };
    const right = (
        <div style={{ fontSize: 24 }}>
            <Space style={{ '--gap': '16px' }}>
                <SearchOutline />
            </Space>
        </div>
    );
    return (
        <div>
            <NavBar onBack={backtop} className="headermobile" right={right}>
                List projects
            </NavBar>
            <DemoBlock>
                <Grid columns={1} gap={8}>
                    <Spin spinning={loading}>
                        <Table dataSource={projects} columns={columns1} pagination={40} />
                    </Spin>
                </Grid>
            </DemoBlock>
        </div>
    );
}
export default Projects;
