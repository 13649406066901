import { Avatar, Form, Input, DatePicker, Modal, Select } from 'antd';
import React, { useState } from 'react';
import latinize from 'latinize';

const ModalEditTodo = ({ visible, onCreate, onCancel, usersAssign }) => {
    const [form] = Form.useForm();
    const dateFormat = 'DD/MM/YYYY';
    const { Option } = Select;
    const [dateOrigin, setDateOrigin] = useState('');
    const onChange = (date) => {
        setDateOrigin(date);
    };
    return (
        <Modal
            forceRender
            visible={visible}
            title="Add new todo"
            okText="Save"
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        values.original = dateOrigin;
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    modifier: 'public',
                }}
            >
                <Form.Item
                    name="title"
                    label="What will you do?"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the title of todo!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="assign" label="Assign" tooltip='Thêm nhân sự ở Project để gán thêm danh sách Assign todo'>
                    <Select
                        //  size="large"
                        className="listusers"
                        showSearch
                        placeholder="Select a person"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            latinize(option.children.props['data-search'].toLowerCase()).includes(
                                latinize(input.toLowerCase()),
                            )
                        }
                    >
                        {usersAssign.map((e) => {
                            return (
                                <Option value={e.email} key={e.id}>
                                    <div data-search={e.last_name}>
                                        <Avatar src={e.avatar} size="small" /> {e.last_name}
                                    </div>
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>

                <Form.Item name="deadline" label="Deadline" >
                    <DatePicker onChange={onChange} format={dateFormat} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default ModalEditTodo;
