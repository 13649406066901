import { Button, Form, Input, Select, PageHeader } from 'antd';
import { LeftOutlined, SaveOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useState } from 'react';
import * as partnersServices from '~/services/partnersService';
import * as projectsServices from '~/services/projectsService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 16,
    },
};

function AddNewPr() {
	const { projectId } = useParams();
    const [form] = Form.useForm();
    const [partner, setPartner] = useState([]);
    const { Option } = Select;
    const [value, setValue] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
		const fetProjectDetail = async()=>{
			const result = await projectsServices.projectsdetail(projectId);
			form.setFieldsValue({
				prj_name: result.prj_name,
				prj_desc: result.prj_desc,
				prj_partner: result.makhachhang,
			});
		}
		fetProjectDetail();
        const fetchApi1 = async () => {
            const result = await partnersServices.partners();
            setPartner(result);
        };
        fetchApi1();
        
    }, [projectId,form]);
    const onFinish = async (values) => {
		values.id = projectId; 
        const result = await projectsServices.editProject(values); console.log(result)
        navigate(`/projects/${projectId}`, { state: result });
    };
    const cancelEdit = () => {
        navigate(`/projects/${projectId}`);
    };

    return (
        <>
            <PageHeader
                className="site-page-header p0"
                onBack={() => {
                    navigate(`/projects/${projectId}`);
                }}
                title="Edit project"
            />
            <div className="rounder">
                <Form {...layout} name="nest-messages" onFinish={onFinish} form={form}>
                    <Form.Item
                        name="prj_name"
                        label="Project Name"
                        rules={[{ required: true, message: 'Insert project name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="prj_partner" label="Partner">
                        <Select showSearch optionFilterProp="children">
                            {partner.map((e) => (
                                <Option key={e.id} value={e.id}>
                                    {e.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="prj_desc" label="Description">
                        <ReactQuill theme="snow" value={value} onChange={setValue} preserveWhitespace={true} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
                        <div className="d-flex space-between">
                            <Button htmlType="button" icon={<LeftOutlined />} onClick={cancelEdit}>
                                Back
                            </Button>
                            <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                                Submit
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
}
export default AddNewPr;
