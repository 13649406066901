import { Button, Space, Divider, Table, Avatar, Tag, Breadcrumb, Typography, Row, Col, Segmented, Tooltip } from 'antd';
import {
    LeftOutlined,
    FormOutlined,
    PlusOutlined,
    AppstoreOutlined,
    BarsOutlined,
    EditOutlined,
	PlusCircleOutlined
} from '@ant-design/icons';
import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getUser } from '~/utils/Common';
import { ToastContainer, toast } from 'react-toastify';
import { Markup } from 'interweave';
import * as projectsServices from '~/services/projectsService';
import * as tasksServices from '~/services/tasksService';
import * as usersServices from '~/services/searchService';
import classNames from 'classnames/bind';
import styles from './Projects.module.scss';
import ProjectTimeline from './timeline';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Board, { moveCard } from '@lourenci/react-kanban';
import '@lourenci/react-kanban/dist/styles.css';
import ModalAddNewTask from '~/components/Modal/modalAddnewTask';


const cx = classNames.bind(styles);
const currentUser = getUser();
function Detail() {
    const { t, i18n } = useTranslation();
    const { projectId } = useParams('');
    const [data, setData] = useState([]);
    const [tasks, settasks] = useState([]);
    const [prjname, setPrjname] = useState();
    const [prjprogress, setPrjprogress] = useState([]);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const [sendRequest, setSendRequest] = useState(false);
    const [showboard, setShowboard] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (sendRequest) {
            setSendRequest(false);
        }
        const getProjectDetail = async () => {
            const result = await projectsServices.projectsdetail(projectId);
            setData(result);
            setPrjname(result.prj_name);
        };
        const getTasksbyProject = async () => {
            const result = await tasksServices.tasksbyproject(projectId); console.log(result)
            const resultArray = result.map((elm) => ({
                key: elm.id,
                id: elm.id,
                task_name: elm.task_name,
                assign_id: elm.task_assign_id,
                thoigian: elm.thoigian,
                task_status: elm.task_status,
                status: elm.task_status,
            }));
            settasks(resultArray);
        };
        const fetchProgress = async () => {
            const result = await projectsServices.projectprogress(projectId);  console.log(result)
            setPrjprogress(result);
        };
        const fetchAllUsers = async () => {
            const result = await usersServices.users();
            setUsers(result);
        };

        getProjectDetail();
        getTasksbyProject();
        fetchProgress();
        fetchAllUsers();
    }, [sendRequest, projectId]);

    const showMessage = (message) => {
        toast.info(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const GoEdit = () => {
        navigate(`/editproject/${projectId}`);
    };
    const addNewTask = () => {
        navigate('/tasks/addnew', { state: { pid: data.id } });
    };
    const toProjectPage = () => {
        navigate(`/projects/`);
    };
    const getAvatarFromArr = (data) => {
        var test = data.toString().split(',').map(Number);
        let answer = users
            .filter((item) => test.includes(item.id))
            .map((item) => (
                <Tooltip placement="top" title={item.email} key={item.id}>
                    <Avatar src={item.avatar}  />
                </Tooltip>
            ));
        return answer;
    };
    const columns1 = [
        {
            title: t('Task name.1'),
            dataIndex: 'task_name',
            key: 'key',
            render: (text, key) => <Link to={`/tasks/${key.key}`}>{text}</Link>,
        },
        {
            title: t('Assign.1'),
            dataIndex: 'assign_id',
            key: 'assign_id',
            render: (assign_id) => <Avatar.Group>{getAvatarFromArr(assign_id)}</Avatar.Group>,
        },
        {
            title: t('Duedate.1'),
            dataIndex: 'thoigian',
            key: 'thoigian',
        },
        {
            title: t('Status.1'),
            dataIndex: 'task_status',
            key: 'task_status',
            render: (task_status) => <Tag className={`ant-tag-${task_status}`}>{task_status}</Tag>,
        },
    ];
    const onChangePrjName = async (value) => {
        if (value !== prjname) {
            setPrjname(value);
            let value1 = value.split();
            value1 = { id: projectId, key: 'prj_name', value: value };
            const result = await projectsServices.updateFieldProject(value1);
            showMessage(result.message);
        }
    };
    const onAddProgress = async (values) => {
        values.pid = projectId;
        values.datetime = moment(values.original).format('YYYY-MM-DD');
        values.author = currentUser.email;
        values.title = values.task_des;
        console.log(values);
        const result = await projectsServices.addTimeline(values);
        showMessage(result.message);
        setSendRequest(true);
    };
    function edittask(e) {
        navigate(`/edittask/${e}`);
    }
    const olala = (e) => {
        if (e === 'Kanban') {
            setShowboard(false);
        }
        if (e === 'List') {
            setShowboard(true);
        }
    };
    const columnsConfig = [
        {
            name: t('Todo.1'),
            predicate: (item) => ['Todo'].includes(item?.status),
        },
        {
            name: t('Doing.1'),
            predicate: (item) => ['Doing'].includes(item?.status),
        },
        {
            name: t('Review.1'),
            predicate: (item) => ['Review'].includes(item?.status),
        },
        {
            name: t('Done.1'),
            predicate: (item) => ['Done'].includes(item?.status),
        },
        {
            name: t('Hold.1'),
            predicate: (item) => ['Hold'].includes(item?.status),
        },
    ];
    function toggleModal() {
        setIsOpen(!isOpen);
    }
    function Header(props) {
        return (
            <div className={cx('header_card')}>
                <div className={cx('title')}> {props.title} </div>
                <Button
                    block
					icon={<PlusCircleOutlined />}
                    onClick={(e) => {
                        props.toggleModal();
                        if (props.title === 'Backlog') {
                            props.setClickedTitle(0);
                        } else if (props.title === 'To Do') {
                            props.setClickedTitle(1);
                        } else if (props.title === 'Done') {
                            props.setClickedTitle(2);
                        }
                    }}
                >
                    Add Task
                </Button>
            </div>
        );
    }
    const buildBoard = (columnsConfig, data) => ({
        columns: columnsConfig.map(({ name, predicate }, i) => ({
            id: i,
            name: name,
            cards: data.filter(predicate),
        })),
    });
    const handleCardMove = (...args) => {
        console.log(args);
        let idCard = args[1].id;
        let statusCard = args[3].toColumnId;
        switch (statusCard) {
            case 0:
                statusCard = 'Todo';
                break;
            case 1:
                statusCard = 'Doing';
                break;
            case 2:
                statusCard = 'Review';
                break;
            case 3:
                statusCard = 'Done';
                break;
            case 4:
                statusCard = 'Hold';
                break;
            default:
                statusCard = 'Other';
        }

        console.log(idCard + statusCard);
        updateStatus(idCard, statusCard);
    };
    const updateStatus = async (taskid, value) => {
        let value1 = [];
        value1 = { id: taskid, key: 'task_status', value: value, author: currentUser.email };
        const result = await tasksServices.updateFieldTask(value1);
        reupdateTasks(taskid, value);
    };
    function reupdateTasks(id, status) {
        let objIndex = tasks.findIndex((obj) => obj.id == id);
        tasks[objIndex].status = status;
        tasks[objIndex].task_status = status;
    }
    function GodetailTask(e) {
        navigate(`/tasks/${e}`);
    }
    function ControlledBoard() {
        return (
            <Board
                disableColumnDrag
                allowRemoveLane
                allowRenameColumn
                allowRemoveCard
                onLaneRemove={console.log}
                onCardRemove={console.log}
                onLaneRename={console.log}
                initialBoard={buildBoard(columnsConfig, tasks)}
                onNewCardConfirm={(draftCard) => ({
                    id: new Date().getTime(),
                    ...draftCard,
                })}
                renderColumnHeader={(e) => (
                    <Header
                        title={e.name}
                        toggleModal={toggleModal}
                        // setClickedTitle={setClickedTitle}
                    />
                )}
                onCardNew={console.log}
                onCardDragEnd={handleCardMove}
                renderCard={(props) => (
                    <div className={cx('kanban_card')}>
                        <div className={cx('title')}>
                            {' '}
                            <Button type="text" onClick={() => GodetailTask(props?.id)}>
                                {props?.task_name}
                            </Button>
                        </div>
                        <div className={cx('time')}>{props?.thoigian}</div>
                        {props?.assign_id ? (
                            <div className={cx('avatar')}>
                                <div>
                                    <Tooltip title="Edit" className={cx('btnarea')}>
                                        <Button
                                            shape="circle"
                                            onClick={() => {
                                                edittask(props?.id);
                                            }}
                                            icon={<EditOutlined />}
                                        />
                                    </Tooltip>
                                </div>
                                <Avatar.Group>{getAvatarFromArr(props?.assign_id)}</Avatar.Group>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                )}
            />
        );
    }
	const getAssignIDFromArr = (data) => {
        var test = data.toString().split(',');
        let answer = users.filter((item) => test.includes(item.email)).map((item) => item.id);
        return answer;
    };
	const onAddnewTask = async (values) => {
		values.pid = projectId;
        values.duedate = moment(values.deadline).format('YYYY-MM-DD');
		if (values.task_tag) {
            values.task_tag = values.task_tag.sort().toString();
        } else {
            values.task_tag = '';
        }
        values.task_assign = values.task_assign.toLocaleString();
        values.task_assign_id = getAssignIDFromArr(values.task_assign.toLocaleString()).toLocaleString();

        const result = await tasksServices.addTask(values);

		console.log(result)
        setIsOpen(false);
       // const result = await tasksServices.addtodo(values);
        setSendRequest(true);
    };
    return (
        <>
            <ModalAddNewTask
                visible={isOpen}
                onCreate={onAddnewTask}
                onCancel={() => {
                    setIsOpen(false);
                }}
                usersAssign={users}
            />
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/">{t('Home.1')}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/projects">{t('Project.1')}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t('Project detail.1')}</Breadcrumb.Item>
            </Breadcrumb>
            <Typography.Title level={4} editable={{ onChange: onChangePrjName }}>
                {prjname}
            </Typography.Title>
            <Space>
                <Button htmlType="button" onClick={GoEdit} icon={<FormOutlined />}>
                    {t('Edit project.1')}
                </Button>
                <Button type="primary" icon={<PlusOutlined />} onClick={addNewTask}>
                    {t('Create new task.1')}
                </Button>
                <Segmented
                    onChange={olala}
                    options={[
                        {
                            value: 'List',
                            icon: <BarsOutlined />,
                        },
                        {
                            value: 'Kanban',
                            icon: <AppstoreOutlined />,
                        },
                    ]}
                />
            </Space>
            <Divider />
            {showboard ? (
                <div>
                    <Row gutter={16}>
                        <Col span={16}>
                            <div className={cx('wrapper')}>
                                <div className="rounder mb-24">
                                    <div>
                                        <p>
                                            Khách hàng: {data.tenkhachhang} / {data.motakhachhang}
                                        </p>
                                        <Divider dashed />
                                        <div className="descriptionbox">
                                            <Markup content={data.prj_desc} />
                                        </div>
                                    </div>
                                </div>
                                <Table dataSource={tasks} columns={columns1} />
                            </div>
                        </Col>
                        <Col span={8}>
                            <ProjectTimeline data={prjprogress} onCreate={onAddProgress} />
                        </Col>
                    </Row>

                    <div className={cx('footer')}>
                        <Button htmlType="button" icon={<LeftOutlined />} onClick={toProjectPage}>
                            Back
                        </Button>
                    </div>
                    <ToastContainer />
                </div>
            ) : (
                <div>
                    <ControlledBoard />
                </div>
            )}
        </>
    );
}

export default Detail;
