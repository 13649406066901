import { Breadcrumb, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as sysServices from '~/services/sysService';
import Item from './Item';

const Syslogs = () => {
    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState([]);
    useEffect(() => {
        let isCanceled = false;
        const fetchAllUsers = async () => {
            const result = await sysServices.getlogs();
            if (!isCanceled) {
                setLogs(result);
                setLoading(false);
            }
        };
        fetchAllUsers();
        //cleanup
        return () => {
            isCanceled = true;
        };
    }, []);
    return (
        <>
            <div className="mb-24">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>System logs</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Spin spinning={loading}>
                {logs.map((data) => {
                    return <Item data={data} key={data.id} />;
                })}
            </Spin>
        </>
    );
};

export default Syslogs;
