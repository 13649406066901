import { Card, Row, Col, Image } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as partnersService from '~/services/partnersService';
import styles from './Home.module.scss';
import classNames from 'classnames/bind';
import images from '~/assets/images';
const cx = classNames.bind(styles);
function Home () {
  const [partners, setPartners] = useState([])
  const { Meta } = Card;
  useEffect(() => {
    const fetchApi1 = async () => {
      const result = await partnersService.partners();
      setPartners(result);
    };
    fetchApi1();
  }, []);
  return (
    <>
      <h2>Welcome to Work Manager</h2>
      <div className='listpartnerindex'> 
        <Row gutter={[16, 16]}>
          {partners.map(e => (
            <Col span={6} key={e.id}>
              <Link to={`/projectlist/${e.id}`}>
                <Card
                  hoverable
                  cover={<Image alt="example" src={e.image} className={cx('coverimg')}
				  fallback={images.noImage}	 
				  />}
                >
                  <Meta title={e.name} description={e.mota} />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </>
  )
}

export default Home;
