import {
  Table,
  Breadcrumb,
  Form,
  Spin,
  Button,
  Drawer,
  Image,
  Tag,
  Space
} from 'antd';
import { PlusOutlined, MobileOutlined, MailOutlined, SkypeOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import * as canboServices from '~/services/canboService';
import { useTranslation } from 'react-i18next';
import Add from './add';
import Edit from './edit';
function Canbo () {
  const { t } = useTranslation();
  const originData = [];
  const [projects, setProjects] = useState([]);
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');
  const [sendRequest, setSendRequest] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [dataEdit, setDataEdit] = useState([]);

  useEffect(() => {
    if (sendRequest) {
      setSendRequest(false);
    }
    const fetchApi = async () => {
      const result = await canboServices.getallcanbo();
      const resultArray = result.map((elm) => ({
        key: elm.id,
        avatar: elm.avatar,
        mota: elm.mota,
        mobile: elm.mobile,
        email: elm.email,
        skyper: elm.skyper,
        name: elm.name,
        phongban: elm.phongban,
        tenphong: elm.tenphong,
      }));
      setProjects(resultArray);
      setLoading(false);
    };
    fetchApi();
  }, [sendRequest]);

  const actionEditCanbo = (e) => {
    setDataEdit(e);
    setOpenEditForm(true);
  };
  const cancel = () => {
    setEditingKey('');
    setOpenAddForm(false);
    setOpenEditForm(false);
  };

  const addNewCanbo = () => {
    setOpenAddForm(true);
  };
  const onError = (e) => {
    e.target.onerror = null;
    e.target.src =
      'https://us.123rf.com/450wm/mathier/mathier1905/mathier190500002/134557216-no-thumbnail-image-placeholder-for-forums-blogs-and-websites.jpg?ver=6';
  };
  const columns = [
    {
      title: 'Avatar',
      render: (record) => {
        return <Image width={60} src={`avatars/canbo${record.key}.jpg`} onError={onError} />;
      },
      width: '90px',
    },
    {
      title: t('Name.1'),
      dataIndex: 'name',
      width: '20%',
    },
    {
      title: 'Liên hệ',
      width: 60,
      render: (record) => {
        return (
          <><Space direction="vertical">
            <Space wrap>
              {record.mobile === 'undefined' ? '' : <Tag icon={<MobileOutlined />} color="success">{record.mobile}</Tag>}
              {record.email === 'undefined' ? '' : <Button href={`mailto:${record.email}`} type="dashed" danger icon={<MailOutlined />} size='small'>{record.email}</Button>}
              {record.skyper === 'undefined' ? '' : <Button href={`skype:${record.skyper}?chat`} type="default" icon={<SkypeOutlined />} size='small' >{record.skyper}</Button>}
            </Space>
          </Space>
          </>
        );
      },
    },
    {
      title: 'Phòng ban',
      dataIndex: 'tenphong',
    },
    {
      title: t('Action.1'),
      render: (record) => {
        return <Button onClick={() => actionEditCanbo(record)}>Edit</Button>
      },
      width: '110px',
    },
  ];
  const funcparent = () => {
    setOpenEditForm(false);
    setOpenAddForm(false);
    setSendRequest(true);
  };
  return (
    <div>
      <div className="d-flex space-between">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Settings</Breadcrumb.Item>
          </Breadcrumb>
          <h3>Office staff list</h3>
        </div>
        <Button type="primary" icon={<PlusOutlined />} onClick={addNewCanbo}>
          Create new
        </Button>
      </div>
      <Spin spinning={loading} tip="Loading...">
        <Form form={form} component={false}>
          <Table
            bordered
            dataSource={projects}
            columns={columns}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['30', '50', '100'],
            }}
          />
        </Form>
      </Spin>
      <Drawer title="Edit cán bộ" placement="right" width={640} onClose={cancel} open={openEditForm}>
        <Edit dataEdit={dataEdit} sendToParent={funcparent} />
      </Drawer>
      <Drawer title="Thêm mới cán bộ" placement="right" width={640} onClose={cancel} open={openAddForm}>
        <Add sendToParent={funcparent} />
      </Drawer>
    </div>
  );
}
export default Canbo;
