import { Table, Input, Space, Button, Spin, Breadcrumb } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as projectsServices from '~/services/projectsService';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { Markup } from 'interweave';
import classNames from 'classnames/bind';
import styles from './Projects.module.scss';
const cx = classNames.bind(styles);

function Projects() {
	const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        let isCanceled = false;
        const fetchApi = async () => {
            const result = await projectsServices.projects(); console.log(result)
            if (!isCanceled) {
                const resultArray = result.map((elm) => ({ key: elm.id, name: elm.name, partner: elm.partner, mota: elm.mota })); //console.log(resultArray)
                setProjects(resultArray);
                setLoading(false);
            }
        };
        fetchApi();
        //cleanup
        return () => {
            isCanceled = true;
        };
    }, []);
    const addNewProject = () => {
        navigate('/createproject');
    };
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 100,
                        }}
                    >
                        Tìm kiếm
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns1 = [
        {
            title: t('Project name.1'),
            dataIndex: 'name',
            id: 'key',
            ...getColumnSearchProps('name'),
            render: (text, key) => <Link to={`/projects/${key.key}`}>{text}</Link>,
			width: '40%',
        },
        {
            title: t('Partner.1'),
            dataIndex: 'partner',
            id: 'khachhang',
            ...getColumnSearchProps('partner'),
			width: '130px',
        },
        {
            title: t('Descriptions.1'),
			dataIndex: 'mota',
            render: (mota) => <Markup content={mota} />,
        },
    ];
    return (
        <div>
            <div className={cx('header-page-breadcrumb')}>
                <div>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">{t('Home.1')}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{t('Project.1')}</Breadcrumb.Item>
                    </Breadcrumb>
                    <h3>{t('Projects list.1')}</h3>
                </div>
                <div className="text-right">
                    <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={addNewProject}></Button>
                </div>
            </div>
            <Spin spinning={loading}>
                <Table dataSource={projects} columns={columns1} pagination={80} />
            </Spin>
        </div>
    );
}
export default Projects;
