import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser } from '~/utils/Common';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import * as searchServices from '~/services/searchService';
import FormikControl from '~/components/FormikControl';
import { faSave, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '~/components/Button';

function ChangePass () {
  const currentUser = getUser();
  const navigate = useNavigate();
  const initialValues = {
    oldpass: '',
    newpass1: '',
    newpass2: '',
  };
  const validationSchema = Yup.object({
    oldpass: Yup.string().required('Vui lòng nhập thông tin'),
    newpass1: Yup.string().required('Vui lòng nhập thông tin'),
    newpass2: Yup.string().required('Vui lòng nhập thông tin'),
  });
  const onSubmit = async (values) => {
    values = { ...values, id: currentUser.id };
    values = JSON.parse(JSON.stringify(values));
    const result = await searchServices.editUser(values);
    navigate(`/users/${currentUser.email}`, { currentUser: result });
  };
  return (
    <>
      <h2 className="mb-24">Change password</h2>
      <div className="card mw600 m-auto">
        <div className="form-group">
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {(Formik) => (
              <Form>
                <FormikControl control="input" type="password" label="Old password" name="oldpass" />
                <FormikControl control="input" type="password" label="New password" name="newpass1" />
                <FormikControl control="input" type="password" label="Confirm new password" name="newpass2" />
                <div className="card-footer">
                  <Button secondary to={`/users/${currentUser.email}`}>
                    <FontAwesomeIcon icon={faChevronLeft} /> Cancel
                  </Button>
                  <Button primary type="submit" className="button">
                    <FontAwesomeIcon icon={faSave} /> Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
export default ChangePass;
