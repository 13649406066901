import { Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
const { Text } = Typography;
export const formatDate = (value) => {
  var temp = value.toString().split(" ");
  return (temp[2] + '/' + month2Num(temp[1]) + '/' + temp[3]);
}
export const formatDate2db = (value) => {
  var temp = value.toString().split(" ");
  return (temp[3] + '-' + month2Num(temp[1]) + '-' + temp[2]);
}
export const month2Num = (val) => {
  switch (val) {
    case 'Jan':
      return '01';
    case 'Feb':
      return '02';
    case 'Mar':
      return '03';
    case 'Apr':
      return '04';
    case 'May':
      return '05';
    case 'Jun':
      return '06';
    case 'Jul':
      return '07';
    case 'Aug':
      return '08';
    case 'Sep':
      return '09';
    case 'Oct':
      return '10';
    case 'Nov':
      return '11';
    case 'Dec':
      return '12';
    default:
      return null;
  }
}

export const getIndex = (value, arr) => {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].value === value) {
      return (arr[(i)]);
    }
  }
  return -1; //to handle the case where the value doesn't exist
};

export const string2ArrWithLink = (mystring) => {
  if (mystring) {
    return (mystring.split(',').map(String).map((e, index) => (<Tag key={index}><Link to={`/tasks/tag/${e}`}>{e}</Link></Tag>)))
  } else {
    return (<Text type="warning">Not have any tag</Text>)
  }
}

export const filterInPlace = (array, predicate) => {
  let end = 0;

  for (let i = 0; i < array.length; i++) {
    const obj = array[i];

    if (predicate(obj)) {
      array[end++] = obj;
    }
  }
  array.length = end;
};

export const stringWidthQuote = (string) => {console.log(string)
  var arr = string.split(','); // đưa về arr
  arr.map(i => `'${i}'`); // thêm ngoặc
  var quotedAndCommaSeparated = "'" + arr.join("','") + "'"; // trả về string
  return (quotedAndCommaSeparated);
}

//lấy items có id trong arr
export const getItemArrById = (arr, id) => {
	return arr.find((obj) => {
		return obj.id === id;
	});
};