import React from 'react';
import { Select } from 'antd';
import languageStore from '~/stores/languageStore';
import images from '~/assets/images';
import styles from './Header.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);
const { Option } = Select;

const SelectLanguage = () => {
    const i18nextLng 	= languageStore((state) => state.i18nextLng);
    const setI18nextLng = languageStore((state) => state.setI18nextLng);

    const handleChange = (value) => {
        setI18nextLng(value);
    };

    const options = [
        { id: 'vi', name: 'Tiếng Việt', icon: images.vietflag },
        { id: 'en', name: 'English', icon: images.enflag },
    ];

    return (
        <Select value={i18nextLng} style={{ width: 126 }} onChange={handleChange} optionFilterProp="children">
            {options.map((e) => (
                <Option key={e.id} value={e.id}>
                    <div className={cx('itemlange')}>
                        <img src={e.icon} alt={e.name} /> {e.name}
                    </div>
                </Option>
            ))}
        </Select>
    );
};

export default SelectLanguage;
