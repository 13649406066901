import * as httpRequest from '~/utils/httpRequest';

export const getallcanbo = async () => {
	try {
		const res = await httpRequest.get('/canbo', {
			params: {},
		});
		return res.data;
	} catch (error) {
		console.log(error);
	}
};
export const addnewCanbo = async (data) => {  
	var bodyFormData = new FormData();
	bodyFormData.append('avatar', data.avatar);
	bodyFormData.append('name', data.name);
  bodyFormData.append('birth', data.birth);
	bodyFormData.append('skyper', data.skyper);
	bodyFormData.append('mobile', data.mobile);
	bodyFormData.append('email', data.email);
	bodyFormData.append('mota', data.mota);
	bodyFormData.append('phongban', data.phongban);
  	bodyFormData.append('phoihop', data.phoihop);
	try {
	   const res = await httpRequest.post(`/addnewcanbo`, bodyFormData); 
	   return res; 
	} catch (error) {
	   console.log(error);
	}
 };
export const editCanbo = async (data) => { 
	var bodyFormData = new FormData();
	bodyFormData.append('avatar', data.avatar);
	bodyFormData.append('name', data.name);
	bodyFormData.append('birth', data.birth);
	bodyFormData.append('skyper', data.skyper);
	bodyFormData.append('mobile', data.mobile);
	bodyFormData.append('email', data.email);
	bodyFormData.append('mota', data.mota);
	bodyFormData.append('phongban', data.phongban);
	bodyFormData.append('phoihop', data.phoihop);
	try {
	   const res = await httpRequest.post(`/editcanbo/${data.id}`, bodyFormData); 
	   return res; 
	} catch (error) {
	   console.log(error);
	}
 };