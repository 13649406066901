import * as httpRequest from '~/utils/httpRequest';

export const getallphongban = async () => {
	try {
		const res = await httpRequest.get('/phongban', {
			params: {},
		});
		return res.data;
	} catch (error) {
		console.log(error);
	}
};
export const editPhongban = async (data) => {
	var bodyFormData = new FormData();
	bodyFormData.append('tenphong', data.name);
	bodyFormData.append('khoi', data.skyper);
	bodyFormData.append('truongphong', data.skyper);
	try {
	   const res = await httpRequest.post(`/editcanbo/${data.id}`, bodyFormData); 
	   return res; 
	} catch (error) {
	   console.log(error);
	}
 };