import React, { useState } from 'react';
import { Form, Input } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const { TextArea } = Input;
const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link'],
        ['clean'],
    ],
};

function QuillEditor(data) {
	const [value, setValue] = useState('');
    return (
        <>
            <Form.Item name="task_des" label="Description" initialValue="">
                <ReactQuill modules={modules} theme="snow" onChange={setValue} preserveWhitespace={true} error="" />
            </Form.Item>
            {/* <Form.Item name="task_des" label="Description">
                <TextArea>
                    {value}
                </TextArea>
            </Form.Item> */}
        </>
    );
}

export default QuillEditor;
