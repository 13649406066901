import { Avatar } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import images from '~/assets/images';
import styles from './Users.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function ItemUserInList({ data }) {
    return (
        <Link to={`${data.email}`} className={cx('itemuser')}>
            <Avatar className={cx('useravatar')} size={56} src={data.avatar || images.noImage} />
            <div>
                <div className={cx('username')}>
                    {data.first_name} {data.last_name}
                </div>
                <div className={cx('useremail')}>{data.email}</div>
            </div>
        </Link>
    );
}

export default ItemUserInList;
