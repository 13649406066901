import { Avatar, Card, Skeleton } from 'antd';
import { Form } from 'antd';
import { List } from 'antd-mobile';
import React from 'react';
import images from '~/assets/images';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import * as searchServices from '~/services/searchService';
import * as tasksServices from '~/services/tasksService';
import classNames from 'classnames/bind';
import styles from './Users.module.scss';
import { ToastContainer } from 'react-toastify';
const cx = classNames.bind(styles);

const { Meta } = Card;

function Detail() {
    const [form] = Form.useForm();
    const { userId } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        const fetchApi = async () => {
            const result = await searchServices.usersbyemail(userId);
            if (result.id) {
                const getAllTaskUser = async () => {
                    const result1 = await tasksServices.tasksbyuser(result.id);
                    const resultArray = result1.map((elm) => ({
                        key: elm.id,
                        task_name: elm.task_name,
                        prj_name: elm.prj_name,
                        thoigian: elm.thoigian,
                        task_status: elm.task_status,
                    }));
                    setTasks(resultArray);
                    setLoading(false);
                };
                getAllTaskUser();
            }
            setUsers(result);
        };
        fetchApi();
    }, [form, userId]);

    return (
        <>
            {!users ? (
                <>
                    <div className={cx('wrapper')}>
                        <div className={cx('card')}>
                            <div className="error">Không tìm thấy thông tin người dùng</div>
                        </div>
                    </div>
                </>
            ) : (
                <Card>
                    <Skeleton loading={loading} avatar active>
                        <Meta
                            avatar={<Avatar size={96} title={users.username} src={users.avatar || images.noImage} />}
                            title={users.first_name + ' ' + users.last_name}
                            description={users.email}
                            style={{
                                width: 300,
                                margin: 'auto',
                            }}
                        />
                    </Skeleton>
                </Card>
            )}

            <List header="Tasks list">
                {tasks.map((e) => (
                    <List.Item title={e.prj_name} description={e.thoigian} clickable key={e.key} onClick={() => {
						navigate(`/tasks/${e.key}`);
					}
				}>
                        {e.task_name}
                    </List.Item>
                ))}
            </List>

            <ToastContainer />
        </>
    );
}

export default Detail;
