import React from 'react';
import FormikContainer from '~/components/FormikContainer';

function FormComponents() {
    return (
        <div>
            <h2>Form Components</h2>
            <div className="card">
                <div className="form-group">
                    <FormikContainer />
                </div>
            </div>
        </div>
    );
}

export default FormComponents;
