import * as httpRequest from '~/utils/httpRequest';

export const tasks = async () => {
    try {
        const res = await httpRequest.get('/tasks', {
            params: {},
        });
        return res.tasks;
    } catch (error) {
        console.log(error);
    }
};

export const tasksbyid = async (id) => {
    try {
        const res = await httpRequest.get(`/tasks/${id}`, {
            params: {},
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const tasksbyproject = async (id) => {
    try {
        const res = await httpRequest.get(`/taskbyproject/${id}`, {
            params: {},
        });
        return res.items;
    } catch (error) {
        console.log(error);
    }
};

export const tasksbyuser = async (email) => {
    try {
        const res = await httpRequest.get(`/taskbyuser/${email}`, {
            params: {},
        });
        return res.items;
    } catch (error) {
        console.log(error);
    }
};

export const commentsbytask = async (id) => {
    try {
        const res = await httpRequest.get(`/commentsbytask/${id}`, {
            params: {},
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const sendcomment = async (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('author', data.author);
    bodyFormData.append('content', data.content);
    bodyFormData.append('taskid', data.taskid);
    try {
        const res = await httpRequest.post(`/sendcomment`, bodyFormData); 
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const addTask = async (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('task_name', data.task_name);
    bodyFormData.append('task_des', data.task_des);
    bodyFormData.append('task_status', data.task_status);
    bodyFormData.append('task_assign', data.task_assign);
	bodyFormData.append('task_assign_id', data.task_assign_id);
    bodyFormData.append('task_duedate', data.task_duedate);
    bodyFormData.append('date_original', data.date_original);
    bodyFormData.append('task_project', data.task_project);
    bodyFormData.append('task_canboyc', data.task_canboyc);
    bodyFormData.append('task_emailyc', data.task_emailyc);
    bodyFormData.append('task_tag', data.task_tag); console.log(bodyFormData)
    try {
        const res = await httpRequest.post(`/task`, bodyFormData);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const editTask = async (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('task_name', data.task_name);
    bodyFormData.append('task_assign', data.task_assign);
    bodyFormData.append('task_assign_id', data.task_assign_id);
    bodyFormData.append('task_canboyc', data.task_canboyc);
    bodyFormData.append('task_des', data.task_des);
    bodyFormData.append('task_duedate', data.task_duedate);
    bodyFormData.append('date_original', data.date_original);
    bodyFormData.append('task_emailyc', data.task_emailyc);
    bodyFormData.append('task_project', data.task_project);
    bodyFormData.append('task_tag', data.task_tag);
    bodyFormData.append('task_status', data.task_status);
    try {
        const res = await httpRequest.post(`/task/${data.id}`, bodyFormData);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const updateFieldTask = async (data) => { 
    var bodyFormData = new FormData();
    bodyFormData.append('key', data.key);
    bodyFormData.append('value', data.value);
    bodyFormData.append('author', data.author);
    try {
        const res = await httpRequest.post(
            `/editlinetask/${data.id}`,
            bodyFormData,
        );
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const searchbytag = async (q) => {
    try {
        const res = await httpRequest.get(`/searchtasksbytag?q=${q}`, {
            params: {},
        });
        return res.tasks;
    } catch (error) {
        console.log(error);
    }
};

export const logsbytask = async (id) => {
    try {
        const res = await httpRequest.get(`/logsbytask/${id}`, {
            params: {},
        });
        return res.items;
    } catch (error) {
        console.log(error);
    }
};

export const todobytask = async (id) => {
    try {
        const res = await httpRequest.get(`/todobytask/${id}`, {
            params: {},
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const addtodo = async (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('pid', data.pid);
    bodyFormData.append('content', data.title);
    bodyFormData.append('deadline', data.duedate);
	  bodyFormData.append('date_original', data.original);
    bodyFormData.append('assign', data.assign);
    try {
        const res = await httpRequest.post(`/todo`, bodyFormData); 
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const edittodo = async (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('id', data.id);
    bodyFormData.append('content', data.title);
    bodyFormData.append('deadline', data.duedate);
	  bodyFormData.append('date_original', data.original);
    bodyFormData.append('assign', data.assign);
    try {
        const res = await httpRequest.post(`/edittodo`, bodyFormData); 
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const deletetodo = async (id) => {
    var bodyFormData = new FormData();
    bodyFormData.append('id', id);
    try {
        const res = await httpRequest.post(`/deletetodo`, bodyFormData);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const updateStatusTodo  = async (id) => {
    var bodyFormData = new FormData();
    bodyFormData.append('id', id);
    try {
        const res = await httpRequest.post(`/updatestatustodo`, bodyFormData);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const changeDateFormat = (dateString) => {
	var dateArray = dateString.split('/');
	var year = dateArray[1];
	var month = dateArray[0];
	var newDateString = year + '/' + month;
	return newDateString;
  }