import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './Text-error';

function Select (props) {
  const { label, name, options, ...rest } = props;
  return (
    <div className="form-element">
      <label htmlFor={name}>{label}</label>
      <Field id={name} name={name} {...rest} as="select">
        <option disabled value="">(Make a Selection)</option>
        {options.map((option) => {
          return (
            <option key={option.key} value={option.key}>
              {option.value}
            </option>
          );
        })}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div >
  );
}

export default Select;
