import { getUser } from '~/utils/Common';
import { Avatar, Card, Skeleton, Tooltip, Divider, Breadcrumb, message } from 'antd';
import { Button, Drawer, Form, Input } from 'antd';
import { EditOutlined, KeyOutlined, StopOutlined, SaveOutlined } from '@ant-design/icons';
import React from 'react';
import images from '~/assets/images';
import Image from '~/components/Image';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import * as searchServices from '~/services/searchService';
import * as tasksServices from '~/services/tasksService';
import TasksByUser from './listTaskByUser';
import classNames from 'classnames/bind';
import styles from './Users.module.scss';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
const cx = classNames.bind(styles);

const { Meta } = Card;

function Detail() {
	const { t, i18n } = useTranslation();
    const currentUser = getUser();
    const [form] = Form.useForm();
    const { userId } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [changepass, setChangepass] = useState(false);
    const closeChangepass = () => {
        setChangepass(false);
    };

    useEffect(() => {
        const fetchApi = async () => {
            const result = await searchServices.usersbyemail(userId);
            if (result.id) {
                const getAllTaskUser = async () => {
                    const result1 = await tasksServices.tasksbyuser(result.id);
                    const resultArray = result1.map((elm) => ({
                        key: elm.id,
                        task_name: elm.task_name,
                        prj_name: elm.prj_name,
                        thoigian: elm.thoigian,
                        task_status: elm.task_status,
                    }));
                    setTasks(resultArray);
                    setLoading(false);
                };
                getAllTaskUser();
            }
            setUsers(result);
            form.setFieldsValue({
                first_name: result.first_name,
                last_name: result.last_name,
                username: result.username,
            });
        };
        fetchApi();
    }, [form, userId]);

    const onChangpass = async (values) => {
        values = { ...values, email: userId };
        values = JSON.parse(JSON.stringify(values));
        const result = await searchServices.changpassUser(values); console.log(result)
        message.info(result.message);
        setChangepass(false);
    };

    const ChangePass = () => {
        setChangepass(true);
    };
    const Editinpage = () => {
        navigate('/editprofile');
    };

    const handleCancel = () => {
        setChangepass(false);
    };
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/">{t('Home.1')}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/users">{t('User.1')}</Link>
                </Breadcrumb.Item>
            </Breadcrumb>
            <h3>{t('Profiles.1')}</h3>
            <Divider />
            {!users ? (
                <>
                    <div className={cx('wrapper')}>
                        <div className={cx('card')}>
                            <div className="error">{t('No result.1')}Không tìm thấy thông tin người dùng</div>
                        </div>
                    </div>
                </>
            ) : (
                <Card
                    style={{
                        width: 460,
                        margin: '0 auto 24px auto',
                    }}
                    actions={
                        currentUser.email === users.email
                            ? [
                                  <Tooltip placement="bottom" onClick={ChangePass} title={t('Change password.1')}>
                                      <KeyOutlined key="changpass" />
                                  </Tooltip>,
                                  <Tooltip placement="bottom" title={t('Edit profile.1')}>
                                      <EditOutlined onClick={Editinpage} key="edit" />
                                  </Tooltip>,
                              ]
                            : []
                    }
                >
                    <Skeleton loading={loading} avatar active>
                        <div className={cx('boxuserdetail')}>
							
                            <Avatar src={users.avatar || images.noImage} className={cx('avataruser')} />
                            <div>
                                <h2 className={cx('username')}>{users.first_name + ' ' + users.last_name}</h2>
                                <div className={cx('useremail')}>{users.email}</div>
                            </div>
                        </div>
                    </Skeleton>
                </Card>
            )}
            <TasksByUser tasks={tasks} loading={loading} />

            <ToastContainer />

            {/* form đổi mật khẩu */}
            <Drawer
                getContainer={false}
                title={t('Change password.1')}
                width={480}
                onClose={closeChangepass}
                visible={changepass}
            >
                <Form onFinish={onChangpass} form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Form.Item
                        name="password"
                        label={t('New password.1')}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            {
                                type: 'string',
                                min: 6,
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label={t('Confirm password.1')}
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error('The two passwords that you entered do not match!'),
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <div className="d-flex space-between">
                            <Button htmlType="button" icon={<StopOutlined />} onClick={handleCancel}>
								{t('Cancel.1')}
                            </Button>
                            <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
								{t('Save.1')}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
}

export default Detail;
