import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tag, Avatar, Spin, Breadcrumb, Form, Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import Highlighter from 'react-highlight-words';
import React, { useEffect, useState, useRef } from 'react';
//import Kanban from '~/components/Kanban';
import { Link } from 'react-router-dom';
import * as tasksServices from '~/services/tasksService';
import * as userServices from '~/services/searchService';
import { useTranslation } from 'react-i18next';
import tasksFilterStore from '~/stores/tasksFilterStore';
function Tasks() {
    const taskFilterData = tasksFilterStore((state) => state.tasksFilter);
    const setTasksFilter = tasksFilterStore((state) => state.setTasksFilter);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [users, setUsers] = useState([]);
    let dateTimeFilter = tasksServices.changeDateFormat(taskFilterData);
    useEffect(() => {
        let isCanceled = false;
        const fetchApi = async () => {
            const result = await tasksServices.tasks();
            if (!isCanceled) {
                const resultArray = result.map((elm) => ({
                    key: elm.id,
                    id: elm.id,
                    status: elm.task_status,
                    task_name: elm.task_name,
                    duan: elm.duan,
                    duanId: elm.task_project,
                    avatar: elm.avatar,
                    thoigian: elm.thoigian,
                    task_status: elm.task_status,
                    task_assign: elm.task_assign,
                    create_at: elm.create_at,
                    assign_id: elm.task_assign_id,
                }));
                setData(resultArray);
                filterData(taskFilterData, resultArray);
                //setTasks(resultArray);
                setLoading(false);
            }
        };
        fetchApi();
        const fetchAllUsers = async () => {
            const result = await userServices.users();
            if (!isCanceled) {
                setUsers(result);
            }
        };
        fetchAllUsers();
        //cleanup
        return () => {
            isCanceled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getAvatarFromArr = (data) => {
        var test = data.split(',').map(Number);
        let answer = users
            .filter((item) => test.includes(item.id))
            .map((item) => <Avatar src={item.avatar} key={item.id} />);
        return answer;
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title: t('Assign.1'),
            dataIndex: 'assign_id',
            id: 'avatar',
            key: 'assign_id',
            render: (assign_id) => <Avatar.Group>{getAvatarFromArr(assign_id)}</Avatar.Group>,
            sorter: (a, b) => a.assign_id.length - b.assign_id.length,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: t('Task name.1'),
            dataIndex: 'task_name',
            key: 'task_name',
            width: '30%',
            ...getColumnSearchProps('task_name'),
            render: (text, key) => <Link to={`/tasks/${key.key}`}>{text}</Link>,
        },
        {
            title: t('Project.2'),
            dataIndex: 'duan',
            key: 'duan',
            width: '20%',
            ...getColumnSearchProps('duan'),
            sorter: (a, b) => a.duan.length - b.duan.length,
            sortDirections: ['descend', 'ascend'],
            render: (text, key) => (
                <Link to={`/projects/${key.duanId}`} className="text-black">
                    {text}
                </Link>
            ),
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'create_at',
            width: '20%',
            render: (create_at) => formatDate(new Date(create_at)),
        },
        {
            title: t('Duedate.1'),
            dataIndex: 'thoigian',
            key: 'thoigian',
            width: '20%',
            sorter: (a, b) => a.thoigian - b.thoigian,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: t('Status.1'),
            dataIndex: 'task_status',
            id: 'task_status',
            filters: [
                { text: 'Todo', value: 'Todo' },
                { text: 'Doing', value: 'Doing' },
                { text: 'Review', value: 'Review' },
                { text: 'Done', value: 'Done' },
                { text: 'Hold', value: 'Hold' },
            ],
            onFilter: (value, record) => record.task_status.includes(value),
            render: (task_status) => <Tag className={`ant-tag-${task_status}`}>{task_status}</Tag>,
            sorter: (a, b) => a.task_status.length - b.task_status.length,
            sortDirections: ['descend', 'ascend'],
        },
    ];
    function formatDate(date) {
        let inputDate = new Date(date);
        const day = inputDate.getDate() < 9 ? `0${inputDate.getDate()}` : inputDate.getDate();
        const month = inputDate.getMonth() < 9 ? `0${inputDate.getMonth() + 1}` : inputDate.getMonth() + 1;
        const year = inputDate.getFullYear();
        return `${day}/${month}/${year}`;
    }
    const filterData = (dateString, data) => {
        if (dateString != '') {
            let result = get_data_in_month(data, dateString);
            setTasks(result);
        } else {
            setTasks(data);
        }
    };
    const onChange = (date, dateString) => {
        setTasksFilter(dateString);
        filterData(dateString, data);
    };
    const get_data_in_month = (data, timeCreate) => {
		// Convert timeCreate to date object
		const [month, year] = timeCreate.split('/');
		// Filter data based on month and year of created_at field
		const filteredData = data.filter((item) => {
			const itemDate = new Date(item.create_at);
			return itemDate.getMonth() === parseInt(month) - 1 && itemDate.getFullYear() === parseInt(year);
		});
		return filteredData;
	};
	
	
    return (
        <div>
            <div className="mb-24">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">{t('Home.1')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t('Tasks list.1')}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {/* <Kanban tasks={tasks} users= {users} /> */}
            <div className="mb-12">
                <Form layout="inline">
                    <Form.Item name="chouseMonth" label="Lọc công việc theo tháng">
                        <DatePicker
                            onChange={onChange}
                            defaultValue={dayjs(dateTimeFilter, 'MM/YYYY')}
                            picker="month"
                            format="MM/YYYY"
                        />
                    </Form.Item>
                </Form>
            </div>
            <Spin spinning={loading}>
                <Table columns={columns} dataSource={tasks} />
            </Spin>
        </div>
    );
}
export default Tasks;
