import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalOutline, AppOutline, SetOutline, UnorderedListOutline, UserOutline } from 'antd-mobile-icons';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
const cx = classNames.bind(styles);
function MenuFooter () {
  console.log('load menu');
  let navigate = useNavigate();
  const [active, setActive] = useState("Home");
  const setRouteActive = (value) => {
    return navigate(`${value}`);
  };
  return (
    <div className={cx('menufooter')}>
      <div className='menufooter_mobile'>
        <ul>
          <li className={`list ${active == 'Home' && 'active'}`} onClick={() => { setActive('Home'); setRouteActive('/dashboard') }}>
            <a href="#1">
              <GlobalOutline className="icon" />
              <span className='text'>Home</span>
            </a>
          </li>
          <li className={`list ${active == 'Tasks' && 'active'}`} onClick={() => { setActive('Tasks'); setRouteActive('/tasks') }}>
            <a href="#1">
              <UnorderedListOutline className="icon" />
              <span className='text'>Tasks</span>
            </a>
          </li>
          <li className={`list ${active == 'Projects' && 'active'}`} onClick={() => { setActive('Projects'); setRouteActive('/projects') }}>
            <a href="#1">
              <AppOutline className="icon" />
              <span className='text'>Projects</span>
            </a>
          </li>
          <li className={`list ${active == 'Users' && 'active'}`} onClick={() => { setActive('Users'); setRouteActive('/users') }}>
            <a href="#1">
              <UserOutline className="icon" />
              <span className='text'>Users</span>
            </a>
          </li>
          <li className={`list ${active == 'Setting' && 'active'}`} onClick={() => { setActive('Setting'); setRouteActive('/users') }}>
            <a href="#1">
              <SetOutline className="icon" />
              <span className='text'>Setting</span>
            </a>
          </li>
          <div className='indicator'></div>
        </ul>
      </div>
    </div>
  );
}
export default MenuFooter;
