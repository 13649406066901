import PropTypes from 'prop-types';
import Header from '~/layouts/components/Header';
import Sidebar from './Sidebar';
import { Layout } from 'antd';
import React, {useState} from 'react';
import sidebarStore from '~/stores/sidebarColspanStore';

const { Content, Sider } = Layout;

function DefaultLayout({ children }) {

	const setSidebarCollapse 	= sidebarStore((state) => state.setSidebarCollapse);
	const sidebarStatus 		= sidebarStore((state) => state.sidebarCollapse); 

	const [collapsed, setCollapsed] = useState(sidebarStatus); console.log(collapsed)
	const setcollap = () => {
		setCollapsed(!collapsed)
		setSidebarCollapse(collapsed)
	}
    return (
        <Layout>
            <Header setcollap={sidebarStatus} />
            <Layout className="site-layout-background">
                <Sider collapsible collapsed={sidebarStatus} onCollapse={(value) => setcollap(value)}>
                    <Sidebar />
                </Sider>
                <Content
					className='mainlayout'
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DefaultLayout;
