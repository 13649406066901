import { Button, Form, Input, Select, PageHeader } from 'antd';
import { LeftOutlined, SaveOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import * as partnersServices from '~/services/partnersService';
import * as projectsServices from '~/services/projectsService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 16,
    },
};

function AddNewCanbo() {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const [partner, setPartner] = useState([]);
    const { Option } = Select;
    const [value, setValue] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchApi1 = async () => {
            const result = await partnersServices.partners();
            setPartner(result);
        };
        fetchApi1();
        form.setFieldsValue({
            prj_name: '',
            prj_desc: '',
            prj_partner: '',
        });
    }, [form]);

    const onFinish = async (values) => {
        console.log(values);
        const result = await projectsServices.addProject(values);
        navigate(`/canbo`, { state: result });
    };
    const cancelEdit = () => {
        navigate(`/canbo`);
    };

    return (
        <>
            <PageHeader
                className="site-page-header p0"
                onBack={() => {
                    navigate(`/canbo`);
                }}
                title={t('Create new office staff.1')}
            />
            <div className="rounder">
                <Form {...layout} name="nest-messages" onFinish={onFinish} form={form}>
                    <Form.Item
                        name="name"
                        label={t('Name.1')}
                        rules={[{ required: true, message: 'Insert project name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label={t('Mobile.1')}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="skype"
                        label={t('Nick skyper.1')}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={t('Email.1')}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="desc"
                        label={t('Descriptions.1')}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
                        <div className="d-flex space-between">
                            <Button htmlType="button" icon={<LeftOutlined />} onClick={cancelEdit}>
                                {t('Back.1')}
                            </Button>
                            <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                                {t('Submit.1')}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
}
export default AddNewCanbo;
