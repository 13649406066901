import React, { useState } from 'react';
import { NavBar, TabBar } from 'antd-mobile';
import { useNavigate, useLocation, MemoryRouter as Router } from 'react-router-dom';
import { AppOutline, MessageOutline, UnorderedListOutline, UserOutline } from 'antd-mobile-icons';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import MenuFooter from '~/components/MenuMobile/MenuFooter';
const cx = classNames.bind(styles);

const Bottom = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    var array = pathname.split('/');
    const setRouteActive = (value) => {
        return navigate(`${value}`);
    };

    const tabs = [
        {
            key: '/projects',
            title: 'Projects',
            icon: <AppOutline />,
        },
        {
            key: '/tasks',
            title: 'Tasks',
            icon: <UnorderedListOutline />,
        },
        {
            key: '/users',
            title: 'Users',
            icon: <UserOutline />,
        },
        {
            key: '/message',
            title: '我的消息',
            icon: <MessageOutline />,
        },
    ];

    return (
        <TabBar activeKey={`/${array[1]}`} onChange={(value) => setRouteActive(value)}>
            {tabs.map((item) => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
        </TabBar>
    );
};
function MobileLayout({ children }) {
    return (
        <div className={cx('app')}>
            <div className={cx('body')}>
                <div className={cx('content')}>{children}</div>
            </div>
            {/* <div className={cx('bottom')}>
                <Bottom />
            </div> */}
			<MenuFooter />
        </div>
    );
}

export default MobileLayout;
