import * as httpRequest from '~/utils/httpRequest';
import axios from 'axios';
import { getUser } from '~/utils/Common';
const currentUser = getUser();
export const projects = async () => {
    try {
        const res = await httpRequest.get('/projects', {
            params: {},
        });
        return res.projects;
    } catch (error) {
        console.log(error);
    }
};

export const projectsbycustomer = async (id) => {
    try {
        const res = await httpRequest.get(`/projectsbycustomer/${id}`, {
            params: {},
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const projectsdetail = async (id) => {
    try {
        const res = await httpRequest.get(`/duan/${id}`, {
            params: {},
        }); 
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const demo = async () => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: currentUser.apiKey,
    };
    const data = [];
    await axios
        .post(`/duan`, data, {
            headers: headers,
        })
        .then((response) => {
            console.log(response);
        })
        .catch((error) => {});
};

export const addProject = async (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('prj_name', data.prj_name);
    bodyFormData.append('prj_desc', data.prj_desc);
    bodyFormData.append('prj_partner', data.prj_partner);
    try {
        const res = await httpRequest.post(
			`/duan`, 
			bodyFormData,
		);console.log(res)
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const componentDidMount = (data) => {
    const requestOptions = {
        method: 'POST',
        // headers =  {
        // 	'Content-Type': 'application/json',
        // 	'Authorization': currentUser.apiKey,
        // 	'My-Custom-Header': 'foobar'
        //   },
        body: JSON.stringify({
            prj_name: data.prj_name,
            prj_desc: data.prj_desc,
            prj_partner: data.prj_partner,
        }),
    };
    fetch('/duan', requestOptions)
        .then((response) => response.json())
        .then((data) => console.log(data));
};

export const editProject = async (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('prj_name', data.prj_name);
    bodyFormData.append('prj_desc', data.prj_desc);
    bodyFormData.append('prj_partner', data.prj_partner);
    try {
        const res = await httpRequest.post(
            `/editproject/${data.id}`,
            bodyFormData,
        ); 
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const updateFieldProject = async (data) => { 
    var bodyFormData = new FormData();
    bodyFormData.append('key', data.key);
    bodyFormData.append('value', data.value);
    try {
        const res = await httpRequest.post(
            `/editlineproject/${data.id}`,
            bodyFormData,
        );
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const projectprogress = async (id) => {
    try {
        const res = await httpRequest.get(`/projectprogress/${id}`, {
            params: {},
        }); 
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const addTimeline = async (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('pid', data.pid);
    bodyFormData.append('datetime', data.datetime);
    bodyFormData.append('author', data.author);
    bodyFormData.append('title', data.title);
    try {
        const res = await httpRequest.post(
			`/addtimelineproject`, 
			bodyFormData,
		);console.log(res)
        return res;
    } catch (error) {
        console.log(error);
    }
};