const routes = {
	home: '/',
	dashboard: '/dashboard',
	syslogs: '/syslogs',
	following: '/following',
	upload: '/upload',
	profiledetail: '/profiles/:profileId',
	users: '/users',
	demo: '/demo',
	profiles: '/profiles',
	changpass: '/users/changepass',
	userdetail: '/users/:userId',
	useredit: '/editprofile',
	useraddnew: '/users/addnew',
	tasks: '/tasks',
	tasksdetail: '/tasks/:taskId',
	taskedit: '/edittask/:taskId',
	taskaddnew: '/tasks/addnew',
	tasksbytag: '/tasks/tag/:taskId',
	projects: '/projects',
	projectlist: '/projectlist/:projectId',
	projectsdetail: '/projects/:projectId',
	projectedit: '/editproject/:projectId',
	projectviewflow: '/projectviewflow/:projectId',
	projectaddnew: '/createproject',
	search: '/search',
	test: '/test',
	FormComponents: '/form-components',
	AntFormComponents: '/ant-form-components',
	canbo: '/canbo',
	canboaddnew: '/canbo/addnew',
	phongban: '/phongban',
	chatroom: '/chatroom',
	login: '/login',
	logout: '/logout',
	page404: '/*',
};

export default routes;
