import { useEffect, useState } from 'react';
import Button from '~/components/Button';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as searchServices from '~/services/searchService';
import classNames from 'classnames/bind';
import styles from './Test.module.scss';

const cx = classNames.bind(styles);
function Test() {
    const [searchResult, setSearchResult] = useState([]);
    const [listC, setListC] = useState(true); // show hide list country
    const [detailCoutry, setDetailCountry] = useState([]);
    const [currentindex, setcurrentindex] = useState([]);

    useEffect(() => {
        const fetchApi = async () => {
            const result = await searchServices.test();
            setSearchResult(result);
        };

        fetchApi();
    }, []);

    // const viewDetail = () => {
    //     //setListC(false);
    // };
    // const findArrayElementByTitle = (e) => {

    //     return searchResult.find((element) => {
    //         return element.name.common === name;
    //     });
    // };

    const getIndex = (value, arr) => {
        for (var i = 0; i < arr.length; i++) {
            if (i === value) {
                setDetailCountry(arr[i]);
                console.log(arr[i]);
            }
        }
        setcurrentindex(value);
        setListC(false);
        return -1; //to handle the case where the value doesn't exist
    };

    const backtolist = () => {
        setListC(true);
    };

    const viewnext = () => {
        getIndex(currentindex + 1, searchResult);
    };
    const viewpre = () => {
        getIndex(currentindex - 1, searchResult);
    };

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <div>
            {listC ? (
                <div className={cx('wrapper')}>
                    <div className={cx('listcoutries')}>
                        {searchResult.map((e, index) => (
                            <div className={cx('item')} key={index} onClick={() => getIndex(index, searchResult)}>
                                <img className={cx('img')} src={e.flags.svg} alt={e.name.common} /> {e.name.common}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className={cx('wrapper')}>
                    <div className={cx('card')}>
                        <div>
                            <h2>{detailCoutry.name.common}</h2>
                            <p>
                                Dân số: <b>{numberWithCommas(detailCoutry.population)}</b> người
                            </p>
                            <p>
                                Diện tích: <b>{detailCoutry.area}</b> km²
                            </p>
                            <p>
                                Thủ đô: <b>{detailCoutry.capital}</b>
                            </p>
                            <p>
                                Xem trên{' '}
                                <a target="_blank" rel="noreferrer" href={detailCoutry.maps.googleMaps}>
                                    googlemap
                                </a>
                            </p>
                        </div>
                        <div>
                            <img
                                className={cx('quochuy')}
                                src={detailCoutry.coatOfArms.svg}
                                alt={detailCoutry.name.common}
                            />
                        </div>
                    </div>
                    <div className={cx('footer')}>
                        <Button onClick={backtolist} primary>
                            <FontAwesomeIcon icon={faChevronLeft} /> Back to list
                        </Button>
                        <div>
                            <Button onClick={() => viewpre()}>
                                <FontAwesomeIcon icon={faChevronLeft} /> Pre
                            </Button>
                            <Button onClick={() => viewnext()}>
                                Next <FontAwesomeIcon icon={faChevronRight} />
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Test;
