import { Avatar, Form, Input, DatePicker, Modal, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import latinize from 'latinize';
import * as searchServices from '~/services/searchService';
import * as canboServices from '~/services/canboService';
import DropdownUser from '~/components/Ant/Dropdown_users';
import { useTranslation } from 'react-i18next';

const ModalAddNewTask = ({ visible, onCreate, onCancel, usersAssign }) => {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const dateFormat = 'DD/MM/YYYY';
    const [tags, setTags] = useState([]);
	const [canbo, setCanbo] = useState([]);
    const { Option } = Select;
    const [dateOrigin, setDateOrigin] = useState('');
    const onChange = (date) => {
        setDateOrigin(date);
    };
    useEffect(() => {
		console.log('aaaa')
        const getApiTags = async () => {
            const result = await searchServices.getAllTags();
            setTags(result);
        };
        getApiTags();
		const listcanbo = async () => {
            const result = await canboServices.getallcanbo();
            setCanbo(result);
        };
        listcanbo();
    },[]);
    return (
        <Modal
            forceRender
            visible={visible}
            title="Add new task"
            okText="Save"
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        values.original = dateOrigin;
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    modifier: 'public',
                }}
            >
               <Form.Item
                        name="task_name"
                        label={t('Task name.1')}
                        rules={[{ required: true, message: 'Insert task name' }]}
                    >
                        <Input />
                </Form.Item>
                <DropdownUser data={usersAssign} labeltitle={t("Assign.1")} />
                <Form.Item name="duedate" label="Duedate">
                    <DatePicker onChange={onChange} format={dateFormat} />
                </Form.Item>
                <Form.Item name="task_tag" label={t('Tags.1')}>
                    <Select showSearch optionFilterProp="children" mode="multiple" allowClear="true">
                        {tags.map((e) => (
                            <Option key={e.id} value={e.name}>
                                {e.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="task_canboyc" label={t('Office staff.2')}>
                    <Select showSearch optionFilterProp="children">
                        {canbo.map((e) => (
                            <Option key={e.id} value={e.id}>
                                {e.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="task_emailyc" label="Email yêu cầu">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default ModalAddNewTask;
