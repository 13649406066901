import { Button, Form, Input, message } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import React from 'react';
import { setUserSession } from '~/utils/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import * as APIServices from '~/services/searchService';
import * as UsersServices from '~/services/usersService';
import classNames from 'classnames/bind';
import styles from './Login.module.scss';
import moment from 'moment';
const cx = classNames.bind(styles);

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 16,
    },
};

function Login() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const redirectPath = location.state?.path || '/dashboard';
    const onSubmit = async (values) => {
        // const ip = await UsersServices.getIp();
        // values.ip = ip.IPv4;
        // values.country_code = ip.country_code;
        // values.country_name = ip.country_name;
        // values.latitude = ip.latitude;
        // values.longitude = ip.longitude;
        const result = await APIServices.login(values);
        if (result.error === false) {
        setUserSession(result);
        return navigate(redirectPath, { replace: true });
        } else {
        message.error(result.message);
        }
    };
    return (
        <>
            <div className={cx('wrapper')}>
                <Form {...layout} name="nest-messages" onFinish={onSubmit} form={form}>
                    <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Insert email account' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
                        <div className="d-flex space-between">
                            <Button type="primary" icon={<LoginOutlined />} htmlType="submit">
                                Login
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
}

export default Login;
