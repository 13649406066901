import React from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from './FormikControl';
import Button from "./Button";
import * as libs from './myLibs'

function FormikContainer() {
	const dropdownOptions = [
		{ key: 'en', value: 'English' },
		{ key: 'vn', value: 'VietNam' },
		{ key: 'cn', value: 'Chinese' },
		{ key: 'tw', value: 'Taiwan' },
		{ key: 'ru', value: 'Russian' },
		{ key: 'ca', value: 'Canada' },
	];
	const radioOptions = [
		{ key: 'en', value: 'English' },
		{ key: 'vn', value: 'VietNam' },
		{ key: 'cn', value: 'Chinese' },
	];
	const checkboxOptions = [
		{ key: '1', value: 'Tập Gym' },
		{ key: '2', value: 'Bóng đá' },
		{ key: '3', value: 'Bơi lội' },
	];

	const initialValues = {
		email: '',
		desc: '',
		language: '',
		myradio: '',
		mycheckbox: [],
		myDate: null,
	};
	const validationSchema = Yup.object({
		email: Yup.string().email('Invalid email').required('Vui lòng nhập thông tin'),
		desc: Yup.string().required('required'),
		language: Yup.string().required('required'),
		myradio: Yup.string().required('required'),
		mycheckbox: Yup.array().required('required'),
		myDate: Yup.date().required('required').nullable(),
	});
	const onSubmit = (values) => {
		values.deadline = libs.formatDate(values.myDate);
		console.log(values);
	};
	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
			{(Formik) => (
				<Form>
					<FormikControl control="input" label="email" name="email" />
					<FormikControl control="textarea" label="Description" name="desc" />
					{/* <select defaultValue={'DEFAULT'} name='language'  {...register('language')}>
						{dropdownOptions.map((e) => (
							<option key={e.key} value={e.key} selected={e.key === 'vn'}>{e.value}</option>
						))}

					</select> */}
					<FormikControl
						selected='vn'
						control="select"
						label="Chouse Language..."
						name="language"
						options={dropdownOptions}
						value="vn"
					/>
					<FormikControl control="radio" label="Choice this" name="myradio" options={radioOptions} />
					<FormikControl control="checkbox" label="Check this" name="mycheckbox" options={checkboxOptions} />
					<FormikControl control="date" label="Chọn ngày..." name="myDate" />
					<Button type="submit" className="button" primary>
						Submit
					</Button>
				</Form>
			)}
		</Formik>
	);
}

export default FormikContainer;
