import { ExceptionOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './ProjectItem.module.scss';

const cx = classNames.bind(styles);

function ProjectItem({ data }) {
    return (
        <Link to={`/projects/${data.id}`} className={cx('item')}>
            <div className={cx('info')}>
                <h4 className={cx('name')}>
				<ExceptionOutlined /> <span>{data.name}</span>
                </h4>
                <span className={cx('username')}>{data.task_status}</span>
            </div>
        </Link>
    );
}

ProjectItem.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ProjectItem;
