import { Divider, Table, Tag, Row, Col, Spin, Space } from 'antd';
import { Grid, NavBar } from 'antd-mobile';
import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { DemoBlock, getAvatarFromArr } from '~/components/until';
import { ToastContainer, toast } from 'react-toastify';
import { Markup } from 'interweave';
import * as projectsServices from '~/services/projectsService';
import * as tasksServices from '~/services/tasksService';
import classNames from 'classnames/bind';
import styles from './Projects.module.scss';
import { FormOutlined } from '@ant-design/icons';

const cx = classNames.bind(styles);

function Detail() {
	const [loading, setLoading] = useState(true);
    const { projectId } = useParams('');
    const [data, setData] = useState([]);
    const [tasks, settasks] = useState([]);
    const [prjname, setPrjname] = useState();
    const navigate = useNavigate();
    useEffect(() => {
		setLoading(true);
        const fetchApi = async () => {
            const result = await projectsServices.projectsdetail(projectId);
            setData(result);
            setPrjname(result.prj_name);
			setLoading(false);
        };
        const fetchApi1 = async () => {
            const result = await tasksServices.tasksbyproject(projectId);
            const resultArray = result.map((elm) => ({
                key: elm.id,
                task_name: elm.task_name,
                avatar: elm.avatar,
                thoigian: elm.thoigian,
                task_status: elm.task_status,
            }));
            settasks(resultArray);
			setLoading(false);
        };
        const fetchProgress = async () => {
            const result = await projectsServices.projectprogress(projectId);
        };
        fetchApi();
        fetchApi1();
        fetchProgress();
    }, [projectId]);

    const showMessage = (message) => {
        toast.info(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const columns1 = [
        {
            title: 'Task name',
            dataIndex: 'task_name',
            id: 'key',
            render: (text, key) => <Link to={`/tasks/${key.key}`}>{text}</Link>,
        },
        {
            title: 'Status',
            dataIndex: 'task_status',
            id: 'task_status',
            render: (task_status) => <Tag className={`ant-tag-${task_status}`}>{task_status}</Tag>,
        },
    ];
	const backtop = () => {
        return navigate(-1);
    };
	const editProject = () => {
        return navigate(`/editproject/${data.id}`);
    };
	const right = (
        <div style={{ fontSize: 24 }}>
            <Space style={{ '--gap': '16px' }}>
                <FormOutlined onClick={editProject} />
            </Space>
        </div>
    );
    return (
        <>
            <NavBar onBack={backtop} className="headermobile" right={right}>
                Project detail
            </NavBar>
			<div className='sticky w-100'><div className='header-subtitle'>{prjname}</div></div>
            <DemoBlock>
                <Grid columns={1} gap={8}>
                    <Spin spinning={loading}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <div className={cx('wrapper')}>
                                    <div className="rounder mb-24">
                                        <div>
                                            <p>
                                                Khách hàng: {data.tenkhachhang} / {data.motakhachhang}
                                            </p>
                                            <Divider dashed />
                                            <div className="descriptionbox">
                                                <Markup content={data.prj_desc} />
                                            </div>
                                        </div>
                                    </div>
                                    <Table dataSource={tasks} columns={columns1} />
                                </div>
                            </Col>
                        </Row>
                    </Spin>
                </Grid>
            </DemoBlock>
            <ToastContainer />
        </>
    );
}

export default Detail;
