import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Bar } from '@ant-design/plots';

function BarChart() {
  const data = [
    {
      type: 'AA',
      sales: 38,
    },
    {
      type: 'BBB',
      sales: 52,
    },
    {
      type: 'CCC',
      sales: 61,
    },
  ];
  const config = {
    data,
    xField: 'sales',
    yField: 'type',
    legend: {
      position: 'top-left',
    },
    barBackground: {
      style: {
        fill: 'rgba(0,0,0,0.1)',
      },
    },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
  };
  return <Bar {...config} />;
};

export default BarChart;