import { Form, Input, Select, DatePicker, Button, Row, Col } from 'antd';
// import {
//   Form,
//   Input,
//   Button,
//   DatePicker
// } from 'antd-mobile'
import { Grid, NavBar } from 'antd-mobile';
import { StopOutlined, SaveOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';
import * as tasksServices from '~/services/tasksService';
import * as searchServices from '~/services/searchService';
import * as projectsServices from '~/services/projectsService';
import * as canboServices from '~/services/canboService';
import DropdownStatus from '~/components/Ant/Dropdown_status';
import DropdownUser from '~/components/Ant/Dropdown_users';
import QuillEditor from '~/components/QuillEditor';
import Checklistmb from '~/components/Ant/mobile_Checklist';
import { DemoBlock } from '~/components/until';

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 16,
    },
};
function EditPr() {
    const [form] = Form.useForm();
    const [users, setUsers] = useState([]);
    const [canbo, setCanbo] = useState([]);
    const [task, setTask] = useState([]);
    const [tags, setTags] = useState([]);
    const [taskStatus, setTaskStatus] = useState([]);
    const [project, setProject] = useState([]);
    // const [listemailassign, setListemailassign] = useState([]);
    const { Option } = Select;
    const { taskId } = useParams();
    // const [datechoice, setDatechoice] = useState(null);
    const [dateOrigin, setDateOrigin] = useState(task.dateOrigin);
    const navigate = useNavigate();
    const dateFormat = 'DD/MM/YYYY';

    useEffect(() => {
        const fetchApi = async () => {
            const result = await tasksServices.tasksbyid(taskId);
            setTask(result);
            //  setListemailassign(stringWidthQuote(result.task_assign));
            var arr = result.task_assign.split(',');
            setDateOrigin(result.date_original);
            form.setFieldsValue({
                task_name: result.task_name,
                task_des: result.task_des,
                task_project: result.task_project,
                task_canboyc: result.task_canboyc,
                task_assign: arr,
                task_tag: result.task_tag.toLocaleString(),
                task_status: result.task_status,
                task_emailyc: result.task_emailyc,
                task_duedate: moment(result.date_original),
            });
        };
        fetchApi();

        const fetchApi1 = async () => {
            const result = await projectsServices.projects();
            setProject(result);
        };
        fetchApi1();

        const fetchApi2 = async () => {
            const result = await searchServices.users();
            setUsers(result);
        };
        fetchApi2();

        // const fetchUsersByListEmail = async (listemailassign) => {
        //   const result = await searchServices.listusersbyemail(listemailassign);
        // };
        //fetchUsersByListEmail(listemailassign);

        const getApiStatus = async () => {
            const result = await searchServices.taskstatus();
            setTaskStatus(result);
        };
        getApiStatus();

        const getApiTags = async () => {
            const result = await searchServices.getAllTags();
            setTags(result);
        };
        getApiTags();

        const listcanbo = async () => {
            const result = await canboServices.getallcanbo();
            setCanbo(result);
        };
        listcanbo();
    }, [taskId, form]);

    const onFinish = async (values) => {
        if (values.task_tag) {
            // values.task_tag = values.task_tag.sort().toString();
        } else {
            values.task_tag = '';
        }
        values.task_assign = values.task_assign.toLocaleString();
        values.task_duedate = moment(dateOrigin).format('YYYY-MM-DD');
        values.date_original = dateOrigin;
        values.id = taskId;
        console.log(values);
        const result = await tasksServices.editTask(values);
        navigate(`/tasks/${taskId}`, { state: result });
    };
    const cancelEdit = () => {
        navigate(`/tasks/${taskId}`);
    };
    const onChange = (date, dateString) => {
        //   setDatechoice(dateString);
        setDateOrigin(date);
    };
    const backtop = () => {
        return navigate(-1);
    };
    return (
        <>
            <NavBar onBack={backtop} className="headermobile">
                Edit Task
            </NavBar>
            <DemoBlock>
                <Grid columns={1} gap={8}>
                    <Checklistmb data={project} />
                    <Form {...layout} name="nest-messages" onFinish={onFinish} form={form}>
                        <Form.Item
                            name="task_name"
                            label="Task Name"
                            rules={[{ required: true, message: 'Insert task name' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="task_project"
                            label="Project"
                            rules={[{ required: true, message: 'Please select project' }]}
                        >
                            <Select showSearch optionFilterProp="children">
                                {project.map((e) => (
                                    <Option key={e.id} value={e.id}>
                                        {e.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <DropdownUser data={users} />
                        <Form.Item name="task_duedate" label="Duedate">
                            <DatePicker onChange={onChange} format={dateFormat} />
                        </Form.Item>
                        <DropdownStatus data={taskStatus} />
                        <QuillEditor />
                        <Form.Item name="task_tag" label="Tags">
                            <Select showSearch optionFilterProp="children" mode="multiple" allowClear="true">
                                {tags.map((e) => (
                                    <Option key={e.id} value={e.name}>
                                        {e.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="task_canboyc" label="Can bo">
                            <Select showSearch optionFilterProp="children">
                                {canbo.map((e) => (
                                    <Option key={e.id} value={e.id}>
                                        {e.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="task_emailyc" label="Email yêu cầu">
                            <Input />
                        </Form.Item>
                    </Form>
                </Grid>
            </DemoBlock>
            <div className="action_footer">
                <Grid columns={2} gap={8}>
                    <Grid.Item>
                        <Button htmlType="button" icon={<StopOutlined />} onClick={cancelEdit}>
                            Cancel edit
                        </Button>
                    </Grid.Item>
                    <Grid.Item>
                        <Button type="primary" icon={<SaveOutlined />} htmlType="submit" className='w-100'>
                            Save
                        </Button>
                    </Grid.Item>
                </Grid>
            </div>
        </>
    );
}
export default EditPr;
