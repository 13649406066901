import moment from 'moment';
import { Tooltip, Avatar } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './chat.module.scss';
const cx = classNames.bind(styles);

function Item({ author, created, content, user, users }) {
	const getAvatarFromArr = (data) => {
        let answer = users
            .filter((item) => data.includes(item.email))
            .map((item) => <Avatar src={item.avatar} key={item.email} className={cx('avatar')} />);
        return answer;
    };
    return (
        <>
			{(author === user.email) ? (
				<div className={cx('itemchatfromme')}>
                <div>
                    <div className={cx('contentchat')}>
                        <div>{content}</div>
                    </div>
                </div>
            </div>
			) : (
				<div className={cx('itemchat')}>
                {getAvatarFromArr(author)}
                <div>
                    <div className={cx('headeritemchat')}>
                        <h4>{author}</h4>
                        <Tooltip title={moment(created).format('DD/MM/YYYY - HH:mm')}>
                            <span className={cx('created')}>{moment(created).fromNow()}</span>
                        </Tooltip>
                    </div>
                    <div className={cx('contentchat')}>
                        <div>{content}</div>
                    </div>
                </div>
            </div>
			)}
            
        </>
    );
}
Item.propTypes = {
    author: PropTypes.string,
    created: PropTypes.string,
    content: PropTypes.string,
};
export default Item;
