import React, { useEffect, useState } from 'react';
import * as tasksServices from '~/services/tasksService';
import Board, { moveCard } from '@lourenci/react-kanban';
import '@lourenci/react-kanban/dist/styles.css';
// Use your own styles to override the default styles
// import "./styles.css";
const board = {
    columns: [
        {
            id: 1,
            title: 'Need to do...',
            cards: [
                {
                    id: 1,
                    title: 'Card title 1',
                    description: 'Card content',
                    aaaa: 'ccccc',
                },
                {
                    id: 2,
                    title: 'Card title 2',
                    description: 'Card content',
                },
                {
                    id: 3,
                    title: 'Card title 3',
                    description: 'Card content',
                },
            ],
        },
        {
            id: 2,
            title: 'Doing',
            cards: [
                {
                    id: 9,
                    title: 'Card title 9',
                    description: 'Card content',
                },
            ],
        },
        {
            id: 3,
            title: 'Done',
            cards: [
                {
                    id: 10,
                    title: 'Card title 10',
                    description: 'Card content',
                },
                {
                    id: 11,
                    title: 'Card title 11',
                    description: 'Card content',
                },
            ],
        },
        {
            id: 4,
            title: 'Holding',
            cards: [
                {
                    id: 12,
                    title: 'Card title 12',
                    description: 'Card content',
                },
                {
                    id: 13,
                    title: 'Card title 13',
                    description: 'Card content',
                },
            ],
        },
        {
            id: 5,
            title: 'Holding',
            cards: [
                {
                    id: 14,
                    title: 'Card title 12',
                    description: 'Card content',
                },
                {
                    id: 15,
                    title: 'Card title 13',
                    description: 'Card content',
                },
            ],
        },
    ],
};
const removeCard = () => {
    console.log('aaaa');
};
function UncontrolledBoard() {
    return (
        <Board
            allowRemoveLane
            allowRenameColumn
            allowRemoveCard
            onLaneRemove={console.log}
            onCardRemove={console.log}
            onLaneRename={console.log}
            initialBoard={board}
            allowAddCard={{ on: 'top' }}
            onNewCardConfirm={(draftCard) => ({
                id: new Date().getTime(),
                ...draftCard,
            })}
            onCardNew={console.log}
        >
            {board}
        </Board>
    );
}


function Demo() {
    const [tasks, setTasks] = useState([]);
    const [tasks_doing, setTasks_doing] = useState([]);

    const gettaskbystatus = (e) => {
        console.log('call1');
        tasks.filter(function (el) {
            return (el.task_status = e);
        });
    };

    useEffect(() => {
        let isCanceled = false;
        const fetchApi = async () => {
            const result = await tasksServices.tasks();
            setTasks(result);
            if (!isCanceled) {
                setTasks_doing(abc);
            }
        };
        fetchApi();
        let abc = gettaskbystatus('Doing');
        console.log(abc);
        return () => {
            isCanceled = true;
        };
    }, []);

    return (
        <>
            <UncontrolledBoard />
        </>
    );
}

export default Demo;
