import { Table, Input, Breadcrumb, InputNumber, Form, Popconfirm, Typography, message, Spin, Button } from 'antd';
import {
    PlusOutlined
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {getallphongban} from '~/services/phongbanService';
import { useTranslation } from 'react-i18next';

function PhongBan() {
	const { t } = useTranslation();
    const originData = [];
    const [projects, setProjects] = useState([]);
    const [form] = Form.useForm();
    const [data, setData] = useState(originData);
    const [editingKey, setEditingKey] = useState('');
    const [sendRequest, setSendRequest] = useState(false);
    const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
    useEffect(() => {
        if (sendRequest) {
            setSendRequest(false);
        }
        const fetchApi = async () => {
            const result = await getallphongban();
            const resultArray = result.map((elm) => ({
                key: elm.id,
                tenphong: elm.tenphong,
                truongphong: elm.truongphong,
                khoi: elm.khoi
            }));
            setProjects(resultArray);
            setLoading(false);
        };
        fetchApi();
    }, [sendRequest]);

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            name: '',
            age: '',
            address: '',
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };
    const sendEditData = async (e) => {
        const values = { ...e[0], id: editingKey };
        values.mobile = values.mobile.replace(/\s/g, '');
       // const result = await canboServices.editCanbo(values);
       // message.info(result.message);
        setSendRequest(true);
    };
    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
            sendEditData(newData);
            setData([]);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };
	const addNewCanbo = () => {
		navigate(`/canbo/addnew`);
	}
    const columns = [
        {
            title: 'Tên phòng',
            dataIndex: 'tenphong',
            width: '25%',
            editable: true,
        },
        {
            title: 'Trưởng phòng',
            dataIndex: 'truongphong',
            width: '15%',
            editable: true,
        },
        {
            title: 'Khối trực thuộc',
            dataIndex: 'khoi',
            editable: true,
        },
        {
            title: t('Action.1'),
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.key)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a href="#1">Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Edit
                    </Typography.Link>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    return (
        <div>
            <div className="d-flex space-between">
                <div>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Settings</Breadcrumb.Item>
                    </Breadcrumb>
                    <h3>Phòng ban</h3>
                </div>
                <Button type="primary" icon={<PlusOutlined />} onClick={addNewCanbo}>
                    Create new
                </Button>
            </div>
            <Spin spinning={loading} tip="Loading...">
                <Form form={form} component={false}>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
						pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['30', '50', '100']}}
                        bordered
                        dataSource={projects}
                        columns={mergedColumns}
                        rowClassName="editable-row"
						size='small'
                    />
                </Form>
            </Spin>
        </div>
    );
}
export default PhongBan;
