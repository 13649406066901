import config from '~/config';

// Layouts
import { HeaderOnly } from '~/layouts';

// Pages
import Home from '~/pages/Home';
import Dashboard from '~/pages/Home/dashboard';
import Following from '~/pages/Following';
import Profiledetail from '~/pages/Profiles/detail';
import Upload from '~/pages/Upload';
import Search from '~/pages/Search';
import Test from '~/pages/Test';
import Users from '~/pages/Users';
import Chatroom from '~/pages/Chatroom';
import Syslogs from '~/pages/Syslogs';
import Changepass from '~/pages/Users/ChangePass';
import Profiles from '~/pages/Users/profiles';
import UserDetail from '~/pages/Users/detail';
import UserEdit from '~/pages/Users/edit';
import UserAddnew from '~/pages/Users/addnew';
import Tasks from '~/pages/Tasks';
import TaskDetail from '~/pages/Tasks/detail';
import TaskAddNew from '~/pages/Tasks/addnew';
import TaskEdit from '~/pages/Tasks/edit';
import TaskByTag from '~/pages/Tasks/viewbytags';
import Projects from '~/pages/Projects';
import Projectlist from '~/pages/Projects/projectlist';
import ProjectsDetail from '~/pages/Projects/detail';
import ProjectsAddNew from '~/pages/Projects/addnew';
import ProjectsEdit from '~/pages/Projects/edit';
import ProjectsViewFlow from '~/pages/Projects/viewflow';
import FormComponents from '~/pages/FormComponents';
import AntFormComponents from '~/pages/AntFormComponents';
import Demo from '~/pages/Demo';
import Canbo from '~/pages/Canbo';
import CanboAddnew from '~/pages/Canbo/addnew';
import PhongBan from '~/pages/PhongBan';
import Login from '~/pages/Login';
import Logout from '~/pages/Logout';
import Page404 from '~/pages/Page404';

// Public routes
const publicRoutes = [
    { path: config.routes.home, component: Home, layout: null },
    { path: config.routes.dashboard, component: Dashboard},
    { path: config.routes.following, component: Following },
    { path: config.routes.tasks, component: Tasks },
    { path: config.routes.tasksbytag, component: TaskByTag },
    { path: config.routes.projects, component: Projects },
    { path: config.routes.projectlist, component: Projectlist },
    { path: config.routes.test, component: Test },
    { path: config.routes.upload, component: Upload, layout: HeaderOnly },
    { path: config.routes.search, component: Search, layout: null },
    { path: config.routes.FormComponents, component: FormComponents },
    { path: config.routes.AntFormComponents, component: AntFormComponents },
    { path: config.routes.login, component: Login, layout: null },
    { path: config.routes.logout, component: Logout, layout: null },
    { path: config.routes.page404, component: Page404, layout: null },
	{ path: config.routes.useraddnew, component: UserAddnew },
    { path: config.routes.syslogs, component: Syslogs },
];

const privateRoutes = [
    { path: config.routes.profiledetail, component: Profiledetail },
    { path: config.routes.projectsdetail, component: ProjectsDetail },
    { path: config.routes.projectaddnew, component: ProjectsAddNew },
    { path: config.routes.projectedit, component: ProjectsEdit },
    { path: config.routes.projectviewflow, component: ProjectsViewFlow, layout: null },
    { path: config.routes.chatroom, component: Chatroom },
    { path: config.routes.canbo, component: Canbo },
    { path: config.routes.canboaddnew, component: CanboAddnew },
	{ path: config.routes.phongban, component: PhongBan },
    { path: config.routes.users, component: Users },
    { path: config.routes.changpass, component: Changepass },
	{ path: config.routes.profiles, component: Profiles },
    { path: config.routes.userdetail, component: UserDetail },
    { path: config.routes.useredit, component: UserEdit },
    { path: config.routes.tasksdetail, component: TaskDetail },
    { path: config.routes.taskedit, component: TaskEdit },
    { path: config.routes.taskaddnew, component: TaskAddNew },
    { path: config.routes.demo, component: Demo },
];

const adminRoutes = [
    
];

export { publicRoutes, privateRoutes, adminRoutes };
