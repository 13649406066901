import { Select, Form } from 'antd';
import {SyncOutlined, CheckCircleTwoTone, HistoryOutlined, FrownOutlined, SmileOutlined} from '@ant-design/icons';
import React from 'react';
import classNames from 'classnames/bind';
import styles from './Dropdown_status.module.scss';
const cx = classNames.bind(styles);
const { Option } = Select;

const DropdownStatus = ({ label, data, onOk, ...props }) => {
    const handleChange = (value) => {
        onOk(value);
    };
    const iconStatus = (e)=>{
      switch(e) {
        case 'Doing':
          return <SyncOutlined spin />
        case 'Done':
          return <CheckCircleTwoTone twoToneColor="#52c41a" />
        case 'Review':
          return <HistoryOutlined />
        case 'Hold':
          return <FrownOutlined />
        case 'Todo':
          return <SmileOutlined />
        default:
          return <span className='icon'></span>
      }
    }
    return (
        <Form.Item name="task_status" label={label} rules={[{ required: true, message: 'Select status' }]}>
            <Select
                style={{
                    width: 145,
                }}
                onChange={handleChange}
                {...props}
            >
                {data.map((e) => (
                    <Option key={e.id} value={e.name}>
                        <div className={cx(`dropdown-${e.name}`)}>
                            {iconStatus(e.name)} {e.mota}
                        </div>
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default DropdownStatus;
