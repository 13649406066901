import * as httpRequest from '~/utils/httpRequest';
import { getUser } from '~/utils/Common';
import axios from 'axios';
import { useEffect, useState } from 'react';
const currentUser = getUser();
if (currentUser) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${currentUser.apiKey}`;
}

export const searchTasks = async (q) => {
  try {
    const res = await httpRequest.get('/searchtasks', {
      params: {
        q,
      },
    });
    return res.tasks;
  } catch (error) {
    console.log(error);
  }
};
export const searchProjects = async (q) => {
  try {
    const res = await httpRequest.get('/searchprojects', {
      params: {
        q,
      },
    }); 
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const test = async () => {
  try {
    const res = await httpRequest.get('https://restcountries.com/v3.1/all', {
      params: {},
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getAllTags = async () => {
  try {
    const res = await httpRequest.get('/tags', {
      params: {},
    });
    return res.tags;
  } catch (error) {
    console.log(error);
  }
};
export const users = async () => {
  const cancelToken = axios.CancelToken.source()
  try {
    const res = await httpRequest.get('/nguoidung', {
      params: {},
      cancelToken: cancelToken.token
    });
    return res.nguoidung;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Cancelled!');
    } else {
      console.log(error);
    }
  }
  return () => {
    cancelToken.cancel()
  }
};

export const useFetchUsers = () => {
  const [data, setDataState] = useState(null);
  const [loading, setLoadingState] = useState(true);
  useEffect(() => {
    function fetchData() {
      setLoadingState(true);
      fetch('/nguoidung')
        .then(j => j.json())
        .then(data => {
          setDataState(data);
          setLoadingState(false);
        });
    }

    const interval = setInterval(() => {
      fetchData();
    }, 5000);

    fetchData();

    return () => clearInterval(interval);
  }, []);

  return [
    {
      data,
      loading
    }
  ];
}

export const usersbyemail = async (email) => {
  try {
    const res = await httpRequest.get(`/nguoidungdetail/${email}`, {
      params: {},
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listusersbyemail = async (email) => {
  try {
    const res = await httpRequest.get(`/listnguoidungbyemail/${email}`, {
      params: {},
    });
    return res.nguoidung;
  } catch (error) {
    console.log(error);
  }
};

export const listusersbyids = async (ids) => {
  try {
    const res = await httpRequest.get(`/listnguoidungbyids/${ids}`, {
      params: {},
    });
    return res.nguoidung;
  } catch (error) {
    console.log(error);
  }
};

export const editUser = async (data) => {
  var bodyFormData = new FormData();
  bodyFormData.append('first_name', data.first_name);
  bodyFormData.append('last_name', data.last_name);
  bodyFormData.append('username', data.username);
  bodyFormData.append('avatar', data.avatar);
  try {
    const res = await httpRequest.post(`/edituser/${data.id}`, bodyFormData);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addUser = async (data) => {
  var bodyFormData = new FormData();
  bodyFormData.append('email', data.email);
  bodyFormData.append('first_name', data.first_name);
  bodyFormData.append('last_name', data.last_name);
  bodyFormData.append('username', data.username);
  bodyFormData.append('password', data.password);
  try {
    const res = await httpRequest.post(`/register`, bodyFormData);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const changpassUser = async (data) => {
  var bodyFormData = new FormData();
  bodyFormData.append('password', data.password);
  bodyFormData.append('confirmpass', data.confirm);
  try {
    const res = await httpRequest.post(
      `/changepassnguoidung/${data.email}`,
      bodyFormData,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const taskstatus = async () => {
  try {
    const res = await httpRequest.get('/taskstatus', {
      params: {},
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const demo = async (data) => {
  var bodyFormData = new FormData();
  bodyFormData.append('first_name', data.first_name);
  bodyFormData.append('last_name', data.last_name);
  bodyFormData.append('username', data.username);
  bodyFormData.append('email', data.email);
  bodyFormData.append('avatar', data.avatar);

  await axios({
    method: 'post',
    url: `/edituser/${data.id}`,
    data: bodyFormData,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }).then((res) => {
    console.log(res.data);
    return res;
  });
};

export const login = async (data) => {
  var bodyFormData = new FormData();
  bodyFormData.append('email', data.email);
  bodyFormData.append('password', data.password);
  try {
    const res = await httpRequest.post(`/login`, bodyFormData);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const logchat = async () => {
  try {
    const res = await httpRequest.get('/logchat', {
      params: {},
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const sendlogchat = async (data) => {
  var bodyFormData = new FormData();
  bodyFormData.append('author', data.author);
  bodyFormData.append('content', data.content);
  try {
    const res = await httpRequest.post(
      `/sendlogchat`,
      bodyFormData,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const notes = async () => { 
  if (currentUser) {
    try {
      const res = await httpRequest.get(`/notebyuser/${currentUser.email}`, {
        params: {},
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
};
export const sendnote = async (data) => {
  var bodyFormData = new FormData();
  bodyFormData.append('author', currentUser.email);
  bodyFormData.append('title', data.title);
  bodyFormData.append('description', data.description);
  bodyFormData.append('datetime', data.datetime);
  try {
    const res = await httpRequest.post(
      `/sendnote`,
      bodyFormData,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const deletenote = async (data) => {
  var bodyFormData = new FormData();
  bodyFormData.append('author', currentUser.email);
  bodyFormData.append('id', data);
  try {
    const res = await httpRequest.post(
      `/deletenote`,
      bodyFormData,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
