import { Table, Card, Row, Col, Divider, Button, Segmented, Space, Spin } from 'antd';
import { PlusOutlined, AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import * as projectService from '~/services/projectsService';
import { useTranslation } from 'react-i18next';
import { Markup } from 'interweave';

function Projectlist() {
    const { t, i18n } = useTranslation();
    const { projectId } = useParams('');
    const [project, setProject] = useState([]);
    const [prjname, setPrjname] = useState('');
    const [showboard, setShowboard] = useState(true);
    const [loading, setLoading] = useState(true);
    const { Meta } = Card;
    const navigate = useNavigate();
    useEffect(() => {
        const fetchApi1 = async () => {
            const result = await projectService.projectsbycustomer(projectId);
            setProject(result);
            setLoading(false);
            if (result.length > 0) {
                setPrjname(result[0].partner);
            }
        };
        fetchApi1();
    }, [projectId]);
    const addNewProject = () => {
        navigate('/createproject');
    };
    const olala = (e) => {
        if (e === 'Kanban') {
            setShowboard(false);
        }
        if (e === 'List') {
            setShowboard(true);
        }
    };
    const columns1 = [
        {
            title: t('Project name.1'),
            dataIndex: 'name',
            id: 'key',
            render: (text, key) => <Link to={`/projects/${key.id}`}>{text}</Link>,
            width: '40%',
        },
        {
            title: t('Partner.1'),
            dataIndex: 'partner',
            id: 'khachhang',
            width: '130px',
        },
        {
            title: t('Descriptions.1'),
            dataIndex: 'mota',
            render: (mota) => <Markup content={mota} />,
        },
    ];
    return (
        <>
            <h3>List project of {prjname}</h3>
            <Space>
                <Button type="primary" icon={<PlusOutlined />} onClick={addNewProject}>
                    Create new project
                </Button>
                <Segmented
                    onChange={olala}
                    options={[
                        {
                            value: 'List',
                            icon: <BarsOutlined />,
                        },
                        {
                            value: 'Kanban',
                            icon: <AppstoreOutlined />,
                        },
                    ]}
                />
            </Space>
            <Divider />
            {showboard ? (
                <Spin spinning={loading}>
                    <Table dataSource={project} columns={columns1} pagination={80} />
                </Spin>
            ) : (
                <div className="listpartnerindex">
                    <Row gutter={[16, 16]}>
                        {project &&
                            project.map((e) => (
                                <Col span={6} key={e.id}>
                                    <Card hoverable>
                                        <Link to={`/projects/${e.id}`}>
                                            {' '}
                                            <Meta title={e.name} />
                                        </Link>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                </div>
            )}
        </>
    );
}

export default Projectlist;
