import React, { useEffect, useRef } from 'react';
import Item from './item';
import classNames from 'classnames/bind';
import styles from './chat.module.scss';
const cx = classNames.bind(styles);
function ChatItems({data, user, users}) {
	const AlwaysScrollToBottom = () => {
		const elementRef = useRef();
		useEffect(() => elementRef.current.scrollIntoView());
		return <div ref={elementRef} />;
	  };
    return (
        <>
            <div className={cx('listchatitems')}>
                {data.map((e) => (
                    <Item key={e.id} author={e.author} content={e.content} created={e.created} user={user} users={users}  />
                ))}
				<AlwaysScrollToBottom />
            </div>
        </>
    );
}

export default ChatItems;
