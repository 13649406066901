import moment from 'moment';
import { Timeline, Empty, Typography } from 'antd';
import React from 'react';
import images from '~/assets/images';
const { Text } = Typography;
const Logs = ({ data }) => (
    <>
        {data ? (
            <Timeline>
                {data.map((e) => (
                    <Timeline.Item color="green" key={e.id}>
                        <h5>
                            <strong>{moment(e.datetime).format('HH:mm:ss - DD/MM/YYYY ')}</strong>
                        </h5>
                        {e.author} {e.action} {e.title.split('task_status').join('status')}
                    </Timeline.Item>
                ))}
            </Timeline>
        ) : (
            <Empty
                image={images.empty.default}
                imageStyle={{
                    height: 60,
                }}
                description={<Text type="secondary">No data here yet</Text>}
            ></Empty>
        )}
    </>
);

export default Logs;
