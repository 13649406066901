import config from '~/config';

// Layouts
import { HeaderOnly } from '~/layouts';

// Pages
import Home from '~/pages/Home';
import Dashboard from '~/pages/Home/mobile_dashboard';
import Following from '~/pages/Following';
import Profiledetail from '~/pages/Profiles/detail';
import Upload from '~/pages/Upload';
import Search from '~/pages/Search';
import Test from '~/pages/Test';
import Users from '~/pages/Users/mobile_index';
import Changepass from '~/pages/Users/ChangePass';
import UserDetail from '~/pages/Users/mobile_detail';
import UserEdit from '~/pages/Users/edit';
import UserAddnew from '~/pages/Users/addnew';
import Tasks from '~/pages/Tasks/mobile_index';
import TaskDetail from '~/pages/Tasks/mobile_detail';
import TaskAddNew from '~/pages/Tasks/addnew';
import TaskEdit from '~/pages/Tasks/mobile_edit';
import TaskByTag from '~/pages/Tasks/viewbytags';
import Projects from '~/pages/Projects/mobile_index';
import Projectlist from '~/pages/Projects/projectlist';
import ProjectsDetail from '~/pages/Projects/mobile_detail';
import ProjectsAddNew from '~/pages/Projects/addnew';
import ProjectsEdit from '~/pages/Projects/mobile_edit';
import FormComponents from '~/pages/FormComponents';
import AntFormComponents from '~/pages/AntFormComponents';
import Canbo from '~/pages/Canbo';
import Login from '~/pages/Login';
import Logout from '~/pages/Logout';
import Page404 from '~/pages/Page404';
import MobileLayout from '~/layouts/MobileLayout';
import MobileLayoutNull from '~/layouts/MobileLayout/mobilelayoutnull';

// Public routes
const publicRoutesMobile = [
  { path: config.routes.home, component: Home },
  { path: config.routes.dashboard, component: Dashboard, layout: MobileLayout},
  { path: config.routes.following, component: Following },
  { path: config.routes.tasks, component: Tasks, layout: MobileLayout },
  { path: config.routes.tasksdetail, component: TaskDetail,layout: MobileLayout },
  { path: config.routes.tasksbytag, component: TaskByTag },

  { path: config.routes.projects, component: Projects, layout: MobileLayout },
  { path: config.routes.projectlist, component: Projectlist },
  { path: config.routes.projectsdetail, component: ProjectsDetail, layout: MobileLayout },
  { path: config.routes.test, component: Test },
  { path: config.routes.upload, component: Upload, layout: HeaderOnly },
  { path: config.routes.search, component: Search, layout: null },
  { path: config.routes.FormComponents, component: FormComponents },
  { path: config.routes.AntFormComponents, component: AntFormComponents },
  { path: config.routes.login, component: Login },
  { path: config.routes.logout, component: Logout, layout: null },
  { path: config.routes.page404, component: Page404, layout: null },
];

const privateRoutesMobile = [
  { path: config.routes.profiledetail, component: Profiledetail },
  { path: config.routes.projectaddnew, component: ProjectsAddNew },
  { path: config.routes.projectedit, component: ProjectsEdit, layout: MobileLayoutNull },
  { path: config.routes.canbo, component: Canbo },
  { path: config.routes.users, component: Users, layout: MobileLayout},
  { path: config.routes.changpass, component: Changepass },
  { path: config.routes.userdetail, component: UserDetail, layout: MobileLayout},
  { path: config.routes.useredit, component: UserEdit },
  { path: config.routes.useraddnew, component: UserAddnew },
  { path: config.routes.taskedit, component: TaskEdit, layout: MobileLayoutNull },
  { path: config.routes.taskaddnew, component: TaskAddNew },
];

export { publicRoutesMobile, privateRoutesMobile };
