import { ClockCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Timeline, Card, Typography, Button, Form, Modal, Empty, DatePicker } from 'antd';
import React, { useState } from 'react';
import { Markup } from 'interweave';
import QuillEditor from '~/components/QuillEditor';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;
const ProjectTimeline = ({ data, onCreate }) => {
	const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const [showModal, setShowModal] = useState(false);
    const dateFormat = 'DD/MM/YYYY';
    const [dateOrigin, setDateOrigin] = useState('');

    const onChange = (date) => {
        setDateOrigin(date);
    };
    const btnShowModal = () => {
        setShowModal(true);
    };
    return (
        <>
            <Card title={t('Timeline.1')} extra={<Button onClick={btnShowModal}>{t('Add.1')}</Button>}>
                <Timeline>
                    {data.length > 0 ? (
                        <>
                            {data.map((e) => (
                                <Timeline.Item
                                    key={e.id}
                                    color="red"
                                    dot={<ClockCircleOutlined className="timeline-clock-icon" />}
                                >
                                    <h5 className="pt-3">
                                        <Text type="danger">{e.datetime}</Text> / <UserOutlined /> {e.author}
                                    </h5>
                                    <div style={{ marginLeft: '-26px' }}>
                                        <Markup content={e.title} />
                                    </div>
                                </Timeline.Item>
                            ))}
                        </>
                    ) : (
                        <>
                            <Empty
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                imageStyle={{
                                    height: 60,
                                }}
                                description={
                                    <Text type="secondary">
                                        No data here yet, Click{' '}
                                        <a
                                            href="#1"
                                            onClick={() => {
                                                setShowModal(true);
                                            }}
                                        >
                                            here
                                        </a>{' '}
                                        to create project timeline
                                    </Text>
                                }
                            ></Empty>
                        </>
                    )}
                </Timeline>
            </Card>
            <Modal
                forceRender
                visible={showModal}
                title="Add timeline to Project"
                okText="Save"
                cancelText="Cancel"
                onCancel={() => {
                    setShowModal(false);
                }}
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            values.original = dateOrigin;
                            form.resetFields();
                            onCreate(values);
                            setShowModal(false);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="datetime"
                        label="Date"
                        rules={[
                            {
                                required: true,
                                message: 'Please chouse datetime',
                            },
                        ]}
                    >
                        <DatePicker onChange={onChange} format={dateFormat} />
                    </Form.Item>
                    <QuillEditor />
                </Form>
            </Modal>
        </>
    );
};

export default ProjectTimeline;
