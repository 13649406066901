import { getUser } from '~/utils/Common';
import { Divider, Breadcrumb, Button, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as searchServices from '~/services/searchService';
import ItemUserInList from './ItemUserInList';
import styles from './Users.module.scss';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import BarChart from '~/components/Chart';
const cx = classNames.bind(styles);

function Users() {
	const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const currentUser = getUser();
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    useEffect(() => {
        const fetchApi = async () => {
            const result = await searchServices.users();
            setUsers(result);
            setLoading(false);
        };
        fetchApi();
    }, []);
    const adduser = () => {
        navigate('/users/addnew');
    };
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/">{t('Home.1')}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t('User.1')}</Breadcrumb.Item>
            </Breadcrumb>
            <h3>{t('List users.1')}</h3>
            {currentUser.isadmin === 1 && (
                <div className="text-right">
                    <Button type="primary" icon={<PlusOutlined />} onClick={adduser}>
                        Add user
                    </Button>
                </div>
            )}

            <Divider />
			<BarChart />
            <Spin spinning={loading} tip="Loading...">
				<div className={cx('listusers')}>
				{users.map(data=>(
					<ItemUserInList data={data}  key={data.id} />
				))}
				</div>
            </Spin>
        </>
    );
}
export default Users;
