import {
    Button,
    Row,
    Col,
    message,
    Space,
    Avatar,
    Collapse,
    Progress,
    Checkbox,
    Popconfirm,
    Tooltip,
    Form,
    Menu,
    Affix,
} from 'antd';
import {
    UserOutlined,
    LeftOutlined,
    FormOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusCircleOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import { Grid, NavBar } from 'antd-mobile';
import React from 'react';
import { getUser } from '~/utils/Common';
import { Markup } from 'interweave';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as tasksServices from '~/services/tasksService';
import * as searchServices from '~/services/searchService';
import * as Libs from '~/components/myLibs';
import classNames from 'classnames/bind';
import styles from './Tasks.module.scss';
import Comments from './comments';
import Logs from './logs';
import moment from 'moment';
import { DemoBlock, getAvatarFromArr } from '~/components/until';
import QuillEditor from '~/components/QuillEditor';
import DropdownStatus from '~/components/Ant/Dropdown_status';

const cx = classNames.bind(styles);
const { Panel } = Collapse;
const currentUser = getUser();
function Detail() {
    const [form] = Form.useForm();
    const [formdes] = Form.useForm();
    let navigate = useNavigate();
    const { taskId } = useParams();
    const [task, setTask] = useState([]);
    const [taskStatus, setTaskStatus] = useState([]);
    const [todo, setTodo] = useState([]); //console.log(task)
    const [comments, setComments] = useState([]);
    const [logs, setlogs] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [activeTabKey2, setActiveTabKey2] = useState('taskComments');
    const [addnewTodoModal, setAddnewTodoModal] = useState(false); // ẩn hiện modal
    const [editTodoModal, setEditTodoModal] = useState(false); // ẩn hiện modal
    const [editDescription, setEditDescription] = useState(true); // edit vùng description
    const [checkcount, setCheckcount] = useState(''); // đếm số lượng todo đã xong
    const [percentTodo, setpercentTodo] = useState(0); // % todo đã làm
    const [sendRequest, setSendRequest] = useState(false);
    const [listemailassign, setListemailassign] = useState([]);
    const [listUsersAssign, setListUsersAssign] = useState([]);
    const onTab2Change = (key) => {
        setActiveTabKey2(key);
    };
    useEffect(() => {
        if (sendRequest) {
            setSendRequest(false);
        }
        const fetchApiTaskDetail = async () => {
            const result = await tasksServices.tasksbyid(taskId);
            setTask(result);
            // setListemailassign(Libs.stringWidthQuote(result.task_assign));
            if (listemailassign.length > 0) {
                const fetchUsersByListEmail = async (listemailassign) => {
                    const result = await searchServices.listusersbyemail(listemailassign);
                    setListUsersAssign(result);
                };
                fetchUsersByListEmail(listemailassign);
            }

            form.setFieldsValue({
                task_status: result.task_status,
            });
            if (form.__INTERNAL__.name) {
                formdes.setFieldsValue({
                    task_des: result.task_des,
                });
            }
        };
        fetchApiTaskDetail();

        const ApiTodo = async () => {
            const result = await tasksServices.todobytask(taskId);
            setTodo(result);
            setCheckcount(result.filter((props) => props.completed).length);
            setpercentTodo((result.filter((props) => props.completed).length / result.length) * 100);
        };
        ApiTodo();

        const ApiComment = async () => {
            const result = await tasksServices.commentsbytask(taskId);
            setComments(result);
        };
        ApiComment();

        const ApiLogs = async () => {
            const result = await tasksServices.logsbytask(taskId);
            setlogs(result);
        };
        ApiLogs();

        const getApiStatus = async () => {
            const result = await searchServices.taskstatus();
            setTaskStatus(result);
        };
        getApiStatus();
    }, [sendRequest, taskId, listemailassign, form, formdes]);

    const backToProject = () => {
        navigate(`/projects/${task.task_project}`);
    };
    const editTask = () => {
        return navigate(`/edittask/${task.id}`);
    };
    const tabListNoTitle = [
        {
            key: 'taskComments',
            tab: 'Comments',
        },
        {
            key: 'taskLogs',
            tab: 'Logs',
        },
    ];
    const onAddnewTodo = async (values) => {
        values.pid = taskId;
        values.duedate = moment(values.deadline).format('YYYY-MM-DD');
        setAddnewTodoModal(false);
        const result = await tasksServices.addtodo(values);
        message.info(result.message);
        setSendRequest(true);
    };
    const editTodo = (value) => {
        setEditTodoModal(true);
        var item = todo.find((item) => item.id === value);
        setDataEdit(item);
    };
    const onEditTodo = async (values) => {
        values.id = dataEdit.id;
        values.duedate = moment(values.deadline).format('YYYY-MM-DD');
        setEditTodoModal(false);
        const result = await tasksServices.edittodo(values);
        message.info(result.message);
        setSendRequest(true);
    };
    const confirmDeleteTask = async (e) => {
        const result = await tasksServices.deletetodo(e);
        message.info(result.message);
        const toDelete = new Set([e]);
        Libs.filterInPlace(todo, (obj) => !toDelete.has(obj.id)); // xóa phần tử trong mảng có id = e
        setTodo(todo);
        setSendRequest(true);
    };
    const onChange = async (e) => {
        todo[todo.findIndex((obj) => obj.id === e.target.value)].completed =
            !todo[todo.findIndex((obj) => obj.id === e.target.value)].completed;
        const result = await tasksServices.updateStatusTodo(e.target.value);
        console.log(result);
        message.info(result.message);
        setpercentTodo((todo.filter((props) => props.completed).length / todo.length) * 100);
        setCheckcount(todo.filter((props) => props.completed).length); //đếm số lượng đã check
    };
    const genExtra = () => (
        <Button
            type="dashed"
            icon={<PlusCircleOutlined />}
            onClick={(event) => {
                event.stopPropagation();
                setAddnewTodoModal(true);
            }}
        >
            Add todo
        </Button>
    );
	
    const updateStatus = async (e) => {
        let value1 = [];
        value1 = { id: taskId, key: 'task_status', value: e, author: currentUser.email };
        const result = await tasksServices.updateFieldTask(value1);
        message.info(result.message);
        setSendRequest(true);
    };

    const handleMenuClick = (e) => {
        message.info('Click on menu item.');
        console.log('click', e);
    };
    const menu = (
        <Menu
            onClick={handleMenuClick}
            items={[
                {
                    label: '1st menu item',
                    key: '1',
                    icon: <UserOutlined />,
                },
                {
                    label: '2nd menu item',
                    key: '2',
                    icon: <UserOutlined />,
                },
                {
                    label: '3rd menu item',
                    key: '3',
                    icon: <UserOutlined />,
                },
            ]}
        />
    );
    const backtop = () => {
        return navigate(-1);
    };
	const right = (
        <div style={{ fontSize: 24 }}>
            <Space style={{ '--gap': '16px' }}>
                <FormOutlined onClick={editTask} />
            </Space>
        </div>
    );
    return (
        <>
            <NavBar onBack={backtop} className="headermobile" right={right}>
                Task detail
            </NavBar>
            <div className='sticky w-100'><div className='header-subtitle'>{task.task_name}</div></div>
            <DemoBlock>
                <Grid columns={1} gap={8}>
                    <Row gutter={24}>
                        <Col className="gutter-row" span={24}>
                            <Collapse defaultActiveKey={['1']} className="mb-24" expandIconPosition="end">
                                <Panel header="Infomations" key="1">
                                    <Row gutter={[16, 16]}>
                                        <Col className="gutter-row" span={8}>
                                            Status:
                                        </Col>
                                        <Col className="gutter-row" span={16}>
                                            <div className="no-label" style={{ marginTop: '-5px' }}>
                                                <Form form={form}>
                                                    <DropdownStatus
                                                        data={taskStatus}
                                                        onOk={updateStatus}
                                                        bordered={false}
                                                    />
                                                </Form>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" span={8}>
                                            Create at:
                                        </Col>
                                        <Col className="gutter-row" span={16}>
                                            {task.create_at}
                                        </Col>
                                        <Col className="gutter-row" span={8}>
                                            Tags:
                                        </Col>
                                        <Col className="gutter-row" span={16}>
                                            {Libs.string2ArrWithLink(task.task_tag)}
                                        </Col>
                                        <Col className="gutter-row" span={8}>
                                            Assign:
                                        </Col>
                                        <Col className="gutter-row" span={16}>
                                            {listUsersAssign ? (
                                                <Avatar.Group>
                                                    {listUsersAssign.map((e) => (
                                                        <Tooltip title={e.last_name} placement="top" key={e.id}>
                                                            <Avatar src={e.avatar} size="small" />
                                                        </Tooltip>
                                                    ))}
                                                </Avatar.Group>
                                            ) : (
                                                <></>
                                            )}

                                            {task.last_name}
                                        </Col>
                                        <Col className="gutter-row" span={8}>
                                            Email:
                                        </Col>
                                        <Col className="gutter-row" span={16}>
                                            {task.task_emailyc ? (
                                                <a target="_blank" rel="noreferrer" href={task.task_emailyc}>
                                                    Link to email
                                                </a>
                                            ) : null}
                                        </Col>
                                        <Col className="gutter-row" span={8}>
                                            Deadline:
                                        </Col>
                                        <Col className="gutter-row" span={16}>
                                            {task.thoigian}
                                        </Col>
                                        <Col className="gutter-row" span={8}>
                                            Office Staff:
                                        </Col>
                                        <Col className="gutter-row" span={16}>
                                            <Tooltip placement="bottom" title={task.motacanbo}>
                                                {task.canboyeucau}
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                            <Collapse defaultActiveKey={['2']} className="mb-24" expandIconPosition="end">
                                <Panel header="Description" key="2" style={{ overflow: 'hidden' }}>
                                    <Markup content={task.task_des} />
                                </Panel>
                            </Collapse>

                            <Collapse defaultActiveKey={['todolist']} className="mb-24" expandIconPosition="end">
                                <Panel header="Todo list" key="todolist" extra={genExtra()}>
                                    Definition of done {checkcount} / {todo.length}
                                    {todo.length > 0 ? (
                                        <Progress
                                            className="mb-24"
                                            percent={Math.floor(percentTodo)}
                                            strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <div className="detaillisttodo">
                                        <table className="w-100">
                                            <tbody>
                                                {todo.map((e) => (
                                                    <tr key={e.id}>
                                                        <td>
                                                            <Checkbox
                                                                onChange={onChange}
                                                                checked={e.completed}
                                                                value={e.id}
                                                                disabled={!currentUser}
                                                            >
                                                                <h4 className={cx(e.completed ? `iscomplete` : '')}>
                                                                    {e.content}
                                                                </h4>
                                                            </Checkbox>
                                                        </td>
                                                        <td style={{ width: 35 }}>
                                                            <Avatar src={e.avatar} size="small" />
                                                        </td>
                                                        <td style={{ width: 75 }} className="text-right">
                                                            {e.deadline}
                                                        </td>
                                                        {currentUser ? (
                                                            <td style={{ width: 66 }} className="text-right">
                                                                <Space>
                                                                    <Button
                                                                        shape="circle"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            editTodo(e.id);
                                                                        }}
                                                                        icon={<EditOutlined />}
                                                                    ></Button>
                                                                    <Popconfirm
                                                                        title={`delete task?`}
                                                                        onConfirm={() => {
                                                                            confirmDeleteTask(e.id);
                                                                        }}
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button
                                                                            shape="circle"
                                                                            size="small"
                                                                            icon={<DeleteOutlined />}
                                                                        ></Button>
                                                                    </Popconfirm>
                                                                </Space>
                                                            </td>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Grid>
            </DemoBlock>
            <ToastContainer />
        </>
    );
}

export default Detail;
