import { Button, Form, Input, Select, Upload, Image, DatePicker, Switch } from 'antd';
import { LeftOutlined, SaveOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import Resizer from 'react-image-file-resizer';
import moment from 'moment';
import { useState } from 'react';
import { getallphongban } from '~/services/phongbanService';
import { editCanbo } from '~/services/canboService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImgCrop from 'antd-img-crop';
import { showResultRequest } from '~/services/libsCustom';
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};

function EditCanbo ({ ...props }) {
  const { dataEdit } = props;
  const [form] = Form.useForm();
  const [ava64, setAva64] = useState('');
  const [phongbanData, setPhongbanData] = useState([]);
  const { Option } = Select;
  const [valueMota, setValueMota] = useState('');
  const [dateOrigin, setDateOrigin] = useState();
  useEffect(() => {
    const fetProjectDetail = async () => {
      form.setFieldsValue({
        name: dataEdit.name,
        skyper: dataEdit.skyper,
        email: dataEdit.email,
        mobile: dataEdit.mobile,
        mota: dataEdit.mota,
        phongban: dataEdit.phongban,
      });
    };
    fetProjectDetail();
    const fetchApi1 = async () => {
      const result = await getallphongban();
      setPhongbanData(result);
    };
    fetchApi1();
    setFileList([{ uid: '-1', name: `${dataEdit.name}`, status: 'done', url: `/avatars/canbo${dataEdit.key}.jpg`, }])
  }, [dataEdit, form]);
  const [fileList, setFileList] = useState([
    {
      uid: '-1',
      name: `${dataEdit.name}`,
      status: 'done',
      url: `/avatars/canbo${dataEdit.key}.jpg`,
    },
  ]);
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
    Resizer.imageFileResizer(
      file,
      200,
      200,
      'JPEG',
      100,
      0,
      (uri) => {
        setAva64(uri);
      },
      'base64',
      200,
      200,
    );
  };
  const onChange = ({ fileList: newFileList }) => {
    console.log(newFileList);
    setFileList(newFileList);
  };
  const handleChange = (content, delta, source, editor) => {
    setValueMota(content)
  };
  const handleChangeDate = (date, dateString) => {
    setDateOrigin(date);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const onFinish = async (values) => {
    values = { ...values, id: dataEdit.key, mota: valueMota, birth: moment(dateOrigin).format('YYYY-MM-DD'), avatar: String(ava64) };
    const result = await editCanbo(values);
    showResultRequest(result);
    props.sendToParent(false);
  };
  const onChangeSwitch = (checked) => {
    console.log(`switch to ${checked}`);
  };
  return (
    <>
      <div className="rounder">
        <Form {...layout} name="nest-messages" onFinish={onFinish} form={form}>
          <Form.Item name="avatar" label="Avatar">
            <ImgCrop rotate>
              <Upload
                listType="picture-card"
                customRequest={dummyRequest}
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
              >
                {fileList.length < 1 && '+ Upload'}
              </Upload>
            </ImgCrop>
          </Form.Item>
          <Form.Item name="name" label="Họ tên" rules={[{ required: true, message: 'Nhập tên cán bộ' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="birth" label='Ngày sinh'>
            <DatePicker onChange={handleChangeDate} format='DD/MM/YYYY' />
          </Form.Item>
          <Form.Item name="phongban" label="Phòng ban">
            <Select showSearch optionFilterProp="children">
              {phongbanData?.map((e) => (
                <Option key={e.id} value={e.id}>
                  {e.tenphong}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="mobile" label="Mobile">
            <Input />
          </Form.Item>
          <Form.Item name="skyper" label="Nick Skyper">
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input />
          </Form.Item>
          <Form.Item name="phoihop" label="Quan tâm" tooltip='Thêm nhân vào danh sách Assign việc trong quản lý tasks'>
            <Switch defaultChecked onChange={onChangeSwitch} />
          </Form.Item>
          {/* <Form.Item name="mota" label="Mô tả">
                        <ReactQuill theme="snow" value={valueMota} onChange={handleChange} preserveWhitespace={true} />
                    </Form.Item> */}
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
            <div className="d-flex space-between">
              <Button
                htmlType="button"
                icon={<LeftOutlined />}
                onClick={() => {
                  props.sendToParent(false);
                  form.resetFields();
                }}
              >
                Hủy
              </Button>
              <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                Lưu
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
export default EditCanbo;
