import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './TaskItem.module.scss';

const cx = classNames.bind(styles);

function TaskItem({ data, users, handleHideResult }) {
	const getAvatarFromArr = (data) => {
		var test = data.split(',').map(Number);
		let answer = users
		  .filter((item) => test.includes(item.id))
		  .map((item) => <Avatar src={item.avatar} key={item.id} size='small' />);
		return answer;
	  };

    return (
        <Link to={`/tasks/${data.id}`} className={cx('wrapper')} onClick={()=>{handleHideResult()}}>
            <div className={cx('info')}>
                <h4 className={cx('name')}>
                    <span>{data.task_name}</span>
                </h4>
                <span className={cx('username')}>{data.task_status}</span>
            </div>
			<Avatar.Group>{getAvatarFromArr(data.task_assign_id)}</Avatar.Group>
        </Link>
    );
}

TaskItem.propTypes = {
    data: PropTypes.object.isRequired,
};

export default TaskItem;
