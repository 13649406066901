import React from 'react';
import { Avatar } from 'antd';

export const DemoBlock = ({ title, children }) => (
    <div style={{ padding: 16 }}>
        <h3>{title}</h3>
        {children}
    </div>
);

export const getAvatarFromArr = (data, users) => {
    var test = data.split(',').map(Number);
    let answer = users
        .filter((item) => test.includes(item.id))
        .map((item) => <Avatar src={item.avatar} key={item.id} />);
    return answer;
};
