import React from 'react';
import DateView, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Field, ErrorMessage } from 'formik';
import TextError from './Text-error';
import vi from "date-fns/locale/vi";
registerLocale("vi", vi);
function DatePicker(props) {

	const { label, name, ...rest } = props;
	return (
		<div className="form-element">
			<label htmlFor={name}>{label}</label>
			<Field name={name}>
				{({ form, field }) => {

					const { setFieldValue } = form;
					const { value } = field;
					return (
						<DateView
							locale="vi"
							id={name}
							{...field}
							{...rest}
							selected={value}
							imputFormat="dd/MM/yyyy h:mm aa"
							dateFormat="dd/MM/yyyy"
							onChange={(val) => {
								setFieldValue(name, val);
							}}
						/>
					);
				}}
			</Field>
			<ErrorMessage name={name} component={TextError} />
		</div>
	);
}

export default DatePicker;
