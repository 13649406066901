import { Image, List } from 'antd-mobile';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import * as searchServices from '~/services/searchService';
import { useNavigate } from 'react-router-dom';
import { Grid, NavBar } from 'antd-mobile';
import { DemoBlock } from '~/components/until';
function Users_mobile () {
//   const [loading, setLoading] = useState(true);
   const navigate = useNavigate();
//   const [users, setUsers] = useState([]);
//   useEffect(() => {
//     setLoading(false);
//     const fetchApi = async () => {
//       const result = await searchServices.users();
//       setUsers(result);
//       setLoading(false);
//     };
//     fetchApi();
//   }, []);
  const backtop = () => {
    return navigate(-1);
  };
  return (
    <div>
      <NavBar onBack={backtop} className="headermobile">
        List users
      </NavBar>
      {/* <DemoBlock>
        <Grid columns={1} gap={8}>
          <Spin spinning={loading}>
            <List>
              {users.map((user) => (
                <List.Item
                  key={user.id}
                  prefix={<Image src={user.avatar} style={{ borderRadius: 20 }} fit="cover" width={40} height={40} />}
                  description={user.email}
                  onClick={() => {
                    navigate(`/users/${user.email}`);
                  }
                  }
                >
                  {user.first_name} {user.last_name}
                </List.Item>
              ))}
            </List>
          </Spin>
        </Grid>
      </DemoBlock> */}

    </div>
  );
}
export default Users_mobile;

