import { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { publicRoutes, privateRoutes, adminRoutes, publicRoutesMobile, privateRoutesMobile } from '~/routes';
import DefaultLayout from '~/layouts';
import { AuthProvider } from './utils/Auth';
import { RequireAuth } from './components/RequireAuth';
import { RequireAdmin } from './components/RequireAdmin';
import { isMobile } from 'react-device-detect';

function App() {
    if (isMobile) {
        return (
            <AuthProvider>
                <Router>
                    <div className="App">
                        <Routes>
                            {publicRoutesMobile.map((route, index) => {
                                const Page = route.component;
                                let Layout = DefaultLayout;

                                if (route.layout) {
                                    Layout = route.layout;
                                } else if (route.layout === null) {
                                    Layout = Fragment;
                                }

                                return (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={
                                            <Layout>
                                                <Page />
                                            </Layout>
                                        }
                                    />
                                );
                            })}
                            {privateRoutesMobile.map((route, index) => {
                                const Page = route.component;
                                let Layout = DefaultLayout;

                                if (route.layout) {
                                    Layout = route.layout;
                                } else if (route.layout === null) {
                                    Layout = Fragment;
                                }

                                return (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={
                                            <RequireAuth>
                                                <Layout>
                                                    <Page />
                                                </Layout>
                                            </RequireAuth>
                                        }
                                    />
                                );
                            })}
                        </Routes>
                    </div>
                </Router>
            </AuthProvider>
        );
    }
    return (
        <AuthProvider>
            <Router>
                <div className="App">
                    <Routes>
                        {publicRoutes.map((route, index) => {
                            const Page = route.component;
                            let Layout = DefaultLayout;

                            if (route.layout) {
                                Layout = route.layout;
                            } else if (route.layout === null) {
                                Layout = Fragment;
                            }
                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={
                                        <Layout>
                                            <Page />
                                        </Layout>
                                    }
                                />
                            );
                        })}
                        {privateRoutes.map((route, index) => {
                            const Page = route.component;
                            let Layout = DefaultLayout;

                            if (route.layout) {
                                Layout = route.layout;
                            } else if (route.layout === null) {
                                Layout = Fragment;
                            }

                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={
                                        <RequireAuth>
                                            <Layout>
                                                <Page />
                                            </Layout>
                                        </RequireAuth>
                                    }
                                />
                            );
                        })}
                        {/* {adminRoutes.map((route, index) => {
                            const Page = route.component;
                            let Layout = DefaultLayout;

                            if (route.layout) {
                                Layout = route.layout;
                            } else if (route.layout === null) {
                                Layout = Fragment;
                            }

                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={
                                        <RequireAdmin>
                                            <Layout>
                                                <Page />
                                            </Layout>
                                        </RequireAdmin>
                                    }
                                />
                            );
                        })} */}
                    </Routes>
                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;
