import React from 'react';
import { Typography, Empty } from 'antd';
import images from '~/assets/images';
const { Text } = Typography;

const EmptyWithLink = ({ text1, text2, textbtn, onclick }) => {
    return (
        <Empty
            image={images.empty.default}
            imageStyle={{
                height: 60,
            }}
            description={
                <Text type="secondary">
                    {text1}
                    <a href="#1" onClick={onclick}>
					{' '} {textbtn} {' '}
                    </a>
                    {text2}
                </Text>
            }
        ></Empty>
    );
};

export default EmptyWithLink;
