import * as httpRequest from '~/utils/httpRequest';


export const getlogs = async (id) => {
    try {
        const res = await httpRequest.get(`/getlogs`, {
            params: {},
        });
        return res.items; 
    } catch (error) {
        console.log(error);
    }
};

