import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as searchServices from '~/services/searchService';
function Detail() {
    const [users, setUsers] = useState([]);
    const { userId } = useParams();
    useEffect(() => {
        const fetchApi = async () => {
            const result = await searchServices.usersbyemail(userId);
            setUsers(result);
        };
        fetchApi();
    }, [userId]);
    console.log(userId);
    return (
        <div>
            <h2>Profile page... {users.username}</h2>
        </div>
    );
}

export default Detail;
