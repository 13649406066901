import { Button, Drawer, Space, Form, Input, DatePicker, Popconfirm } from 'antd';
import {
    PlusOutlined,
    SaveOutlined,
    ArrowLeftOutlined,
    StopOutlined,
    ClockCircleOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
const cx = classNames.bind(styles);

function Note({ visible, showDrawer, onClose, savedata, notes, addNote, deleteNote }) { 
    const dateFormat = 'DD/MM/YYYY - hh:mm';
    const [form] = Form.useForm();
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const [dateOrigin, setDateOrigin] = useState();

    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false);
    };

    const onAddNote = (values) => {
        values.datetime = moment(dateOrigin).format('YYYY-MM-DD hh:mm');
        addNote(values);
        setChildrenDrawer(false);
    };
    const deletenote = (values) => {console.log(values)
        deleteNote(values);
        setChildrenDrawer(false);
    };
    const handleCancel = () => {
        setChildrenDrawer(false);
    };
    const onChange = (date, dateString) => {
        console.log(date);
        setDateOrigin(date);
    };

    const cancel = (e) => {
        // message.error('Click on No');
    };
    return (
        <>
            <Drawer
                title="My notes"
                width={520}
                closable={false}
                onClose={onClose}
                visible={visible}
                extra={
                    <Space>
                        <Button type="primary" shape="circle" onClick={showChildrenDrawer}>
                            <PlusOutlined />
                        </Button>
                    </Space>
                }
            >
                <div className={cx('list-notes')}>
                    {notes && notes.map((e) => (
                        <div key={e.id} className={cx('item-note')}>
                            <div className={cx('datetime')}>
                                <ClockCircleOutlined /> {e.thoigian}
                            </div>
                            <div className={cx('title')}>{e.title}</div>
                            <div className={cx('desc')}>{e.description}</div>
                            <div>
                                <Popconfirm
                                    title="Are you sure to delete this note?"
                                    onConfirm={() => deletenote(e.id)}
                                    onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
									className={cx('btn-delete')}
									placement="left"
                                >
                                    <DeleteOutlined />
                                </Popconfirm>
                            </div>
                        </div>
                    ))}
                </div>
                <Drawer
                    title="Two-level Drawer"
                    width={500}
                    closable={false}
                    onClose={onChildrenDrawerClose}
                    visible={childrenDrawer}
                    extra={
                        <Space>
                            <Button shape="circle" onClick={onChildrenDrawerClose}>
                                <ArrowLeftOutlined />
                            </Button>
                            <Button shape="circle" type="primary" onClick={savedata}>
                                <SaveOutlined />
                            </Button>
                        </Space>
                    }
                >
                    <Form onFinish={onAddNote} form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                        <Form.Item name="datetime" label="Date time">
                            <DatePicker onChange={onChange} format={dateFormat} showTime />
                        </Form.Item>
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input title!',
                                },
                                {
                                    type: 'string',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item name="description" label="Description" dependencies={['desc']}>
                            <Input.TextArea />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                            <div className="d-flex space-between">
                                <Button htmlType="button" icon={<StopOutlined />} onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Drawer>
            </Drawer>
        </>
    );
}
Note.propTypes = {
    onClose: PropTypes.func,
    savedata: PropTypes.func,
};
export default Note;
