import styles from './style.css';
function Home() {
    return (
        <>
            <div>
                <div className="background background0"></div>
                <div className="background background1"></div>
                <div className="background background2"></div>
                <div className="background background3"></div>
                <div className="background background4"></div>
                <div className="background background5"></div>
                <div className="background background6"></div>
                <div className="background background7"></div>
                <div className="background background8"></div>
                <div className="background background9"></div>
                <div className="background background10"></div>
                <div className="background background11"></div>
                <div className="background background12"></div>
                <div className="background background13"></div>
                <div className="background background14"></div>
                <div className="background background15"></div>
                <div className="background background16"></div>
                <div className="background background17"></div>
                <div className="background background18"></div>
                <div className="background background19"></div>
                <div className="background background20"></div>
                <div className="background background21"></div>
                <div className="background background22"></div>
                <div className="criterion">
                    <div className="text text0">W</div>
                    <div className="text text1">e</div>
                    <div className="text text2">l</div>
                    <div className="text text3">c</div>
                    <div className="text text4">o</div>
                    <div className="text text5">m</div>
                    <div className="text text6">e</div>
                    <div className="text text7"> </div>
                    <div className="text text8">t</div>
                    <div className="text text9">o</div>
                    <div className="text text10"> </div>
                    <div className="text text11">D</div>
                    <div className="text text12">E</div>
                    <div className="text text13">S</div>
                    <div className="text text14">I</div>
                    <div className="text text15">G</div>
                    <div className="text text16">N</div>
                    <div className="text text17"> </div>
                    <div className="text text18">D</div>
                    <div className="text text19">E</div>
                    <div className="text text20">P</div>
                    <div className="text text21">T</div>
                    <div className="text text22">: )</div>
                    <div className="frame frame0"></div>
                    <div className="frame frame1"></div>
                    <div className="frame frame2"></div>
                    <div className="frame frame3"></div>
                    <div className="frame frame4"></div>
                    <div className="frame frame5"></div>
                    <div className="frame frame6"></div>
                    <div className="frame frame7"></div>
                    <div className="frame frame8"></div>
                    <div className="frame frame9"></div>
                    <div className="frame frame10"></div>
                    <div className="frame frame11"></div>
                    <div className="frame frame12"></div>
                    <div className="frame frame13"></div>
                    <div className="frame frame14"></div>
                    <div className="frame frame15"></div>
                    <div className="frame frame16"></div>
                    <div className="frame frame17"></div>
                    <div className="frame frame18"></div>
                    <div className="frame frame19"></div>
                    <div className="frame frame20"></div>
                    <div className="frame frame21"></div>
                    <div className="frame frame22"></div>
                    <div className="particle particle00"></div>
                    <div className="particle particle01"></div>
                    <div className="particle particle02"></div>
                    <div className="particle particle03"></div>
                    <div className="particle particle04"></div>
                    <div className="particle particle05"></div>
                    <div className="particle particle10"></div>
                    <div className="particle particle11"></div>
                    <div className="particle particle12"></div>
                    <div className="particle particle13"></div>
                    <div className="particle particle14"></div>
                    <div className="particle particle15"></div>
                    <div className="particle particle20"></div>
                    <div className="particle particle21"></div>
                    <div className="particle particle22"></div>
                    <div className="particle particle23"></div>
                    <div className="particle particle24"></div>
                    <div className="particle particle25"></div>
                    <div className="particle particle30"></div>
                    <div className="particle particle31"></div>
                    <div className="particle particle32"></div>
                    <div className="particle particle33"></div>
                    <div className="particle particle34"></div>
                    <div className="particle particle35"></div>
                    <div className="particle particle40"></div>
                    <div className="particle particle41"></div>
                    <div className="particle particle42"></div>
                    <div className="particle particle43"></div>
                    <div className="particle particle44"></div>
                    <div className="particle particle45"></div>
                    <div className="particle particle50"></div>
                    <div className="particle particle51"></div>
                    <div className="particle particle52"></div>
                    <div className="particle particle53"></div>
                    <div className="particle particle54"></div>
                    <div className="particle particle55"></div>
                    <div className="particle particle60"></div>
                    <div className="particle particle61"></div>
                    <div className="particle particle62"></div>
                    <div className="particle particle63"></div>
                    <div className="particle particle64"></div>
                    <div className="particle particle65"></div>
                    <div className="particle particle70"></div>
                    <div className="particle particle71"></div>
                    <div className="particle particle72"></div>
                    <div className="particle particle73"></div>
                    <div className="particle particle74"></div>
                    <div className="particle particle75"></div>
                    <div className="particle particle80"></div>
                    <div className="particle particle81"></div>
                    <div className="particle particle82"></div>
                    <div className="particle particle83"></div>
                    <div className="particle particle84"></div>
                    <div className="particle particle85"></div>
                    <div className="particle particle90"></div>
                    <div className="particle particle91"></div>
                    <div className="particle particle92"></div>
                    <div className="particle particle93"></div>
                    <div className="particle particle94"></div>
                    <div className="particle particle95"></div>
                    <div className="particle particle100"></div>
                    <div className="particle particle101"></div>
                    <div className="particle particle102"></div>
                    <div className="particle particle103"></div>
                    <div className="particle particle104"></div>
                    <div className="particle particle105"></div>
                    <div className="particle particle110"></div>
                    <div className="particle particle111"></div>
                    <div className="particle particle112"></div>
                    <div className="particle particle113"></div>
                    <div className="particle particle114"></div>
                    <div className="particle particle115"></div>
                    <div className="particle particle120"></div>
                    <div className="particle particle121"></div>
                    <div className="particle particle122"></div>
                    <div className="particle particle123"></div>
                    <div className="particle particle124"></div>
                    <div className="particle particle125"></div>
                    <div className="particle particle130"></div>
                    <div className="particle particle131"></div>
                    <div className="particle particle132"></div>
                    <div className="particle particle133"></div>
                    <div className="particle particle134"></div>
                    <div className="particle particle135"></div>
                    <div className="particle particle140"></div>
                    <div className="particle particle141"></div>
                    <div className="particle particle142"></div>
                    <div className="particle particle143"></div>
                    <div className="particle particle144"></div>
                    <div className="particle particle145"></div>
                    <div className="particle particle150"></div>
                    <div className="particle particle151"></div>
                    <div className="particle particle152"></div>
                    <div className="particle particle153"></div>
                    <div className="particle particle154"></div>
                    <div className="particle particle155"></div>
                    <div className="particle particle160"></div>
                    <div className="particle particle161"></div>
                    <div className="particle particle162"></div>
                    <div className="particle particle163"></div>
                    <div className="particle particle164"></div>
                    <div className="particle particle165"></div>
                    <div className="particle particle170"></div>
                    <div className="particle particle171"></div>
                    <div className="particle particle172"></div>
                    <div className="particle particle173"></div>
                    <div className="particle particle174"></div>
                    <div className="particle particle175"></div>
                    <div className="particle particle180"></div>
                    <div className="particle particle181"></div>
                    <div className="particle particle182"></div>
                    <div className="particle particle183"></div>
                    <div className="particle particle184"></div>
                    <div className="particle particle185"></div>
                    <div className="particle particle190"></div>
                    <div className="particle particle191"></div>
                    <div className="particle particle192"></div>
                    <div className="particle particle193"></div>
                    <div className="particle particle194"></div>
                    <div className="particle particle195"></div>
                    <div className="particle particle200"></div>
                    <div className="particle particle201"></div>
                    <div className="particle particle202"></div>
                    <div className="particle particle203"></div>
                    <div className="particle particle204"></div>
                    <div className="particle particle205"></div>
                    <div className="particle particle210"></div>
                    <div className="particle particle211"></div>
                    <div className="particle particle212"></div>
                    <div className="particle particle213"></div>
                    <div className="particle particle214"></div>
                    <div className="particle particle215"></div>
                    <div className="particle particle220"></div>
                    <div className="particle particle221"></div>
                    <div className="particle particle222"></div>
                    <div className="particle particle223"></div>
                    <div className="particle particle224"></div>
                    <div className="particle particle225"></div>
                </div>
            </div>
        </>
    );
}

export default Home;
