import React from 'react';
import { Link } from 'react-router-dom';
import { FieldTimeOutlined } from '@ant-design/icons';
import classNames from 'classnames/bind';
import styles from './Syslog.module.scss';
const cx = classNames.bind(styles);

function Item({ data }) {
    return (
        <div className={cx('item')}>
            <div>
                <span className={cx('time')}>
                    <FieldTimeOutlined /> {data.thoigian}
                </span>{' '}
                <span className={cx('date')}>({data.ngaythang})</span> {data.author}{' '}
                <span className={cx('actions')}>{data.action}</span> {data.title}
            </div>
            {data.tid !== 0 && (
                <Link className={cx('button')} to={`/tasks/${data.tid}`}>
                    View task
                </Link>
            )}
        </div>
    );
}

export default Item;
