import create from 'zustand';

const sidebarStore = create((set, get) => ({
    sidebarCollapse: localStorage.getItem('sidebarCollapse') || false,
    setSidebarCollapse: (value) => {
        set({ sidebarCollapse: value });
        localStorage.setItem('sidebarCollapse', value);
    },
}));

export default sidebarStore;
