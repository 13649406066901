import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tag, Avatar } from 'antd';
import Highlighter from 'react-highlight-words';
import React, { useEffect, useState, useRef } from 'react';

import { Link, useParams } from 'react-router-dom';
import * as tasksServices from '~/services/tasksService';
import * as userServices from '~/services/searchService';

function Tasks() {
    const { taskId } = useParams();
    const [tasks, setTasks] = useState([]);
    const [avatarlist, setAvatarlist] = useState([]);
    useEffect(() => { 
        const fetchApi = async () => {
            const result = await tasksServices.searchbytag(taskId);
            const resultArray = result.map((elm) => ({
                key: elm.id,
                task_name: elm.task_name,
                task_tag: elm.task_tag.split(',').map(String),
                avatar: elm.avatar,
                thoigian: elm.thoigian,
                task_status: elm.task_status,
            }));
            setTasks(resultArray);
        };
        fetchApi();
        const fetchApi1 = async () => {
            const result = await userServices.users();
            const avatarArray = result.map((elm) => ({ text: elm.last_name, value: elm.avatar }));
            setAvatarlist(avatarArray);
        };
        fetchApi1();
    }, [taskId]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title: 'Assign',
            dataIndex: 'avatar',
            id: 'avatar',
            render: (avatar) => <Avatar src={avatar} />,
            sorter: (a, b) => a.avatar.length - b.avatar.length,
            sortDirections: ['descend', 'ascend'],
            filters: avatarlist,
            filterSearch: true,
            onFilter: (value, record) => record.avatar.startsWith(value),
        },
        {
            title: 'Task name',
            dataIndex: 'task_name',
            key: 'task_name',
            width: '30%',
            ...getColumnSearchProps('task_name'),
            render: (text, key) => <Link to={`/tasks/${key.key}`}>{text}</Link>,
        },
        {
            title: 'Tags',
            dataIndex: 'task_tag',
            key: 'task_tag',
            width: '20%',
            sorter: (a, b) => a.task_tag.length - b.task_tag.length,
            sortDirections: ['descend', 'ascend'],
            render: (tags) => (
				<>
				{tags.map((tag) => (
					<Link key={tag} to={`/tasks/tag/${tag}`}>
					<Tag color="blue">{tag}</Tag>
					</Link>
				))}
				</>
			),
        },
        {
            title: 'Duedate',
            dataIndex: 'thoigian',
            key: 'thoigian',
            width: '20%',
            sorter: (a, b) => a.thoigian - b.thoigian,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Status',
            dataIndex: 'task_status',
            id: 'task_status',
            render: (task_status) => <Tag className={`ant-tag-${task_status}`}>{task_status}</Tag>,
            sorter: (a, b) => a.task_status.length - b.task_status.length,
            sortDirections: ['descend', 'ascend'],
        },
    ];

    return (
        <div>
            <h2>List task by '{taskId}'</h2>
            <Table columns={columns} dataSource={tasks} />
        </div>
    );
}
export default Tasks;
