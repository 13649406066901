import { getUser, updateUserSession } from '~/utils/Common';
import ImgCrop from 'antd-img-crop';
import { Button, Form, Input, Breadcrumb, Upload, Divider } from 'antd';
import { StopOutlined, SaveOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import * as searchServices from '~/services/searchService';
import Resizer from "react-image-file-resizer";



function EditProfile() {
    const currentUser = getUser();
    const [form] = Form.useForm();
    const [ava64, setAva64] = useState(currentUser.avatar);
    const navigate = useNavigate();
    const onFinish = async (values) => {
        values = { ...values, id: currentUser.id, avatar: String(ava64), email: currentUser.email };
        values = JSON.parse(JSON.stringify(values));
        console.log(values);
        updateUserSession({ avatar: String(ava64) });
        const result = await searchServices.editUser(values);
        navigate(`/users/${currentUser.email}`, { state: result });
    };
    form.setFieldsValue({
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        username: currentUser.username,
    });

    const [fileList, setFileList] = useState(() => {
        if (currentUser.avatar) {
            return [
                {
                    uid: '-1',
                    name: 'avatar.png',
                    status: 'done',
                    url: currentUser.avatar,
                },
            ];
        } else {
            return [];
        }
    });
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        setAva64(newFileList);
    };
    const onPreview = async (file) => {
        let src = file.url;

        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);

                reader.onload = () => resolve(reader.result);
            });
        }

        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
		Resizer.imageFileResizer(
			file,
			100,
			100,
			"JPEG",
			100,
			0,
			(uri) => {
			  console.log(uri);
			  setAva64(uri);
			},
			"base64",
			100,
			100
		  );
    };
    const handleCancel = () => {
        navigate(`/users/${currentUser.email}`);
    };
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/users">Users</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>My profiles</Breadcrumb.Item>
            </Breadcrumb>
			<h3>Edit profiles</h3>
			<Divider />
            <div className="card mw600 m-auto">
                <div className="form-group">
                    <Form
                        name="nest-messages"
                        onFinish={onFinish}
                        form={form}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                    >
                        <Form.Item name="Avatar" label="Avatar">
                            <ImgCrop rotate>
                                <Upload
                                    customRequest={dummyRequest}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={onChange}
                                    onPreview={onPreview}
                                >
                                    {fileList.length < 1 && '+ Upload'}
                                </Upload>
                            </ImgCrop>
                        </Form.Item>

                        <Divider dashed />
                        <Form.Item
                            name="first_name"
                            label="First Name"
                            rules={[{ required: true, message: 'insert first name' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            label="Last Name"
                            rules={[{ required: true, message: 'insert last name' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="username"
                            label="User Name"
                            rules={[{ required: true, message: 'insert user name' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <div className="d-flex space-between">
                                <Button htmlType="button" icon={<StopOutlined />} onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
}
export default EditProfile;
