import create from 'zustand';
const currentTime = new Date();
const month = currentTime.getMonth() + 1;
const year = currentTime.getFullYear();

const tasksFilterStore = create((set, get) => ({
    tasksFilter: localStorage.getItem('tasksFilter') || (year + '/' + month),
    setTasksFilter: (value) => {
        set({ tasksFilter: value });
        localStorage.setItem('tasksFilter', value);
    },
}));

export default tasksFilterStore;
