import { Card, Row, Col, Image, Spin } from 'antd';
import { Grid, NavBar } from 'antd-mobile';
import { DemoBlock } from '~/components/until';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as partnersService from '~/services/partnersService';
import styles from './Home.module.scss';
import classNames from 'classnames/bind';
import images from '~/assets/images';
const cx = classNames.bind(styles);
function Home() {
    const [loading, setLoading] = useState(true);
    const [partners, setPartners] = useState([]);
    const { Meta } = Card;
    useEffect(() => {
        setLoading(true);
        const fetchApi1 = async () => {
            const result = await partnersService.partners();
            setPartners(result);
            setLoading(false);
        };
        fetchApi1();
    }, []);
    return (
        <>
            <NavBar back={null} className="headermobile">
                Dashbroad
            </NavBar>
            <DemoBlock>
                <Grid columns={1} gap={8}>
                    <Spin spinning={loading}>
                        <Row gutter={[16, 16]}>
                            {partners.map((e) => (
                                <Col span={24} key={e.id}>
                                    <Link to={`/projectlist/${e.id}`}>
                                        <Card
                                            hoverable
                                            cover={
                                                <Image
                                                    alt="example"
                                                    src={e.image}
                                                    className={cx('coverimg')}
                                                    fallback={images.noImage}
                                                />
                                            }
                                        >
                                            <Meta title={e.name} description={e.mota} />
                                        </Card>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    </Spin>
                </Grid>
            </DemoBlock>
        </>
    );
}

export default Home;
