import * as React from 'react';

const ArrowDownSVG = () => (
   <svg id="logo-vnpay" xmlns="http://www.w3.org/2000/svg" width="105.388" height="32" viewBox="0 0 105.388 32"> 
  <g id="Group_2" data-name="Group 2" transform="translate(0 0)">
    <path id="Path_1" data-name="Path 1" d="M62.323,36.288,59.281,39.33l-.151.15-.967.968-.72.721-.967.967-.15.15-.421.421-.15.152h0l-1.426,1.424v0l-.15.15a6.259,6.259,0,0,1-1.4,1.055,6.337,6.337,0,0,1-.78.362,6.213,6.213,0,0,1-1.841.4A6.136,6.136,0,0,1,49,46.221a6.2,6.2,0,0,1-2.378-.791,4.327,4.327,0,0,1-.912-.642s0,0,0,0q-.089-.086-.177-.177L39.991,39.07l-.118-.118a2.126,2.126,0,0,1-.256-.346,2.084,2.084,0,0,1-.291-1.065,1.769,1.769,0,0,1,.008-.181,2.2,2.2,0,0,1,.039-.262L39.4,37l.012-.041a2.151,2.151,0,0,1,.384-.727c.047-.057.1-.112.15-.167l.1-.1,5.9-5.9h0l3.575-3.575a2.128,2.128,0,0,1,2.9-.1Z" transform="translate(-39.325 -25.864)" fill="#004a9c"/>
    <path id="Path_2" data-name="Path 2" d="M70.276,71.622" transform="translate(-59.753 -56.064)" fill="none" stroke="#006d9f" stroke-miterlimit="10" stroke-width="1"/>
    <path id="Path_3" data-name="Path 3" d="M91.557,93.18" transform="translate(-73.798 -70.293)" fill="none" stroke="#006d9f" stroke-miterlimit="10" stroke-width="1"/>
    <path id="Path_4" data-name="Path 4" d="M63.594,57.088a3.342,3.342,0,0,1-1.394-.311l-1.134-1.132-.278-.276-.047-.049-.528-.528-2.642-2.672q.089.091.177.177a4.3,4.3,0,0,0,.915.644,6.2,6.2,0,0,0,2.378.791,6.135,6.135,0,0,0,1.156.032,6.213,6.213,0,0,0,1.841-.4,6.336,6.336,0,0,0,.78-.362,6.26,6.26,0,0,0,1.4-1.055l.15-.15v0L67.8,50.37h0l.15-.152.421-.421.15-.15.967-.967.72-.721.967-.968.151-.15,6.129-6.129,2.528-2.738L80,37.959l.136-.134a1.977,1.977,0,0,1,2.8,0l1.384,1.384h0l.046.045a2.264,2.264,0,0,0-1.693.644l-4.684,4.607-.047.057-8.151,8.021-.012-.01-3,2.949-.02.023c-.008.01-.073.083-.181.189a5.313,5.313,0,0,1-1.392.979,4.074,4.074,0,0,1-.905.307,3.637,3.637,0,0,1-.542.067Z" transform="translate(-51.367 -33.377)" fill="#e50019"/>
    <path id="Path_5" data-name="Path 5" d="M102.418,54.5l-.15.152-6.639,6.739-6.971,7.078a6.094,6.094,0,0,1-.7.6.329.329,0,0,1-.028.023c-.033.024-.067.049-.1.073l-.012.01a6.429,6.429,0,0,1-8.161-.577l0,0c-.053-.045-.1-.09-.155-.138l-1.2-1.2-3.368-3.371L73.8,62.765l-.157-.157a3.511,3.511,0,0,0,.553.043H74.2l.15,0a3.864,3.864,0,0,0,.64-.077,4.627,4.627,0,0,0,1.035-.35,5.9,5.9,0,0,0,1.548-1.087c.1-.1.179-.185.21-.222l2.591-2.546.01.012L89.3,49.614l.047-.057L93.672,45.3a1.711,1.711,0,0,1,2.154-.189l1.026,1.026a0,0,0,0,1,0,0l5.564,5.564a1.968,1.968,0,0,1,0,2.8Z" transform="translate(-61.977 -38.371)" fill="#e50019"/>
    <g id="Group_1" data-name="Group 1" transform="translate(6.003 5.942)">
      <path id="Path_6" data-name="Path 6" d="M69.916,46.15a.408.408,0,0,1-.573-.006,6.76,6.76,0,1,0-9.6,9.523L61.8,57.74a.2.2,0,0,1,.035.045l-.03,0a6.146,6.146,0,0,1-1.124-.028l-1.51-1.524A7.567,7.567,0,1,1,69.916,45.575l.006.006A.4.4,0,0,1,69.916,46.15Z" transform="translate(-56.981 -43.341)" fill="#008ed4"/>
      <path id="Path_7" data-name="Path 7" d="M100.985,57.036l-.571.571-1-1.008a.4.4,0,0,1,.573-.569Z" transform="translate(-84.908 -51.636)" fill="#008ed4"/>
      <path id="Path_8" data-name="Path 8" d="M69.076,65.668l-.018.01a6.3,6.3,0,0,1-.76.355l-2.8-2.823a5.183,5.183,0,0,1-.6-6.58.4.4,0,1,1,.665.459,4.372,4.372,0,0,0,.5,5.552Z" transform="translate(-61.61 -51.996)" fill="#008ed4"/>
      <path id="Path_9" data-name="Path 9" d="M82.4,55.052l-.014.014-.422.421-.136.134-3.15-3.174a4.358,4.358,0,0,0-4.457-1.079.4.4,0,1,1-.248-.768,5.162,5.162,0,0,1,5.278,1.278Z" transform="translate(-68.01 -47.964)" fill="#008ed4"/>
      <path id="Path_10" data-name="Path 10" d="M78.946,61.385l-.571.571-3.156-3.181A1.983,1.983,0,1,0,72.4,61.569l2.034,2.051a.4.4,0,1,1-.573.569l-2.036-2.052a2.791,2.791,0,0,1,3.963-3.932Z" transform="translate(-66.247 -52.608)" fill="#008ed4"/>
      <path id="Path_11" data-name="Path 11" d="M81.89,67.706l-.014.014-.421.421-.136.136-3.164-3.188a.4.4,0,0,1,.573-.569Z" transform="translate(-70.879 -57.241)" fill="#008ed4"/>
    </g>
  </g>
  <path id="Path_12" data-name="Path 12" d="M274.9,59.64,270.2,52.894l-.479-.439h4.638l3.143,5.1.253-.206,1.9-2.824a1.487,1.487,0,0,0-.261-2.07h4.382l-5.065,7.183,0,5.212.893,1.062h-5.444l.768-1.046c0-1.737-.063-3.494-.018-5.226Zm-12.775-7.185a1.954,1.954,0,0,1,.529,1.156,1.642,1.642,0,0,1-.1.8l-3.5,10.147a3.738,3.738,0,0,1-.995,1.325h3.819a1.574,1.574,0,0,1-.53-.907,1.5,1.5,0,0,1,.013-.761l.776-2.41h4.444l.693,1.857a2.138,2.138,0,0,1,.132.584,1.747,1.747,0,0,1-.712,1.668h5.987a5.513,5.513,0,0,1-1.431-1.735l-4.371-11.724Zm3.81,7.6h-3.179l1.542-4.392,1.637,4.392Zm-15.916-7.591,3.008-.012h3.653a3.664,3.664,0,0,1,3.653,3.653v.45a3.661,3.661,0,0,1-3.653,3.6l-1.666.051a3.414,3.414,0,0,0,1.733-3.574c-.134-1.125-.714-2.207-1.679-2.227l-.609-.013V64.411a.713.713,0,0,0,.136.447,6.975,6.975,0,0,0,.606.817l.176.212H250.2c.078-.1.154-.192.227-.291a3.33,3.33,0,0,0,.4-.694,1.083,1.083,0,0,0,.1-.456V53.689C250.905,53.128,250.348,52.81,250.021,52.467Z" transform="translate(-178.384 -43.414)" fill="#004a9c" fill-rule="evenodd"/>
  <path id="Path_13" data-name="Path 13" d="M167.4,52.279h4.183l3.51,10.066,2.977-8.338a.969.969,0,0,0-.116-.708c-.165-.338-.341-.667-.506-1.005h3.124c-.185.68-.458,1.427-.694,2.113L175.84,66.131a6.584,6.584,0,0,1-3.278-1.555,5.727,5.727,0,0,1-1.321-2.365l-3.373-8.933a8.689,8.689,0,0,0-.472-1ZM184.1,57v7.193c0,.292.067.344.247.578l.824,1.074h-3.937l.665-1.015a1.067,1.067,0,0,0,.245-.7V54.079l-1.107-1.8h4.08l6.606,8.508V54.007a1.084,1.084,0,0,0-.235-.708c-.222-.338-.453-.667-.675-1.005h3.933l-.82,1.074c-.18.234-.247.286-.247.578V66.185a5.56,5.56,0,0,1-4.111-2.137Z" transform="translate(-123.852 -43.298)" fill="#e50019" fill-rule="evenodd"/>
  <g id="Group_24" data-name="Group 24" transform="translate(47.353 24.457)">
    <g id="Group_3" data-name="Group 3" transform="translate(0 0.681)">
      <path id="Path_14" data-name="Path 14" d="M178.98,101.762c0,.539.225.817.814.817a1.828,1.828,0,0,0,.669-.121c.107-.041.138-.013.138.1v.056c0,.1-.026.134-.138.185a1.59,1.59,0,0,1-.66.116c-.81,0-1.192-.362-1.192-1.155v-.742a1.051,1.051,0,0,1,1.182-1.2,1.74,1.74,0,0,1,.646.12c.112.041.135.084.135.177v.061c0,.115-.047.148-.145.106a1.64,1.64,0,0,0-.648-.125c-.563,0-.8.273-.8.867Z" transform="translate(-178.607 -99.81)" fill="#008ed4"/>
      <path id="Path_15" data-name="Path 15" d="M179.794,102.912c-.816,0-1.2-.369-1.2-1.16v-.742a1.053,1.053,0,0,1,1.187-1.209,1.746,1.746,0,0,1,.648.12c.118.044.138.092.138.182v.061c0,.062-.013.1-.04.118a.118.118,0,0,1-.111-.008,1.64,1.64,0,0,0-.646-.124c-.551,0-.8.266-.8.861v.742c0,.561.25.812.81.812a1.827,1.827,0,0,0,.668-.121c.055-.021.089-.023.111-.008s.033.05.033.115v.056c0,.1-.028.138-.141.189A1.576,1.576,0,0,1,179.794,102.912Zm-.01-3.1a1.045,1.045,0,0,0-1.177,1.2v.742c0,.785.377,1.15,1.187,1.15a1.573,1.573,0,0,0,.658-.116c.11-.05.135-.084.135-.181v-.056c0-.061-.009-.094-.029-.107s-.051-.01-.1.01a1.85,1.85,0,0,1-.671.121c-.566,0-.819-.254-.819-.822v-.742c0-.6.249-.871.807-.871a1.647,1.647,0,0,1,.65.125.11.11,0,0,0,.1.008c.023-.015.036-.052.036-.109V100.1c0-.087-.018-.13-.132-.172A1.719,1.719,0,0,0,179.785,99.811Z" transform="translate(-178.598 -99.801)" fill="#008ed4"/>
    </g>
    <g id="Group_4" data-name="Group 4" transform="translate(2.483 0.491)">
      <path id="Path_16" data-name="Path 16" d="M185.916,99.405c0-.126.031-.148.147-.148h.052c.123,0,.147.022.147.148v1.08a.81.81,0,0,1,.729-.316c.57,0,.857.27.857.812v1.372c0,.125-.024.149-.147.149h-.057c-.123,0-.147-.024-.147-.149v-1.33c0-.367-.164-.525-.594-.525-.446,0-.641.177-.641.6v1.252c0,.125-.024.149-.147.149h-.052c-.117,0-.147-.024-.147-.149Z" transform="translate(-185.911 -99.252)" fill="#008ed4"/>
      <path id="Path_17" data-name="Path 17" d="M187.691,102.5h-.057c-.126,0-.152-.026-.152-.153v-1.331c0-.369-.171-.52-.589-.52-.446,0-.636.179-.636.6v1.252c0,.127-.026.153-.152.153h-.052c-.122,0-.152-.03-.152-.153V99.4c0-.132.036-.153.152-.153h.052c.126,0,.152.026.152.153v1.065a.829.829,0,0,1,.724-.3c.572,0,.862.275.862.817v1.372C187.843,102.471,187.818,102.5,187.691,102.5Zm-.8-2.013c.425,0,.6.153.6.529v1.331c0,.122.021.143.142.143h.057c.121,0,.142-.021.142-.143v-1.372c0-.535-.287-.807-.852-.807a.8.8,0,0,0-.725.313l-.009.013V99.4c0-.122-.021-.143-.142-.143h-.052c-.112,0-.142.017-.142.143v2.948c0,.125.032.143.142.143h.052c.121,0,.142-.021.142-.143v-1.252C186.248,100.666,186.441,100.484,186.893,100.484Z" transform="translate(-185.901 -99.242)" fill="#008ed4"/>
    </g>
    <g id="Group_5" data-name="Group 5" transform="translate(4.912 1.404)">
      <path id="Path_18" data-name="Path 18" d="M193.059,103.347v-.432c0-.648.337-.973,1.018-.973.719,0,1.011.338,1.011.973v.432q0,.974-1.011.973C193.356,104.32,193.059,103.982,193.059,103.347Zm1.683-.432c0-.445-.159-.645-.665-.645s-.672.2-.672.645v.432c0,.445.166.645.672.645s.665-.2.665-.645Z" transform="translate(-193.054 -101.936)" fill="#008ed4"/>
      <path id="Path_19" data-name="Path 19" d="M194.068,104.316c-.7,0-1.023-.311-1.023-.978v-.432a.976.976,0,0,1,.255-.734,1.079,1.079,0,0,1,.768-.245c.693,0,1.016.311,1.016.979v.432A.884.884,0,0,1,194.068,104.316Zm0-2.379a1.068,1.068,0,0,0-.762.242.966.966,0,0,0-.252.727v.432c0,.661.322.968,1.014.968a1.056,1.056,0,0,0,.755-.242.967.967,0,0,0,.251-.727v-.432C195.075,102.245,194.755,101.937,194.068,101.937Zm0,2.05c-.487,0-.677-.182-.677-.649v-.432c0-.474.184-.65.677-.65s.669.176.669.65v.432C194.738,103.811,194.556,103.987,194.068,103.987Zm0-1.721c-.486,0-.667.173-.667.64v.432c0,.46.187.64.667.64s.66-.173.66-.64v-.432C194.728,102.439,194.549,102.266,194.068,102.266Z" transform="translate(-193.045 -101.927)" fill="#008ed4"/>
    </g>
    <g id="Group_6" data-name="Group 6" transform="translate(8.627 1.404)">
      <path id="Path_20" data-name="Path 20" d="M203.987,102.9c0-.608.327-.96,1.033-.96a1.243,1.243,0,0,1,.408.061c.112.037.14.07.14.176v.046c0,.116-.029.149-.14.112a1.181,1.181,0,0,0-.4-.065c-.52,0-.7.2-.7.636v.45c0,.468.209.635.724.635a1.293,1.293,0,0,0,.418-.061c.107-.037.138,0,.138.116v.046c0,.1-.031.134-.147.166a1.415,1.415,0,0,1-.432.061c-.71,0-1.047-.306-1.047-.96Z" transform="translate(-203.982 -101.936)" fill="#008ed4"/>
      <path id="Path_21" data-name="Path 21" d="M205.025,104.316a1.133,1.133,0,0,1-.793-.236.934.934,0,0,1-.259-.729v-.458a.949.949,0,0,1,.253-.712,1.093,1.093,0,0,1,.784-.254,1.251,1.251,0,0,1,.41.061c.115.038.143.073.143.181v.046c0,.067-.01.1-.033.12s-.057.015-.113,0a1.183,1.183,0,0,0-.4-.065c-.491,0-.691.183-.691.631v.45c0,.459.195.63.719.63a1.286,1.286,0,0,0,.416-.06.122.122,0,0,1,.109,0c.025.018.035.055.035.123v.047c0,.1-.034.139-.151.17A1.4,1.4,0,0,1,205.025,104.316Zm-.014-2.379c-.673,0-1.028.33-1.028.955v.458c0,.652.331.955,1.042.955a1.411,1.411,0,0,0,.431-.06c.113-.031.144-.066.144-.161v-.047c0-.065-.01-.1-.032-.115s-.051-.013-.1,0a1.3,1.3,0,0,1-.419.061c-.525,0-.729-.18-.729-.64v-.45c0-.455.2-.641.7-.641a1.189,1.189,0,0,1,.4.066c.052.017.084.019.1,0s.029-.048.029-.112v-.046c0-.1-.026-.135-.137-.171A1.245,1.245,0,0,0,205.01,101.937Z" transform="translate(-203.973 -101.927)" fill="#008ed4"/>
    </g>
    <g id="Group_7" data-name="Group 7" transform="translate(10.579 1.45)">
      <path id="Path_22" data-name="Path 22" d="M210.073,103.544c0,.366.152.538.622.538s.615-.167.615-.538v-1.318c0-.115.033-.148.15-.148h.05c.126,0,.15.033.15.148v1.318c0,.56-.283.867-.964.867-.646,0-.969-.287-.969-.867v-1.318c0-.115.031-.148.147-.148h.05c.126,0,.15.033.15.148Z" transform="translate(-209.721 -102.073)" fill="#008ed4"/>
      <path id="Path_23" data-name="Path 23" d="M210.685,104.405c-.646,0-.973-.293-.973-.871v-1.318c0-.119.034-.153.152-.153h.05c.132,0,.154.038.154.153v1.318c0,.384.173.532.617.532s.61-.144.61-.532v-1.318c0-.118.036-.153.154-.153h.05c.132,0,.154.038.154.153v1.318a.841.841,0,0,1-.228.647A1.044,1.044,0,0,1,210.685,104.405Zm-.821-2.332c-.113,0-.142.029-.142.143v1.318a.828.828,0,0,0,.24.647,1.062,1.062,0,0,0,.723.215,1.033,1.033,0,0,0,.734-.221.831.831,0,0,0,.225-.64v-1.318c0-.11-.02-.143-.145-.143h-.05c-.114,0-.145.031-.145.143v1.318c0,.39-.174.542-.62.542s-.627-.152-.627-.542v-1.318c0-.11-.02-.143-.145-.143Z" transform="translate(-209.712 -102.063)" fill="#008ed4"/>
    </g>
    <g id="Group_8" data-name="Group 8" transform="translate(12.977 0.57)">
      <path id="Path_24" data-name="Path 24" d="M216.779,101.727v-.432c0-.648.337-.973,1.019-.973.719,0,1.011.338,1.011.973v.432q0,.974-1.011.973C217.076,102.7,216.779,102.362,216.779,101.727Zm1.683-.432c0-.445-.159-.645-.665-.645s-.672.2-.672.645v.432c0,.445.169.645.672.645s.665-.2.665-.645Zm-.992-1.581c.112-.171.176-.227.328-.227s.218.046.33.227l.176.274c.057.083.038.107-.06.107h-.038a.179.179,0,0,1-.19-.107l-.107-.18q-.111-.2-.221,0l-.1.18a.182.182,0,0,1-.192.107h-.04c-.1,0-.111-.029-.059-.107Zm.171,3.4a.229.229,0,1,1,.325.324.229.229,0,0,1-.325-.324Z" transform="translate(-216.774 -99.482)" fill="#008ed4"/>
      <path id="Path_25" data-name="Path 25" d="M217.794,103.5a.231.231,0,0,1-.234-.233.231.231,0,0,1,.067-.165h0a.234.234,0,0,1,.333.33A.235.235,0,0,1,217.794,103.5Zm0-.457a.221.221,0,0,0-.159.065h0a.227.227,0,0,0,0,.318.23.23,0,0,0,.319,0,.228.228,0,0,0,0-.318A.221.221,0,0,0,217.794,103.042Zm-.006-.346c-.7,0-1.023-.311-1.023-.978v-.432a.976.976,0,0,1,.255-.734,1.078,1.078,0,0,1,.768-.245c.693,0,1.016.311,1.016.979v.432A.884.884,0,0,1,217.788,102.7Zm0-2.379a1.067,1.067,0,0,0-.761.242.965.965,0,0,0-.252.727v.432c0,.661.322.968,1.014.968a1.056,1.056,0,0,0,.755-.242.969.969,0,0,0,.251-.727v-.432C218.795,100.625,218.475,100.317,217.788,100.317Zm0,2.05c-.487,0-.677-.182-.677-.649v-.432c0-.474.184-.65.677-.65s.67.176.67.65v.432C218.458,102.191,218.276,102.367,217.788,102.367Zm0-1.721c-.486,0-.667.173-.667.64v.432c0,.46.187.64.667.64s.66-.173.66-.64v-.432C218.448,100.819,218.269,100.646,217.788,100.646Zm.446-.556H218.2a.183.183,0,0,1-.194-.109l-.107-.18c-.036-.064-.071-.1-.106-.1h0c-.035,0-.07.032-.106.1l-.1.18a.186.186,0,0,1-.2.109h-.04c-.053,0-.08-.008-.09-.027s0-.045.027-.088l.175-.274c.111-.169.177-.229.332-.229s.22.045.334.229l.175.274c.03.043.039.07.029.088S218.29,100.09,218.234,100.09Zm-.445-.395h0c.038,0,.077.034.115.1l.107.18a.174.174,0,0,0,.186.1h.038c.051,0,.077-.006.084-.021s0-.039-.029-.079l-.176-.274c-.112-.18-.175-.224-.326-.224s-.214.056-.323.224l-.176.274c-.026.038-.034.063-.026.077s.034.022.082.022h.04a.177.177,0,0,0,.188-.1l.1-.18C217.712,99.729,217.751,99.7,217.789,99.7Z" transform="translate(-216.765 -99.473)" fill="#008ed4"/>
    </g>
    <g id="Group_9" data-name="Group 9" transform="translate(15.455 1.404)">
      <path id="Path_26" data-name="Path 26" d="M224.069,102.9c0-.608.327-.96,1.033-.96a1.243,1.243,0,0,1,.408.061c.112.037.14.07.14.176v.046c0,.116-.028.149-.14.112a1.182,1.182,0,0,0-.4-.065c-.52,0-.7.2-.7.636v.45c0,.468.209.635.724.635a1.293,1.293,0,0,0,.418-.061c.107-.037.138,0,.138.116v.046c0,.1-.031.134-.147.166a1.415,1.415,0,0,1-.432.061c-.71,0-1.047-.306-1.047-.96Z" transform="translate(-224.064 -101.936)" fill="#008ed4"/>
      <path id="Path_27" data-name="Path 27" d="M225.107,104.316a1.133,1.133,0,0,1-.793-.236.934.934,0,0,1-.259-.729v-.458a.949.949,0,0,1,.253-.712,1.093,1.093,0,0,1,.784-.254,1.254,1.254,0,0,1,.41.061c.115.038.143.073.143.181v.046c0,.067-.01.1-.033.12s-.057.015-.113,0a1.183,1.183,0,0,0-.4-.065c-.491,0-.691.183-.691.631v.45c0,.459.195.63.719.63a1.286,1.286,0,0,0,.416-.06.121.121,0,0,1,.109,0c.025.018.035.055.035.123v.047c0,.1-.034.139-.151.17A1.4,1.4,0,0,1,225.107,104.316Zm-.014-2.379c-.673,0-1.028.33-1.028.955v.458c0,.652.331.955,1.042.955a1.409,1.409,0,0,0,.431-.06c.113-.031.144-.066.144-.161v-.047c0-.065-.01-.1-.032-.115s-.051-.013-.1,0a1.305,1.305,0,0,1-.419.061c-.525,0-.729-.18-.729-.64v-.45c0-.455.2-.641.7-.641a1.189,1.189,0,0,1,.4.066c.053.018.085.02.1,0s.029-.048.029-.112v-.046c0-.1-.025-.135-.137-.171A1.244,1.244,0,0,0,225.092,101.937Z" transform="translate(-224.055 -101.927)" fill="#008ed4"/>
    </g>
    <g id="Group_10" data-name="Group 10" transform="translate(18.617 1.404)">
      <path id="Path_28" data-name="Path 28" d="M233.37,104.023c0-.116.035-.143.147-.12a2.6,2.6,0,0,0,.681.092c.358,0,.506-.065.506-.265,0-.148-.078-.227-.3-.334l-.565-.269c-.3-.144-.432-.283-.432-.6,0-.4.261-.584.821-.584a1.744,1.744,0,0,1,.579.088c.112.038.14.065.14.172v.041c0,.116-.042.144-.15.112a1.814,1.814,0,0,0-.579-.088c-.337,0-.468.073-.468.259,0,.131.083.233.244.306l.591.269c.313.149.463.292.463.621,0,.413-.277.6-.876.6a2.438,2.438,0,0,1-.655-.088c-.116-.032-.147-.075-.147-.172Z" transform="translate(-233.365 -101.936)" fill="#008ed4"/>
      <path id="Path_29" data-name="Path 29" d="M234.162,104.316a2.453,2.453,0,0,1-.657-.088c-.116-.032-.151-.073-.151-.176v-.037c0-.062.011-.1.034-.116s.059-.021.12-.009a2.605,2.605,0,0,0,.68.092c.365,0,.5-.07.5-.26,0-.143-.07-.22-.3-.329l-.565-.27a.605.605,0,0,1-.435-.607c0-.4.262-.589.827-.589a1.757,1.757,0,0,1,.581.088c.111.038.143.066.143.177v.041c0,.06-.012.1-.036.115a.136.136,0,0,1-.12,0,1.814,1.814,0,0,0-.578-.088c-.333,0-.463.071-.463.254,0,.127.081.229.242.3l.591.27c.3.143.466.285.466.625C235.043,104.124,234.764,104.316,234.162,104.316Zm-.717-.424a.079.079,0,0,0-.05.014c-.021.017-.03.051-.03.109v.037c0,.1.033.137.144.167a2.447,2.447,0,0,0,.654.087c.6,0,.871-.183.871-.593,0-.335-.162-.475-.46-.617l-.591-.27a.348.348,0,0,1-.247-.31c0-.19.132-.264.473-.264a1.83,1.83,0,0,1,.581.088c.054.016.089.016.111,0s.032-.051.032-.107v-.041c0-.106-.029-.131-.137-.168a1.748,1.748,0,0,0-.578-.087c-.557,0-.817.184-.817.579,0,.33.145.461.429.6l.565.27c.23.11.3.192.3.338,0,.2-.138.27-.51.27a2.625,2.625,0,0,1-.683-.092A.278.278,0,0,0,233.445,103.891Z" transform="translate(-233.355 -101.927)" fill="#008ed4"/>
    </g>
    <g id="Group_11" data-name="Group 11" transform="translate(20.745)">
      <path id="Path_30" data-name="Path 30" d="M239.626,100.621v-.432c0-.648.337-.973,1.019-.973.719,0,1.011.338,1.011.973v.432q0,.974-1.011.973C239.923,101.594,239.626,101.256,239.626,100.621Zm1.683-.432c0-.445-.159-.645-.665-.645s-.672.2-.672.645v.432c0,.445.168.645.672.645s.665-.2.665-.645Zm-.992-1.581c.112-.171.176-.227.328-.227s.218.046.33.227l.176.274c.057.083.038.107-.059.107h-.038a.179.179,0,0,1-.19-.107l-.107-.18q-.111-.2-.221,0l-.1.18a.182.182,0,0,1-.192.107h-.04c-.1,0-.112-.029-.06-.107Zm1.183-.278a.221.221,0,0,1-.2.112h-.048c-.107,0-.121-.024-.069-.116l.194-.375a.213.213,0,0,1,.233-.139h.045c.126,0,.145.027.074.134Z" transform="translate(-239.621 -97.806)" fill="#008ed4"/>
      <path id="Path_31" data-name="Path 31" d="M240.635,101.59c-.7,0-1.023-.311-1.023-.978v-.432a.976.976,0,0,1,.255-.734,1.079,1.079,0,0,1,.768-.245c.693,0,1.016.311,1.016.979v.432A.884.884,0,0,1,240.635,101.59Zm0-2.379a1.067,1.067,0,0,0-.762.242.966.966,0,0,0-.252.727v.432c0,.661.322.968,1.014.968a1.056,1.056,0,0,0,.755-.242.969.969,0,0,0,.251-.727v-.432C241.642,99.519,241.322,99.211,240.635,99.211Zm0,2.05c-.487,0-.677-.182-.677-.649v-.432c0-.474.184-.65.677-.65s.669.176.669.65v.432C241.3,101.085,241.123,101.261,240.635,101.261Zm0-1.721c-.487,0-.667.173-.667.64v.432c0,.46.187.64.667.64s.66-.173.66-.64v-.432C241.3,99.713,241.117,99.54,240.635,99.54Zm.446-.556h-.038a.183.183,0,0,1-.194-.109l-.107-.18c-.072-.127-.141-.127-.212,0l-.1.18a.187.187,0,0,1-.2.109h-.04c-.052,0-.08-.008-.09-.027s0-.045.027-.088l.175-.274c.111-.169.177-.229.332-.229s.22.045.334.229l.176.274c.03.043.038.07.029.088S241.137,98.984,241.082,98.984Zm-.445-.395c.038,0,.077.034.115.1l.107.18a.174.174,0,0,0,.186.1h.038c.05,0,.076-.006.084-.021s0-.039-.029-.079l-.175-.274c-.112-.18-.176-.224-.326-.224s-.213.056-.324.224l-.176.274c-.026.038-.034.063-.026.078s.034.022.082.022h.04a.177.177,0,0,0,.188-.1l.1-.18C240.56,98.623,240.6,98.59,240.636,98.59Zm.654-.152h-.048c-.059,0-.087-.008-.1-.026s0-.047.026-.1l.194-.375a.218.218,0,0,1,.237-.141h.045c.07,0,.1.009.116.031s0,.054-.038.111l-.23.385A.228.228,0,0,1,241.291,98.437Zm.311-.63a.207.207,0,0,0-.229.136l-.194.375c-.027.047-.034.073-.025.088s.036.021.09.021h.048a.217.217,0,0,0,.2-.109l.23-.384c.035-.053.047-.084.037-.1s-.041-.025-.107-.025Z" transform="translate(-239.612 -97.797)" fill="#008ed4"/>
    </g>
    <g id="Group_12" data-name="Group 12" transform="translate(23.266 1.404)">
      <path id="Path_32" data-name="Path 32" d="M247.391,104.125c0,.125-.024.149-.15.149h-.05c-.116,0-.15-.024-.15-.149v-1.316c0-.581.325-.868.969-.868.684,0,.966.306.966.868v1.316c0,.125-.024.149-.15.149h-.055c-.126,0-.15-.024-.15-.149v-1.316c0-.372-.142-.539-.612-.539s-.62.172-.62.539Z" transform="translate(-247.037 -101.936)" fill="#008ed4"/>
      <path id="Path_33" data-name="Path 33" d="M248.818,104.269h-.055c-.129,0-.154-.026-.154-.153V102.8c0-.389-.165-.534-.608-.534s-.615.149-.615.534v1.316c0,.128-.026.153-.155.153h-.05c-.116,0-.154-.022-.154-.153V102.8c0-.579.327-.872.973-.872a1.046,1.046,0,0,1,.743.225.842.842,0,0,1,.228.648v1.316C248.972,104.243,248.946,104.269,248.818,104.269ZM248,102.256c.45,0,.617.147.617.544v1.316c0,.122.021.143.145.143h.055c.123,0,.145-.021.145-.143V102.8a.833.833,0,0,0-.225-.641,1.037,1.037,0,0,0-.736-.222,1.06,1.06,0,0,0-.722.215.827.827,0,0,0-.241.648v1.316c0,.125.033.143.144.143h.05c.123,0,.145-.021.145-.143V102.8C247.377,102.408,247.552,102.256,248,102.256Z" transform="translate(-247.028 -101.927)" fill="#008ed4"/>
    </g>
    <g id="Group_13" data-name="Group 13" transform="translate(25.629 1.208)">
      <path id="Path_34" data-name="Path 34" d="M255.97,102.419c0,.538-.318.807-.959.807a2.078,2.078,0,0,0-.491.037.137.137,0,0,0-.112.139c0,.065.038.107.117.13a2.583,2.583,0,0,0,.487.029,1.527,1.527,0,0,1,.793.143.53.53,0,0,1,.223.492.618.618,0,0,1-.228.552,1.42,1.42,0,0,1-.788.158,1.46,1.46,0,0,1-.8-.158.561.561,0,0,1-.218-.487.542.542,0,0,1,.261-.5.316.316,0,0,1-.19-.306c0-.2.123-.325.375-.362a.652.652,0,0,1-.389-.677v-.056c0-.506.356-.8.964-.8a1.177,1.177,0,0,1,.477.093l.266-.236c.078-.07.114-.07.19,0l.031.032c.076.07.076.107,0,.182l-.209.213a.672.672,0,0,1,.2.52Zm-1.413,1.423a.387.387,0,0,0-.218.38c0,.3.171.381.672.381.482,0,.667-.08.667-.394,0-.292-.171-.343-.667-.343A3.473,3.473,0,0,1,254.557,103.842Zm1.061-1.479c0-.329-.2-.491-.608-.491s-.617.163-.617.491v.056c0,.334.149.5.617.5s.608-.167.608-.5Z" transform="translate(-253.985 -101.359)" fill="#008ed4"/>
      <path id="Path_35" data-name="Path 35" d="M255,104.9a1.453,1.453,0,0,1-.805-.159.565.565,0,0,1-.22-.491.547.547,0,0,1,.256-.5.319.319,0,0,1-.185-.306c0-.2.122-.325.362-.364a.662.662,0,0,1-.377-.674v-.056a.743.743,0,0,1,.256-.595,1.1,1.1,0,0,1,.713-.213,1.19,1.19,0,0,1,.476.092l.263-.234c.08-.072.119-.072.2,0l.031.033c.041.038.058.064.059.091s-.017.056-.059.1l-.205.209a.675.675,0,0,1,.2.52v.056c0,.539-.324.812-.964.812a2.082,2.082,0,0,0-.49.036.133.133,0,0,0-.108.134c0,.063.036.1.113.125a2.64,2.64,0,0,0,.486.028,1.522,1.522,0,0,1,.8.145.532.532,0,0,1,.225.5.624.624,0,0,1-.23.556A1.427,1.427,0,0,1,255,104.9Zm0-3.344c-.6,0-.959.3-.959.8v.056a.651.651,0,0,0,.387.672l.016.007-.017,0c-.25.036-.371.153-.371.357a.311.311,0,0,0,.187.3l.008,0-.008,0a.538.538,0,0,0-.259.492.556.556,0,0,0,.216.483,1.448,1.448,0,0,0,.8.157,1.421,1.421,0,0,0,.785-.157.615.615,0,0,0,.226-.548.523.523,0,0,0-.221-.487,1.515,1.515,0,0,0-.79-.143,2.588,2.588,0,0,1-.488-.029c-.081-.023-.12-.067-.12-.134a.142.142,0,0,1,.115-.143,2.058,2.058,0,0,1,.493-.037,1.1,1.1,0,0,0,.717-.2.746.746,0,0,0,.237-.6v-.056a.665.665,0,0,0-.2-.516l0,0,0,0,.209-.213c.04-.039.056-.066.055-.09s-.016-.049-.055-.085l-.031-.033c-.074-.068-.106-.068-.183,0l-.268.238h0A1.171,1.171,0,0,0,255,101.556Zm0,3.042c-.519,0-.677-.09-.677-.386a.391.391,0,0,1,.221-.384h0a3.49,3.49,0,0,0,.453.023c.5,0,.672.053.672.348S255.51,104.6,255,104.6Zm-.453-.761a.382.382,0,0,0-.215.375c0,.288.156.376.667.376s.662-.091.662-.389-.165-.338-.662-.338A3.479,3.479,0,0,1,254.549,103.837Zm.453-.922c-.442,0-.622-.147-.622-.506v-.056c0-.334.2-.5.622-.5s.613.167.613.5v.056C255.614,102.773,255.442,102.915,255,102.915Zm0-1.048c-.412,0-.612.159-.612.487v.056c0,.352.178.5.612.5s.6-.139.6-.5v-.056C255.6,102.031,255.4,101.867,255,101.867Z" transform="translate(-253.976 -101.35)" fill="#008ed4"/>
    </g>
    <g id="Group_14" data-name="Group 14" transform="translate(29.268 0.491)">
      <path id="Path_36" data-name="Path 36" d="M267.132,99.827c0,.1-.033.13-.131.13h-.278v1.624c0,.645-.337.969-1.016.969-.7,0-1.014-.324-1.014-.969v-.432a.855.855,0,0,1,.959-.978.754.754,0,0,1,.724.338v-.552h-.632c-.107,0-.131-.032-.131-.13V99.79c0-.1.024-.131.131-.131h.632v-.254c0-.126.021-.148.147-.148h.052c.114,0,.147.022.147.148v.254H267c.1,0,.131.033.131.131Zm-2.092,1.749c0,.445.154.645.667.645s.669-.2.669-.645v-.432c0-.445-.164-.645-.669-.645s-.667.2-.667.645Z" transform="translate(-264.689 -99.252)" fill="#008ed4"/>
      <path id="Path_37" data-name="Path 37" d="M265.7,102.543a1.06,1.06,0,0,1-.772-.244.966.966,0,0,1-.247-.729v-.432a1.02,1.02,0,0,1,.24-.737.976.976,0,0,1,.724-.245.768.768,0,0,1,.719.325v-.529h-.627c-.1,0-.135-.03-.135-.134V99.78c0-.1.031-.135.135-.135h.627V99.4c0-.129.025-.153.152-.153h.052c.114,0,.152.022.152.153v.249h.273c.1,0,.135.035.135.135v.037c0,.1-.035.134-.135.134h-.273v1.619C266.719,102.216,266.376,102.543,265.7,102.543Zm-.054-2.379a.965.965,0,0,0-.717.243,1.009,1.009,0,0,0-.238.73v.432a.957.957,0,0,0,.244.723,1.052,1.052,0,0,0,.765.241,1.066,1.066,0,0,0,.76-.241.96.96,0,0,0,.252-.723V99.941h.283c.1,0,.126-.03.126-.124V99.78c0-.1-.031-.125-.126-.125h-.283V99.4c0-.125-.032-.143-.142-.143h-.052c-.123,0-.142.02-.142.143v.259h-.637c-.107,0-.126.033-.126.125v.037c0,.091.019.124.126.124h.637v.576l-.009-.016A.752.752,0,0,0,265.644,100.165Zm.054,2.05c-.49,0-.672-.176-.672-.649v-.432c0-.467.188-.65.672-.65s.675.182.675.65v.432C266.373,102.032,266.184,102.215,265.7,102.215Zm0-1.721c-.477,0-.662.179-.662.64v.432c0,.466.179.64.662.64s.665-.173.665-.64v-.432C266.363,100.667,266.183,100.494,265.7,100.494Z" transform="translate(-264.68 -99.242)" fill="#008ed4"/>
    </g>
    <g id="Group_15" data-name="Group 15" transform="translate(31.765 1.404)">
      <path id="Path_38" data-name="Path 38" d="M272.04,103.347v-.432c0-.648.337-.973,1.018-.973.515,0,.807.172.938.506.074-.032.14-.121.14-.372,0-.069.026-.088.1-.088h.081c.078,0,.1.019.1.088,0,.376-.15.557-.363.622a1.565,1.565,0,0,1,.014.217v.432q0,.974-1.012.973C272.336,104.32,272.04,103.982,272.04,103.347Zm1.683-.432c0-.445-.159-.645-.665-.645s-.672.2-.672.645v.432c0,.445.169.645.672.645s.665-.2.665-.645Z" transform="translate(-272.035 -101.936)" fill="#008ed4"/>
      <path id="Path_39" data-name="Path 39" d="M273.048,104.316c-.7,0-1.023-.311-1.023-.978v-.432a.976.976,0,0,1,.255-.734,1.078,1.078,0,0,1,.768-.245c.5,0,.8.16.94.5.089-.042.133-.161.133-.365,0-.07.027-.093.109-.093h.081c.08,0,.1.02.1.093,0,.34-.122.55-.363.625a1.529,1.529,0,0,1,.014.214v.432A.884.884,0,0,1,273.048,104.316Zm0-2.379a1.067,1.067,0,0,0-.761.242.965.965,0,0,0-.252.727v.432c0,.661.322.968,1.014.968a1.055,1.055,0,0,0,.755-.242.967.967,0,0,0,.252-.727v-.432a1.573,1.573,0,0,0-.014-.217v0h0c.239-.072.36-.28.36-.617,0-.067-.018-.083-.092-.083h-.081c-.077,0-.1.019-.1.083,0,.211-.047.334-.143.376l0,0,0,0C273.847,102.1,273.551,101.937,273.048,101.937Zm0,2.05c-.487,0-.677-.182-.677-.649v-.432c0-.474.183-.65.677-.65s.669.176.669.65v.432C273.718,103.811,273.537,103.987,273.048,103.987Zm0-1.721c-.486,0-.667.173-.667.64v.432c0,.46.187.64.667.64s.66-.173.66-.64v-.432C273.708,102.439,273.529,102.266,273.048,102.266Z" transform="translate(-272.025 -101.927)" fill="#008ed4"/>
    </g>
    <g id="Group_16" data-name="Group 16" transform="translate(34.287 1.404)">
      <path id="Path_40" data-name="Path 40" d="M279.8,104.125c0,.125-.024.149-.15.149h-.05c-.116,0-.15-.024-.15-.149v-1.316c0-.581.325-.868.969-.868.684,0,.966.306.966.868v1.316c0,.125-.024.149-.15.149h-.054c-.126,0-.15-.024-.15-.149v-1.316c0-.372-.142-.539-.613-.539s-.619.172-.619.539Z" transform="translate(-279.45 -101.936)" fill="#008ed4"/>
      <path id="Path_41" data-name="Path 41" d="M281.231,104.269h-.055c-.129,0-.154-.026-.154-.153V102.8c0-.389-.165-.534-.608-.534s-.615.149-.615.534v1.316c0,.128-.026.153-.154.153h-.05c-.116,0-.154-.022-.154-.153V102.8c0-.579.327-.872.973-.872a1.046,1.046,0,0,1,.743.225.842.842,0,0,1,.228.648v1.316C281.385,104.243,281.36,104.269,281.231,104.269Zm-.817-2.013c.45,0,.617.147.617.544v1.316c0,.122.021.143.144.143h.055c.123,0,.145-.021.145-.143V102.8a.832.832,0,0,0-.225-.641,1.036,1.036,0,0,0-.736-.222,1.06,1.06,0,0,0-.723.215.827.827,0,0,0-.241.648v1.316c0,.125.033.143.145.143h.05c.123,0,.145-.021.145-.143V102.8C279.79,102.408,279.965,102.256,280.414,102.256Z" transform="translate(-279.441 -101.927)" fill="#008ed4"/>
    </g>
    <g id="Group_17" data-name="Group 17" transform="translate(37.888 1.208)">
      <path id="Path_42" data-name="Path 42" d="M292.028,102.419c0,.538-.318.807-.959.807a2.078,2.078,0,0,0-.491.037.137.137,0,0,0-.112.139c0,.065.038.107.116.13a2.583,2.583,0,0,0,.487.029,1.528,1.528,0,0,1,.793.143.53.53,0,0,1,.223.492.617.617,0,0,1-.228.552,1.421,1.421,0,0,1-.788.158,1.46,1.46,0,0,1-.8-.158.561.561,0,0,1-.218-.487.542.542,0,0,1,.261-.5.315.315,0,0,1-.19-.306c0-.2.123-.325.375-.362a.653.653,0,0,1-.39-.677v-.056c0-.506.356-.8.964-.8a1.177,1.177,0,0,1,.477.093l.266-.236c.078-.07.114-.07.19,0l.031.032c.076.07.076.107,0,.182l-.209.213a.672.672,0,0,1,.2.52Zm-1.412,1.423a.388.388,0,0,0-.219.38c0,.3.171.381.672.381.482,0,.667-.08.667-.394,0-.292-.171-.343-.667-.343A3.462,3.462,0,0,1,290.615,103.842Zm1.061-1.479c0-.329-.2-.491-.608-.491s-.617.163-.617.491v.056c0,.334.15.5.617.5s.608-.167.608-.5Z" transform="translate(-290.043 -101.359)" fill="#008ed4"/>
      <path id="Path_43" data-name="Path 43" d="M291.059,104.9a1.453,1.453,0,0,1-.805-.159.566.566,0,0,1-.22-.491.548.548,0,0,1,.256-.5.319.319,0,0,1-.185-.306c0-.2.122-.325.363-.364a.661.661,0,0,1-.377-.674v-.056a.743.743,0,0,1,.256-.595,1.1,1.1,0,0,1,.713-.213,1.192,1.192,0,0,1,.477.092l.263-.234c.08-.072.119-.072.2,0l.031.033c.041.038.058.064.058.091s-.016.056-.059.1l-.205.209a.676.676,0,0,1,.2.52v.056c0,.539-.324.812-.964.812a2.086,2.086,0,0,0-.49.036.133.133,0,0,0-.108.134c0,.063.036.1.113.125a2.638,2.638,0,0,0,.485.028,1.525,1.525,0,0,1,.8.145.532.532,0,0,1,.225.5.623.623,0,0,1-.23.556A1.428,1.428,0,0,1,291.059,104.9Zm0-3.344c-.6,0-.959.3-.959.8v.056a.651.651,0,0,0,.387.672l.016.007-.017,0c-.25.036-.371.153-.371.357a.312.312,0,0,0,.187.3l.008,0-.008,0a.538.538,0,0,0-.259.492.556.556,0,0,0,.217.483,1.446,1.446,0,0,0,.8.157,1.421,1.421,0,0,0,.785-.157.614.614,0,0,0,.226-.548.522.522,0,0,0-.221-.487,1.515,1.515,0,0,0-.791-.143,2.585,2.585,0,0,1-.488-.029c-.081-.023-.12-.067-.12-.134a.142.142,0,0,1,.115-.143,2.056,2.056,0,0,1,.493-.037,1.1,1.1,0,0,0,.717-.2.745.745,0,0,0,.237-.6v-.056a.664.664,0,0,0-.2-.516l0,0,0,0,.209-.213c.04-.039.056-.066.056-.09s-.017-.049-.056-.085l-.031-.033c-.074-.069-.106-.068-.183,0l-.268.238h0A1.171,1.171,0,0,0,291.059,101.556Zm0,3.042c-.518,0-.677-.09-.677-.386a.391.391,0,0,1,.221-.384h0a3.484,3.484,0,0,0,.453.023c.5,0,.672.053.672.348C291.732,104.537,291.505,104.6,291.059,104.6Zm-.453-.761a.382.382,0,0,0-.215.375c0,.288.156.376.667.376s.663-.091.663-.389-.166-.338-.663-.338A3.483,3.483,0,0,1,290.607,103.837Zm.453-.922c-.442,0-.622-.147-.622-.506v-.056c0-.334.2-.5.622-.5s.613.167.613.5v.056C291.672,102.773,291.5,102.915,291.059,102.915Zm0-1.048c-.412,0-.612.159-.612.487v.056c0,.352.177.5.612.5s.6-.139.6-.5v-.056C291.663,102.031,291.46,101.867,291.059,101.867Z" transform="translate(-290.034 -101.35)" fill="#008ed4"/>
    </g>
    <g id="Group_18" data-name="Group 18" transform="translate(40.338 0.509)">
      <path id="Path_44" data-name="Path 44" d="M297.71,99.537a.22.22,0,0,1-.223.222.225.225,0,0,1-.233-.222.229.229,0,0,1,.233-.228A.224.224,0,0,1,297.71,99.537Zm-.4.862c0-.115.021-.148.147-.148h.052c.114,0,.147.033.147.148v1.989c0,.125-.033.149-.147.149h-.052c-.126,0-.147-.024-.147-.149Z" transform="translate(-297.249 -99.304)" fill="#008ed4"/>
      <path id="Path_45" data-name="Path 45" d="M297.5,102.531h-.052c-.128,0-.152-.024-.152-.153v-1.989c0-.115.022-.153.152-.153h.052c.117,0,.152.036.152.153v1.989C297.653,102.509,297.615,102.531,297.5,102.531Zm-.052-2.286c-.123,0-.142.032-.142.143v1.989c0,.124.02.143.142.143h.052c.108,0,.142-.019.142-.143v-1.989c0-.113-.031-.143-.142-.143Zm.029-.491a.23.23,0,0,1-.237-.227.233.233,0,0,1,.237-.233.228.228,0,0,1,.228.233A.225.225,0,0,1,297.477,99.754Zm0-.45a.223.223,0,0,0-.228.223.22.22,0,0,0,.228.217.215.215,0,0,0,.219-.217A.219.219,0,0,0,297.477,99.3Z" transform="translate(-297.24 -99.294)" fill="#008ed4"/>
    </g>
    <g id="Group_19" data-name="Group 19" transform="translate(41.24 0.324)">
      <path id="Path_46" data-name="Path 46" d="M301.749,101.491c0,.514-.3.732-.971.732-.532,0-.871-.214-.871-.7s.316-.677.947-.677h.546v-.279c0-.315-.214-.422-.608-.422a2.255,2.255,0,0,0-.51.051c-.109.023-.147,0-.147-.116v-.037c0-.107.029-.134.14-.158a2.411,2.411,0,0,1,.5-.047c.648,0,.973.241.973.728Zm-.349-.367h-.541c-.413,0-.6.112-.6.4,0,.265.171.394.52.394.425,0,.624-.1.624-.445Zm-.541-1.493c-.088,0-.1-.032-.112-.088-.014-.13.069-.185.209-.334a.164.164,0,0,0,.059-.107c0-.078-.038-.116-.083-.12a.429.429,0,0,0-.2.041.087.087,0,0,1-.126-.051l-.019-.037c-.026-.07.019-.094.067-.116a.641.641,0,0,1,.273-.056.3.3,0,0,1,.325.338c0,.2-.271.287-.283.441a.1.1,0,0,1-.086.088Z" transform="translate(-299.902 -98.759)" fill="#008ed4"/>
      <path id="Path_47" data-name="Path 47" d="M300.769,102.219c-.573,0-.876-.242-.876-.7s.294-.681.952-.681h.541v-.274c0-.292-.18-.416-.6-.416a2.224,2.224,0,0,0-.509.051.144.144,0,0,1-.12-.01c-.023-.019-.034-.053-.034-.112v-.037c0-.107.027-.138.144-.163a2.447,2.447,0,0,1,.5-.046c.649,0,.978.246.978.732v.919C301.745,101.991,301.444,102.219,300.769,102.219Zm.076-1.372c-.652,0-.943.207-.943.672s.3.691.867.691a1.224,1.224,0,0,0,.734-.172.642.642,0,0,0,.233-.555v-.919a.636.636,0,0,0-.242-.542,1.216,1.216,0,0,0-.727-.18,2.427,2.427,0,0,0-.5.046c-.112.024-.136.051-.136.153v.037c0,.055.009.087.029.1s.056.02.112.008a2.221,2.221,0,0,1,.511-.052c.429,0,.612.128.612.426v.284Zm-.078,1.07c-.353,0-.525-.131-.525-.4,0-.286.182-.409.608-.409h.546v.358C301.4,101.842,301.155,101.917,300.767,101.917Zm.083-.8c-.419,0-.6.119-.6.4,0,.262.169.389.515.389.452,0,.62-.119.62-.44v-.347Zm0-1.494c-.093,0-.1-.036-.116-.091-.012-.111.044-.168.145-.271l.065-.067a.154.154,0,0,0,.058-.1c0-.08-.04-.112-.079-.115H300.9a.433.433,0,0,0-.181.042.091.091,0,0,1-.133-.053l-.019-.037c-.029-.076.025-.1.069-.123a.637.637,0,0,1,.275-.056.307.307,0,0,1,.33.343.368.368,0,0,1-.157.26.316.316,0,0,0-.125.181.107.107,0,0,1-.089.092Zm.052-.659h.021c.042,0,.088.038.088.124a.17.17,0,0,1-.061.11l-.065.067c-.1.1-.154.157-.142.263.014.054.021.083.107.083h.021a.1.1,0,0,0,.08-.083.324.324,0,0,1,.129-.187.363.363,0,0,0,.154-.254.326.326,0,0,0-.083-.245.323.323,0,0,0-.238-.088.638.638,0,0,0-.271.055c-.052.025-.087.047-.064.111l.019.036a.082.082,0,0,0,.12.049A.444.444,0,0,1,300.9,98.968Z" transform="translate(-299.893 -98.75)" fill="#008ed4"/>
    </g>
    <g id="Group_20" data-name="Group 20" transform="translate(43.574 1.404)">
      <path id="Path_48" data-name="Path 48" d="M307.12,104.125c0,.125-.024.149-.15.149h-.05c-.116,0-.15-.024-.15-.149v-1.316c0-.581.325-.868.969-.868.684,0,.966.306.966.868v1.316c0,.125-.024.149-.15.149H308.5c-.126,0-.15-.024-.15-.149v-1.316c0-.372-.142-.539-.613-.539s-.619.172-.619.539Z" transform="translate(-306.766 -101.936)" fill="#008ed4"/>
      <path id="Path_49" data-name="Path 49" d="M308.547,104.269h-.054c-.129,0-.154-.026-.154-.153V102.8c0-.389-.165-.534-.608-.534s-.615.149-.615.534v1.316c0,.128-.026.153-.154.153h-.05c-.116,0-.154-.022-.154-.153V102.8c0-.579.328-.872.973-.872a1.045,1.045,0,0,1,.743.225.841.841,0,0,1,.228.648v1.316C308.7,104.243,308.676,104.269,308.547,104.269Zm-.817-2.013c.45,0,.617.147.617.544v1.316c0,.122.022.143.145.143h.054c.123,0,.145-.021.145-.143V102.8a.833.833,0,0,0-.225-.641,1.038,1.038,0,0,0-.736-.222,1.059,1.059,0,0,0-.722.215.826.826,0,0,0-.241.648v1.316c0,.125.032.143.144.143h.05c.123,0,.145-.021.145-.143V102.8C307.106,102.408,307.281,102.256,307.73,102.256Z" transform="translate(-306.757 -101.927)" fill="#008ed4"/>
    </g>
    <g id="Group_21" data-name="Group 21" transform="translate(47.335 0.491)">
      <path id="Path_50" data-name="Path 50" d="M317.832,99.405c0-.126.031-.148.147-.148h.052c.124,0,.147.022.147.148v1.08a.811.811,0,0,1,.729-.316c.57,0,.857.27.857.812v1.372c0,.125-.024.149-.147.149h-.057c-.123,0-.147-.024-.147-.149v-1.33c0-.367-.164-.525-.593-.525-.446,0-.641.177-.641.6v1.252c0,.125-.023.149-.147.149h-.052c-.116,0-.147-.024-.147-.149Z" transform="translate(-317.827 -99.252)" fill="#008ed4"/>
      <path id="Path_51" data-name="Path 51" d="M319.608,102.5h-.057c-.126,0-.152-.026-.152-.153v-1.331c0-.369-.17-.52-.589-.52-.446,0-.636.179-.636.6v1.252c0,.127-.025.153-.152.153h-.052c-.114,0-.152-.022-.152-.153V99.4c0-.132.036-.153.152-.153h.052c.127,0,.152.026.152.153v1.065a.827.827,0,0,1,.724-.3c.572,0,.862.275.862.817v1.372C319.76,102.471,319.735,102.5,319.608,102.5Zm-.8-2.013c.425,0,.6.153.6.529v1.331c0,.122.021.143.142.143h.057c.121,0,.142-.021.142-.143v-1.372c0-.535-.287-.807-.852-.807a.811.811,0,0,0-.725.313l-.009.013V99.4c0-.122-.021-.143-.142-.143h-.052c-.112,0-.142.017-.142.143v2.948c0,.125.032.143.142.143h.052c.121,0,.142-.021.142-.143v-1.252C318.164,100.666,318.358,100.484,318.81,100.484Z" transform="translate(-317.818 -99.242)" fill="#008ed4"/>
    </g>
    <g id="Group_22" data-name="Group 22" transform="translate(49.761 1.404)">
      <path id="Path_52" data-name="Path 52" d="M324.968,103.347v-.432c0-.648.337-.973,1.019-.973.515,0,.807.172.938.506.073-.032.14-.121.14-.372,0-.069.026-.088.1-.088h.081c.079,0,.1.019.1.088,0,.376-.15.557-.363.622a1.56,1.56,0,0,1,.014.217v.432q0,.974-1.011.973C325.265,104.32,324.968,103.982,324.968,103.347Zm1.683-.432c0-.445-.159-.645-.665-.645s-.672.2-.672.645v.432c0,.445.168.645.672.645s.665-.2.665-.645Z" transform="translate(-324.963 -101.936)" fill="#008ed4"/>
      <path id="Path_53" data-name="Path 53" d="M325.977,104.316c-.7,0-1.023-.311-1.023-.978v-.432a.976.976,0,0,1,.255-.734,1.078,1.078,0,0,1,.768-.245c.5,0,.8.16.94.5.089-.042.133-.161.133-.365,0-.07.027-.093.109-.093h.081c.081,0,.1.02.1.093,0,.34-.122.55-.363.625a1.6,1.6,0,0,1,.014.214v.432A.884.884,0,0,1,325.977,104.316Zm0-2.379a1.068,1.068,0,0,0-.762.242.966.966,0,0,0-.252.727v.432c0,.661.322.968,1.014.968a1.056,1.056,0,0,0,.755-.242.967.967,0,0,0,.251-.727v-.432a1.542,1.542,0,0,0-.014-.217v0h0c.239-.072.36-.28.36-.617,0-.067-.018-.083-.093-.083h-.081c-.077,0-.1.019-.1.083,0,.211-.047.334-.143.376l0,0,0,0C326.776,102.1,326.48,101.937,325.977,101.937Zm0,2.05c-.487,0-.677-.182-.677-.649v-.432c0-.474.183-.65.677-.65s.669.176.669.65v.432C326.647,103.811,326.465,103.987,325.977,103.987Zm0-1.721c-.486,0-.667.173-.667.64v.432c0,.46.187.64.667.64s.66-.173.66-.64v-.432C326.637,102.439,326.459,102.266,325.977,102.266Z" transform="translate(-324.954 -101.927)" fill="#008ed4"/>
    </g>
    <g id="Group_23" data-name="Group 23" transform="translate(52.282 1.404)">
      <path id="Path_54" data-name="Path 54" d="M332.733,104.125c0,.125-.024.149-.15.149h-.05c-.117,0-.15-.024-.15-.149v-1.316c0-.581.325-.868.969-.868.684,0,.966.306.966.868v1.316c0,.125-.024.149-.15.149h-.055c-.126,0-.15-.024-.15-.149v-1.316c0-.372-.142-.539-.612-.539s-.62.172-.62.539Z" transform="translate(-332.379 -101.936)" fill="#008ed4"/>
      <path id="Path_55" data-name="Path 55" d="M334.16,104.269h-.055c-.129,0-.154-.026-.154-.153V102.8c0-.389-.165-.534-.608-.534s-.615.149-.615.534v1.316c0,.128-.026.153-.154.153h-.05c-.116,0-.154-.022-.154-.153V102.8c0-.579.327-.872.973-.872a1.045,1.045,0,0,1,.743.225.841.841,0,0,1,.228.648v1.316C334.314,104.243,334.289,104.269,334.16,104.269Zm-.817-2.013c.45,0,.617.147.617.544v1.316c0,.122.022.143.145.143h.055c.123,0,.145-.021.145-.143V102.8a.832.832,0,0,0-.225-.641,1.037,1.037,0,0,0-.736-.222,1.059,1.059,0,0,0-.722.215.827.827,0,0,0-.241.648v1.316c0,.125.033.143.145.143h.05c.123,0,.145-.021.145-.143V102.8C332.719,102.408,332.894,102.256,333.343,102.256Z" transform="translate(-332.37 -101.927)" fill="#008ed4"/>
    </g>
  </g>
</svg>

);

export default ArrowDownSVG;
