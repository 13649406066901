import {
    Button,
    Divider,
    Breadcrumb,
    Card,
    Row,
    Col,
    message,
    Space,
    Avatar,
    Collapse,
    Progress,
    Checkbox,
    Popconfirm,
    Tooltip,
    Form,
    Typography,
    Dropdown,
    Menu,
} from 'antd';
import {
    LeftOutlined,
    FormOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusCircleOutlined,
    SettingOutlined,
    MobileOutlined,
    SkypeOutlined,
    MailOutlined,
} from '@ant-design/icons';
import React from 'react';
import { getUser } from '~/utils/Common';
import { Markup } from 'interweave';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import * as tasksServices from '~/services/tasksService';
import * as searchServices from '~/services/searchService';
import * as Libs from '~/components/myLibs';
import classNames from 'classnames/bind';
import styles from './Tasks.module.scss';
import ModalEditTodo from './modalEditTodo';
import ModalAddnewTodo from './modalAddnewTodo';
import Comments from './comments';
import Logs from './logs';
import moment from 'moment';
import QuillEditor from '~/components/QuillEditor';
import DropdownStatus from '~/components/Ant/Dropdown_status';
import NoDataWithLink from '~/components/Empty/EmptyWithLink';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;
const cx = classNames.bind(styles);
const { Panel } = Collapse;
const currentUser = getUser();
function Detail() {
	const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const [formdes] = Form.useForm();
    let navigate = useNavigate();
    const { taskId } = useParams();
    const [task, setTask] = useState([]);
    const [taskStatus, setTaskStatus] = useState([]);
    const [todo, setTodo] = useState([]);
    const [comments, setComments] = useState([]);
    const [logs, setlogs] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [activeTabKey2, setActiveTabKey2] = useState('taskComments');
    const [addnewTodoModal, setAddnewTodoModal] = useState(false); // ẩn hiện modal
    const [editTodoModal, setEditTodoModal] = useState(false); // ẩn hiện modal
    const [editDescription, setEditDescription] = useState(true); // edit vùng description
    const [checkcount, setCheckcount] = useState(''); // đếm số lượng todo đã xong
    const [percentTodo, setpercentTodo] = useState(0); // % todo đã làm
    const [sendRequest, setSendRequest] = useState(false);
    //  const [listemailassign, setListemailassign] = useState([]);
    const [listUsersAssign, setListUsersAssign] = useState([]);
    const dataSend = [];

    const handleSubmitCmt = async (value) => {
        if (!value) return;
        dataSend.taskid = taskId;
        dataSend.author = currentUser.email;
        dataSend.content = value.content;
        const result = await tasksServices.sendcomment(dataSend);
        message.info(result.message);
        setSendRequest(true);
    };
    const contentListNoTitle = {
        taskComments: <Comments mycomments={comments} onSubmit={handleSubmitCmt} />,
        taskLogs: <Logs data={logs} />,
    };

    const onTab2Change = (key) => {
        setActiveTabKey2(key);
    };
    useEffect(() => {
        if (sendRequest) {
            setSendRequest(false);
        }
        const fetchApiTaskDetail = async () => {
            const result = await tasksServices.tasksbyid(taskId);
            setTask(result);
            if (result.task_assign_id) {
                const fetchUsersByIds = async () => {
                    const result1 = await searchServices.listusersbyids(result.task_assign_id);
                    setListUsersAssign(result1);
                };
                fetchUsersByIds();
            }

            form.setFieldsValue({
                task_status: result.task_status,
            });
        };
        fetchApiTaskDetail();

        const ApiTodo = async () => {
            const result = await tasksServices.todobytask(taskId);
            setTodo(result);
            setCheckcount(result.filter((props) => props.completed).length);
            setpercentTodo((result.filter((props) => props.completed).length / result.length) * 100);
        };
        ApiTodo();

        const ApiComment = async () => {
            const result = await tasksServices.commentsbytask(taskId);
            setComments(result);
        };
        ApiComment();

        const ApiLogs = async () => {
            const result = await tasksServices.logsbytask(taskId);
            setlogs(result);
        };
        ApiLogs();

        const getApiStatus = async () => {
            const result = await searchServices.taskstatus();
            setTaskStatus(result);
        };
        getApiStatus();
    }, [sendRequest, taskId, form, formdes]);

    const backToProject = () => {
        navigate(`/projects/${task.task_project}`);
    };
    const editTask = () => {
        return navigate(`/edittask/${task.id}`);
    };
    const tabListNoTitle = [
        {
            key: 'taskComments',
            tab: t('Comments.1'),
        },
        {
            key: 'taskLogs',
            tab: t('Logs.1'),
        },
    ];
    const onAddnewTodo = async (values) => {
        values.pid = taskId;
        values.duedate = moment(values.deadline).format('YYYY-MM-DD');
        setAddnewTodoModal(false);
        const result = await tasksServices.addtodo(values);
        message.info(result.message);
        setSendRequest(true);
    };
    const editTodo = (value) => {
        setEditTodoModal(true);
        var item = todo.find((item) => item.id === value);
        setDataEdit(item);
    };
    const onEditTodo = async (values) => {
        values.id = dataEdit.id;
        values.duedate = moment(values.deadline).format('YYYY-MM-DD');
        setEditTodoModal(false);
        const result = await tasksServices.edittodo(values);
        message.info(result.message);
        setSendRequest(true);
    };
    const confirmDeleteTask = async (e) => {
        const result = await tasksServices.deletetodo(e);
        message.info(result.message);
        const toDelete = new Set([e]);
        Libs.filterInPlace(todo, (obj) => !toDelete.has(obj.id)); // xóa phần tử trong mảng có id = e
        setTodo(todo);
        setSendRequest(true);
    };
    const onChange = async (e) => {
        todo[todo.findIndex((obj) => obj.id === e.target.value)].completed =
            !todo[todo.findIndex((obj) => obj.id === e.target.value)].completed;
        const result = await tasksServices.updateStatusTodo(e.target.value);
        message.info(result.message);
        setpercentTodo((todo.filter((props) => props.completed).length / todo.length) * 100);
        setCheckcount(todo.filter((props) => props.completed).length); //đếm số lượng đã check
    };
    const genExtra = () => (
        <Button
            size="small"
            type="dashed"
            icon={<PlusCircleOutlined />}
            onClick={(event) => {
                event.stopPropagation();
                setAddnewTodoModal(true);
            }}
        >
            {t('Add todo.1')}
        </Button>
    );
    const editDes = (editDescription) => {
        if (editDescription) {
			formdes.setFieldsValue({
                task_des: task.task_des,
            });
            return (
                <Button
                    size="small"
                    type="dashed"
                    icon={<SettingOutlined />}
                    onClick={(event) => {
                        event.stopPropagation();
                        setEditDescription(!editDescription);
                    }}
                >
                     {t('Edit.1')}
                </Button>
            );
        } else {
            return (
                <Space>
                    <Button
                        type="button"
                        onClick={(event) => {
                            event.stopPropagation();
                            setEditDescription(!editDescription);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={(event) => {
                            formdes.submit();
                            event.stopPropagation();
                            setEditDescription(!editDescription);
                        }}
                    >
                        Save
                    </Button>
                </Space>
            );
        }
    };

    const onFinishEditDes = async (values) => {
        if (values.task_des !== task.task_des) {
            let value1 = [];
            value1 = { id: taskId, key: 'task_des', value: values.task_des };
            const result = await tasksServices.updateFieldTask(value1);
            message.info(result.message);
            setSendRequest(true);
        } else {
            message.warning('Không sửa gì thì đừng bấm linh tinh nhé');
        }
    };
    const onChangeTaskname = async (values) => {
        if (values !== task.task_name) {
            let value1 = [];
            value1 = { id: taskId, key: 'task_name', value: values };
            const result = await tasksServices.updateFieldTask(value1);
            message.info(result.message);
            setSendRequest(true);
        } else {
            message.warning('Không sửa gì thì đừng bấm linh tinh nhé');
        }
    };
    const updateStatus = async (e) => {
        let value1 = [];
        value1 = { id: taskId, key: 'task_status', value: e, author: currentUser.email };
        const result = await tasksServices.updateFieldTask(value1);
        message.info(result.message);
        setSendRequest(true);
    };
    const handleButtonClick = (e) => {
        message.info('Click on left button.');
        console.log('click left button', e);
    };

    const handleMenuClick = (e) => {
        console.log('click', e);
    };
    const menu = (
        <Menu
            onClick={handleMenuClick}
            items={[
                {
                    label: task.mobile,
                    key: '1',
                    icon: <MobileOutlined />,
                },
                {
                    label: <a href={`skype:${task.skyper}?chat`}> {task.skyper} </a>,
                    key: '2',
                    icon: <SkypeOutlined />,
                },
                {
                    label: <a href={`mailto:${task.email_canbo}`}> {task.email_canbo} </a>,
                    key: '3',
                    icon: <MailOutlined />,
                },
            ]}
        />
    );

    return (
        <>
            <ModalAddnewTodo
                visible={addnewTodoModal}
                onCreate={onAddnewTodo}
                onCancel={() => {
                    setAddnewTodoModal(false);
                }}
                usersAssign={listUsersAssign}
            />
            <ModalEditTodo
                visible={editTodoModal}
                onCreate={onEditTodo}
                onCancel={() => {
                    setEditTodoModal(false);
                }}
                dataEdit={dataEdit}
                usersAssign={listUsersAssign}
            />

            <Row gutter={16}>
                <Col className="gutter-row" span={16}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">{t('Home.1')}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/projects/${task.task_project}`}>{task.prj_name}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{t('Task detail.1')}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Typography.Title level={4} editable={{ onChange: onChangeTaskname }}>
                        {task.task_name}
                    </Typography.Title>
                    <Space>
                        <Button onClick={editTask} icon={<FormOutlined />}>
						{t('Edit task.1')}
                        </Button>
                    </Space>

                    <Divider />
                    <Collapse defaultActiveKey={['1']} className="mb-24" expandIconPosition="end">
                        <Panel header={t('Infomations.1')} key="1">
                            <Row gutter={[16, 16]}>
                                <Col className="gutter-row" span={4}>
									{t('Project.1')}:
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Link to={`/projects/${task.task_project}`}>{task.prj_name}</Link>
                                </Col>
                                <Col className="gutter-row" span={4}>
									{t('Status.1')}:
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <div className="no-label" style={{ marginTop: '-5px' }}>
                                        <Form form={form}>
                                            <DropdownStatus data={taskStatus} onOk={updateStatus} bordered={false} />
                                        </Form>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={4}>
									{t('Create at.1')}:
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    {task.create_at}
                                </Col>
                                <Col className="gutter-row" span={4}>
                                    Tags:
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    {Libs.string2ArrWithLink(task.task_tag)}
                                </Col>
                                <Col className="gutter-row" span={4}>
									{t('Deadline.1')}:
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    {task.thoigian}
                                </Col>
                                <Col className="gutter-row" span={4}>
									{t('Email.1')}:
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    {task.task_emailyc === '' ? (
                                        <Text type="warning">No data</Text>
                                    ) : (
                                        <a target="_blank" rel="noreferrer" href={task.task_emailyc}>
                                            Link to email
                                        </a>
                                    )}
                                </Col>
                                <Col className="gutter-row" span={4}>
									{t('Assign.1')}:
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    {listUsersAssign ? (
                                        <Avatar.Group>
                                            {listUsersAssign.map((e) => (
                                                <Tooltip title={e.last_name} placement="top" key={e.id}>
                                                    <Avatar src={e.avatar} size="small" />
                                                </Tooltip>
                                            ))}
                                        </Avatar.Group>
                                    ) : (
                                        <></>
                                    )}

                                    {task.last_name}
                                </Col>
                                <Col className="gutter-row" span={4}>
									{t('Office staff.1')}:
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    {task.canboyeucau === 'Chọn cán bộ' ? (
                                        <Text type="warning">No data selected</Text>
                                    ) : (
                                        <Tooltip placement="top" title={task.motacanbo}>
                                            <Dropdown.Button onClick={handleButtonClick} overlay={menu} size="small">
                                                {task.canboyeucau}
                                            </Dropdown.Button>
                                        </Tooltip>
                                    )}
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                    <Collapse defaultActiveKey={['2']} className="mb-24" expandIconPosition="end">
                        <Panel header={t('Descriptions.1')} key="2" extra={editDes(editDescription)}>
                            <Row>
                                <Col>
                                    {editDescription ? (
                                        <>
                                            {task.task_des ? (
                                                <>
                                                    <div className="descriptionBox">
                                                        <Markup content={task.task_des} />
                                                    </div>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <div className="no-label">
                                                <Form name="nest-messages" onFinish={onFinishEditDes} form={formdes}>
                                                    <QuillEditor data={task.task_des} />
                                                </Form>
                                            </div>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                    <Collapse defaultActiveKey={['todolist']} className="mb-24" expandIconPosition="end">
                        <Panel header={t('Todo list.1')} key="todolist" extra={genExtra()}>
                            {todo.length > 0 && (
                                <>
                                    Definition of done {checkcount} / {todo.length}
                                    <Progress
                                        className="mb-24"
                                        percent={Math.floor(percentTodo)}
                                        strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
                                    />
                                </>
                            )}
                            <div className="detaillisttodo">
                                {todo.length > 0 ? (
                                    <>
                                        <table className="w-100">
                                            <tbody>
                                                {todo.map((e) => (
                                                    <tr key={e.id}>
                                                        <td>
                                                            <Checkbox
                                                                onChange={onChange}
                                                                checked={e.completed}
                                                                value={e.id}
                                                                disabled={!currentUser}
                                                            >
                                                                <h4 className={cx(e.completed ? `iscomplete` : '')}>
                                                                    {e.content}
                                                                </h4>
                                                            </Checkbox>
                                                        </td>
                                                        <td style={{ width: 35 }}>
                                                            <Avatar src={e.avatar} size="small" />
                                                        </td>
                                                        <td style={{ width: 75 }} className="text-right">
                                                            {e.deadline}
                                                        </td>
                                                        {currentUser && (
                                                            <td style={{ width: 66 }} className="text-right">
                                                                <Space>
                                                                    <Button
                                                                        shape="circle"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            editTodo(e.id);
                                                                        }}
                                                                        icon={<EditOutlined />}
                                                                    ></Button>
                                                                    <Popconfirm
                                                                        title={`delete task?`}
                                                                        onConfirm={() => {
                                                                            confirmDeleteTask(e.id);
                                                                        }}
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button
                                                                            shape="circle"
                                                                            size="small"
                                                                            icon={<DeleteOutlined />}
                                                                        ></Button>
                                                                    </Popconfirm>
                                                                </Space>
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </>
                                ) : (
									<NoDataWithLink 
										text1={t('No data todo yet, plzz.1')} 
										text2={t('to create new one.1')} 
										textbtn={t('click here.1')} 
										onclick={() => {
										setAddnewTodoModal(true);
									}} />
                                )}
                            </div>
                        </Panel>
                    </Collapse>
                </Col>
                <Col className="gutter-row" span={8}>
                    <Card
                        style={{
                            width: '100%',
                        }}
                        tabList={tabListNoTitle}
                        activeTabKey={activeTabKey2}
                        onTabChange={(key) => {
                            onTab2Change(key);
                        }}
                    >
                        {contentListNoTitle[activeTabKey2]}
                    </Card>
                </Col>
            </Row>

            <div className={cx('wrapper')}>
                <div className={cx('footer')}>
                    <Button htmlType="button" icon={<LeftOutlined />} onClick={backToProject}>
						{t('Back.1')}
                    </Button>
                </div>
            </div>
        </>
    );
}

export default Detail;
