import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tag, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function TasksByUser({tasks, loading}) {
	const { t, i18n } = useTranslation();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title:t('Task name.1'),
            dataIndex: 'task_name',
            key: 'task_name',
            width: '60%',
            ...getColumnSearchProps('task_name'),
            render: (text, key) => <Link to={`/tasks/${key.key}`}>{text}</Link>,
        },
        {
            title:t('Project.1'),
            dataIndex: 'prj_name',
            key: 'prj_name',
            ...getColumnSearchProps('prj_name'),
            sorter: (a, b) => a.prj_name.length - b.prj_name.length,
            sortDirections: ['descend', 'ascend'],
            render: (text, key) => (
                <Link to={`/projects/${key.prj_name}`} className="text-black">
                    {text}
                </Link>
            ),
        },
        {
            title:t('Duedate.1'),
            dataIndex: 'thoigian',
            key: 'thoigian',
            sorter: (a, b) => a.thoigian - b.thoigian,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title:t('Status.1'),
            dataIndex: 'task_status',
            id: 'task_status',
            render: (task_status) => <Tag className={`ant-tag-${task_status}`}>{task_status}</Tag>,
            sorter: (a, b) => a.task_status.length - b.task_status.length,
            sortDirections: ['descend', 'ascend'],
        },
    ];

    return (
        <div>
            <h2>{t('Tasks list.1')}</h2>
            <Spin spinning={loading}>
                <Table columns={columns} dataSource={tasks} />
            </Spin>
        </div>
    );
}
export default TasksByUser;
