
const images = {
    logo: require('~/assets/images/logo.svg').default,
    noImage: require('~/assets/images/no-image.png'),
    loading: require('~/assets/images/loading'),
	empty: require('~/assets/images/empty.svg'),
	vietflag: require('~/assets/images/vi.png'),
	enflag: require('~/assets/images/en.png')
};

export default images;