import { Button, Form, Input, Divider, Breadcrumb, Space } from 'antd';
import { LeftOutlined, SaveOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import * as searchServices from '~/services/searchService';
import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 8,
    },
};

function AddNewPr() {
    const navigate = useNavigate();
    const onFinish = async (values) => {
        console.log(values.newuser);
        const result = await searchServices.addUser(values); console.log(result)
        //  navigate(`/projects`, { state: result });
    };
    const cancelEdit = () => {
        navigate(`/projects`);
    };

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/users">Users</Link>
                </Breadcrumb.Item>
            </Breadcrumb>
            <h3>Add new user</h3>
            <Divider />
            <div className="rounder">
                <Form {...layout} name="newuser" onFinish={onFinish}>
                    <Form.Item label="Name"  rules={[{ required: true, message: 'Missing name' }]}>
                        <Space style={{ display: 'flex', marginBottom: '0px' }} align="baseline">
                            <Form.Item
                                name={['newuser', 'first_name']}
                                rules={[{ required: true, message: 'Missing first name' }]}
                                style={{ marginBottom: '0px' }}
                            >
                                <Input placeholder="First Name" />
                            </Form.Item>
                            <Form.Item
                                name={['newuser', 'last_name']}
                                rules={[{ required: true, message: 'Missing last name' }]}
                                style={{ marginBottom: '0px' }}
                            >
                                <Input placeholder="Last Name" />
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item
                        name={['newuser', 'username']} label="User Name" rules={[{ required: true, message: 'insert user name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name={['newuser', 'email']} label="Email" rules={[{ required: true, type: 'email' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={['newuser', 'password']} label="Password" rules={[{ required: true, message: 'insert email' }]}>
                        <Input.Password
                            placeholder="input password"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                        <div className="d-flex space-between">
                            <Button htmlType="button" icon={<LeftOutlined />} onClick={cancelEdit}>
                                Back
                            </Button>
                            <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                                Submit
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
}
export default AddNewPr;
