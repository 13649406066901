import { getUser } from '~/utils/Common';
import { Avatar, Button, Comment, Form, Input, List } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <div className="mycmtlist">
    <List
      dataSource={comments}
      itemLayout="horizontal"
    />
  </div>
);



const Comments = ({mycomments, onSubmit}) => {
  const { t, i18n } = useTranslation();
  const currentUser = getUser();
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  
  const Editor = ({ onChange, onSubmit, value }) => (
	<>
	  <Form.Item>
		<TextArea rows={4} onChange={onChange} value={value} />
	  </Form.Item>
	  <Form.Item>
		<Button htmlType="submit" onClick={onSubmit} type="primary">
		{t('Add Comment.1')}
		</Button>
	  </Form.Item>
	</>
  );

  const handleSubmit = (dataSend) => {
    if (!value) return;
    setSubmitting(true);
    dataSend.author = currentUser.username;
    dataSend.email = currentUser.email;
    dataSend.content = value;
    onSubmit(dataSend);
    setValue('')
  };
  const handleChange = (e) => {
    setValue(e.target.value);
    console.log(e.target.value);
  };

  return (
    <>
      {mycomments.length > 0 && <CommentList comments={mycomments} />}
      {currentUser ? (
        <Comment
          avatar={<Avatar src={currentUser.avatar} alt="" />}
          content={
            <Editor onChange={handleChange} onSubmit={handleSubmit} submitting={submitting} value={value} />
          }
        />
      ) : (
        <></>
      )}

    </>
  );
};

export default Comments;
