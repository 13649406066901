import { Button } from 'antd';
import React, { useEffect } from 'react';
import Welcome from '~/components/Welcome';
import { useNavigate } from 'react-router-dom';
// import style from './welcome.css';
function Home() {
	const navigate = useNavigate();
    const [showElement, setShowElement] = React.useState(false);
    useEffect(() => {
        setTimeout(function () {
            setShowElement(true);
        }, 6000);
    }, []);
	const tologin = () => {
        navigate('/login');
    };
    return (
        <>
            <Welcome />
            {showElement ? (
                <div className='d-flex actc'>
                    <div className="mb-24">
                        <h1>Chào mừng đến với vùng đất của<br/><span>Phòng Thiết Kế</span></h1>
                        <div class="boxwelcome-text">
							<div>
                            <Button type="primary" size="large" shape="round">
                                For Guest
                            </Button>
							<p>Bấm vào đây nếu bạn là Khách</p>
							</div>
							<div className='space24'></div>
							<div>
                            <Button type="primary" size="large" shape="round" onClick={tologin}>
                                For Designer Dept
                            </Button>
							<p>Bấm vào đây nếu bạn là Người nhà</p>
							</div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

export default Home;
