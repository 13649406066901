import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tag, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import React, { useEffect, useState, useRef } from 'react';
import { Grid, NavBar } from 'antd-mobile';
import { DemoBlock, getAvatarFromArr } from '~/components/until';

import { Link, useNavigate } from 'react-router-dom';
import * as tasksServices from '~/services/tasksService';
import * as userServices from '~/services/searchService';
import classNames from 'classnames/bind';
import styles from './Tasks.module.scss';
const cx = classNames.bind(styles);
function Tasks() {
	const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [tasks, setTasks] = useState([]);
    const [avatarlist, setAvatarlist] = useState([]);
    const [users, setUsers] = useState([]);
    useEffect(() => {
        const fetchApi = async () => {
            const result = await tasksServices.tasks();
            const resultArray = result.map((elm) => ({
                key: elm.id,
                task_name: elm.task_name,
                duan: elm.duan,
                duanId: elm.task_project,
                avatar: elm.avatar,
                thoigian: elm.thoigian,
                task_status: elm.task_status,
                task_assign: elm.task_assign,
                assign_id: elm.task_assign_id,
            }));
            setTasks(resultArray);
            setLoading(false);
        };
        fetchApi();
        const fetchApi1 = async () => {
            const result = await userServices.users();
            setUsers(result);
            const avatarArray = result.map((elm) => ({ text: elm.last_name, value: elm.avatar }));
            setAvatarlist(avatarArray);
        };
        fetchApi1();
    }, []);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title: 'Task name',
            dataIndex: 'task_name',
            key: 'task_name',
            width: '50%',
            ...getColumnSearchProps('task_name'),
            render: (text, key) => <Link to={`/tasks/${key.key}`}>{text}</Link>,
        },
        {
            title: 'Duedate',
            dataIndex: 'thoigian',
            key: 'thoigian',
            width: '20%',
            sorter: (a, b) => a.thoigian - b.thoigian,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Status',
            dataIndex: 'task_status',
            id: 'task_status',
            render: (task_status) => <Tag className={`ant-tag-${task_status}`}>{task_status}</Tag>,
            sorter: (a, b) => a.task_status.length - b.task_status.length,
            sortDirections: ['descend', 'ascend'],
        },
    ];
    const backtop = () => {
        return navigate(-1);
    };
    return (
        <div>
            <NavBar onBack={backtop} className="headermobile">
                List tasks
            </NavBar>
            <Spin spinning={loading}>
                <DemoBlock>
                    <Grid columns={1} gap={8}>
                        {tasks.map((e) => (
                            <Grid.Item key={e.key} className={cx('taskrounder')}>
                                <div className={styles['grid-demo-item-block']}>
                                    <Link to={`/tasks/${e.key}`} className={cx('tasktitle')}>
                                        {e.task_name}
                                    </Link>
                                    <div className={cx('footer')}>
                                        <div>
                                            <div className={cx('taskduedate')}>{e.thoigian}</div>
                                            <div>
                                                <Tag className={`ant-tag-${e.task_status}`}>{e.task_status}</Tag>
                                            </div>
                                        </div>
                                        <div className={cx('avatar_assign')}>
                                            {getAvatarFromArr(e.assign_id, users)}
                                        </div>
                                    </div>
                                </div>
                            </Grid.Item>
                        ))}
                    </Grid>
                </DemoBlock>

                {/* <Table columns={columns} dataSource={tasks} /> */}
            </Spin>
        </div>
    );
}
export default Tasks;
