import { Button, Form, Input, Select, DatePicker } from 'antd';
import { StopOutlined, SaveOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';
import * as tasksServices from '~/services/tasksService';
import * as searchServices from '~/services/searchService';
import * as projectsServices from '~/services/projectsService';
import * as canboServices from '~/services/canboService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DropdownStatus from '~/components/Ant/Dropdown_status';
import DropdownUser from '~/components/Ant/Dropdown_users';
import { useTranslation } from 'react-i18next';
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 16,
    },
};
const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link'],
        ['clean'],
    ],
};
function EditPr() {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const [users, setUsers] = useState([]);
    const [canbo, setCanbo] = useState([]);
    const [tags, setTags] = useState([]);
    const [taskStatus, setTaskStatus] = useState([]);
    const [project, setProject] = useState([]);
    const { Option } = Select;
    //  const [datechoice, setDatechoice] = useState(null);
    const [dateOrigin, setDateOrigin] = useState();
    const navigate = useNavigate();
    const dateFormat = 'DD/MM/YYYY';
    const { state } = useLocation();

    useEffect(() => {
        const data_prj = state;
        const fetchApi1 = async () => {
            const result = await projectsServices.projects();
            setProject(result);

            form.setFieldsValue({
                task_project: data_prj.pid,
            });
        };
        fetchApi1();

        const fetchApi2 = async () => {
            const result = await searchServices.users();
            setUsers(result);
        };
        fetchApi2();

        const getApiStatus = async () => {
            const result = await searchServices.taskstatus();
            setTaskStatus(result);
        };
        getApiStatus();

        const getApiTags = async () => {
            const result = await searchServices.getAllTags();
            setTags(result);
        };
        getApiTags();

        const listcanbo = async () => {
            const result = await canboServices.getallcanbo();
            setCanbo(result);
        };
        listcanbo();
    }, [form]);
    const getAssignIDFromArr = (data) => {
        var test = data.toString().split(',');
        let answer = users.filter((item) => test.includes(item.email)).map((item) => item.id);
        return answer;
    };
    const onFinish = async (values) => {
        if (values.task_tag) {
            values.task_tag = values.task_tag.sort().toString();
        } else {
            values.task_tag = '';
        }
        values.task_assign = values.task_assign.toLocaleString();
        values.task_assign_id = getAssignIDFromArr(values.task_assign.toLocaleString()).toLocaleString();
        values.task_duedate = moment(dateOrigin).format('YYYY-MM-DD');
        values.date_original = dateOrigin;
        const result = await tasksServices.addTask(values);
        navigate(`/projects/${values.task_project}`, { state: result });
    };
    const onChange = (date, dateString) => {
        // setDatechoice(dateString);
        setDateOrigin(date);
    };
    const handleSelectStatus = (value) => {
        console.log(`selected ${value}`);
    };
    const callfocus = () => {
        tasknameInputRef.current.focus();
        console.log('aaa');
    };
    const tasknameInputRef = React.useRef(null);
    React.useEffect(() => {
        tasknameInputRef.current.focus();
    }, []);
    return (
        <>
            <h2 className="mb-24" onClick={callfocus}>
                {t('Create new task.1')}
            </h2>

            <div className="rounder">
                <Form {...layout} name="nest-messages" onFinish={onFinish} form={form}>
                    <Form.Item
                        name="task_name"
                        label={t('Task name.1')}
                        rules={[{ required: true, message: 'Insert task name' }]}
                    >
                        <Input ref={tasknameInputRef} />
                    </Form.Item>
                    <Form.Item
                        name="task_project"
                        label={t('Project.1')}
                        rules={[{ required: true, message: 'Please select project' }]}
                    >
                        <Select showSearch optionFilterProp="children">
                            {project.map((e) => (
                                <Option key={e.id} value={e.id}>
                                    {e.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <DropdownUser data={users} labeltitle={t("Assign.1")} />

                    <Form.Item name="task_duedate" label={t('Duedate.1')}>
                        <DatePicker onChange={onChange} format={dateFormat} />
                    </Form.Item>

                    <DropdownStatus data={taskStatus} label={t('Status.1')} onOk={handleSelectStatus} />

                    <Form.Item name="task_des" label={t('Descriptions.1')} initialValue="">
                        <ReactQuill modules={modules} theme="snow" preserveWhitespace={true} error="" />
                    </Form.Item>
                    <Form.Item name="task_tag" label={t('Tags.1')}>
                        <Select showSearch optionFilterProp="children" mode="multiple" allowClear="true">
                            {tags.map((e) => (
                                <Option key={e.id} value={e.name}>
                                    {e.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="task_canboyc" label={t('Office staff.2')}>
                        <Select showSearch optionFilterProp="children">
                            {canbo.map((e) => (
                                <Option key={e.id} value={e.id}>
                                    {e.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="task_emailyc" label="Email yêu cầu">
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
                        <div className="d-flex space-between">
                            <Button htmlType="button" icon={<StopOutlined />} onClick={() => navigate(-1)}>
                                {t('Cancel.1')}
                            </Button>
                            <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                                {t('Save.1')}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
}
export default EditPr;
