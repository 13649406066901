import {
    LogoutOutlined,
    UserOutlined,
    SettingOutlined,
    BellOutlined,
    DiffOutlined,
    ScheduleOutlined,
} from '@ant-design/icons';
import { Dropdown, Select, Menu, Space, Badge, message, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getUser } from '~/utils/Common';
import classNames from 'classnames/bind';
import 'tippy.js/dist/tippy.css';
import config from '~/config';
import styles from './Header.module.scss';
import images from '~/assets/images';
import Image from '~/components/Image';
import Search from '../Search';
import Chatroom from '~/components/chatroom/chat';
import Note from '~/components/Note';
import * as logchatService from '~/services/searchService';
import SelectLanguage from './SelectLanguage';
import languageStore from '~/stores/languageStore';
//import { io } from 'socket.io-client';
import { useTranslation } from 'react-i18next';
const cx = classNames.bind(styles);
const { Option } = Select;
function Header() {
	const selectedLanguage = languageStore((state) => state.i18nextLng);
    const { t, i18n } = useTranslation();
    const [thongbao, setThongbao] = useState();// console.log(thongbao)
    const [sendRequest, setSendRequest] = useState(false);
    const currentUser = getUser();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [usersOnline, setUsersOnline] = useState([]); 
	//const [socket, setSocket] = useState(null);
    // useEffect(() => {
    //     setSocket(io(process.env.REACT_APP_BASE_URL));
    // }, []);
    // useEffect(() => {
    //     socket?.on('usersonline', (data) => {
    //         setUsersOnline(data);
    //     });
    //     socket?.on('thongbao', (data) => {
    //         setThongbao(data);
    //     });
    // }, [socket]);
    // useEffect(() => {
    // 	if(thongbao && currentUser){
    // 		message.success(thongbao);
    // 	}
    // 	if(currentUser){
    // 		socket?.emit('newUser', currentUser.email);
    // 	}
    // }, [socket, thongbao]);

    const [visible, setVisible] = useState(false);
    const tologin = () => {
        navigate(`/login`);
    };
    const createnewtask = () => {
        navigate(`/tasks/addnew`);
    };
    let linkprofile = '';
    if (currentUser) {
        linkprofile = `/users/${currentUser.email}`;
    }
    const menu1 = (
        <Menu
            items={[
                {
                    label: (
                        <Link to={linkprofile}>
                            <UserOutlined className={cx('icon')} /> {t('Profiles.1')}
                        </Link>
                    ),
                    className: cx('sub_menu_user'),
                    key: '0',
                },
                {
                    label: (
                        <Link to="/setting">
                            <SettingOutlined className={cx('icon')} /> {t('Setting.1')}
                        </Link>
                    ),
                    className: cx('sub_menu_user'),
                    key: '1',
                },
                {
                    type: 'divider',
                },
                {
                    label: (
                        <Link to="/logout" state={currentUser && { name: currentUser.username }}>
                            <LogoutOutlined className={cx('icon')} /> {t('Logout.1')}
                        </Link>
                    ),
                    className: cx('sub_menu_user'),
                    key: '3',
                },
            ]}
        />
    );
    const [notes, setNotes] = useState([]);
    useEffect(() => {
        if (sendRequest) {
            setSendRequest(false);
        }
        let isCanceled = false;
        const fetchApi = async () => {
            const result = await logchatService.notes(); // lấy notes
            if (!isCanceled) {
                setNotes(result);
            }
        };
		i18n.changeLanguage(selectedLanguage);
        fetchApi();
        //cleanup
        return () => {
            isCanceled = true;
        };
    }, [sendRequest, selectedLanguage]);
    const addNote = async (values) => {
        const result = await logchatService.sendnote(values);
        setSendRequest(true);
        message.success(result.message);
    };
    const deleteNote = async (values) => {
        const result = await logchatService.deletenote(values);
        setSendRequest(true);
        message.success(result.message);
    };
    return (
        <header className={cx('wrapper')}>
            <div className={cx('inner')}>
                <Link to={config.routes.dashboard} className={cx('logo-link')}>
                    <img src={images.logo} alt="VNPAY" />
                </Link>

                <Search />

                <div className={cx('actions')}>
                    {currentUser ? (
                        <>
                            <Button type="primary" shape="round" icon={<ScheduleOutlined />} onClick={createnewtask}>
                                {t('Create new task.1')}
                            </Button>
							<SelectLanguage />
                            <Button
                                shape="circle"
                                type="text"
                                className={cx('action-btn')}
                                onClick={() => setVisible(!visible)}
                            >
                                <DiffOutlined />
                            </Button>
                            <button className={cx('action-btn')} onClick={() => setOpen(!open)}>
                                <Badge count={5}>
                                    <BellOutlined />
                                </Badge>
                            </button>
                            {/* <Chatroom visible={open} handleOk={() => setOpen(!open)} currentUser={currentUser} usersOnline={usersOnline} /> */}
                            <Note
                                addNote={addNote}
                                deleteNote={deleteNote}
                                notes={notes}
                                visible={visible}
                                showDrawer={() => setVisible(!visible)}
                                onClose={() => setVisible(!visible)}
                            />
                        </>
                    ) : (
                        <>
                            <Button type="primary" onClick={tologin}>
                                Log in
                            </Button>
                        </>
                    )}
                    <Dropdown overlay={menu1}>
                        <a href="#1" type="link" onClick={(e) => e.preventDefault()}>
                            <Space>
                                {currentUser && (
                                    <Image
                                        className={cx('user-avatar')}
                                        src={currentUser.avatar}
                                        alt={currentUser.username}
                                    />
                                )}
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            </div>
        </header>
    );
}

export default Header;
