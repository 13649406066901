import create from 'zustand';

const languageStore = create((set, get) => ({
    i18nextLng: localStorage.getItem('i18nextLng') || 'vn',
    setI18nextLng: (value) => {
        set({ i18nextLng: value });
        localStorage.setItem('i18nextLng', value);
    },
}));

export default languageStore;
