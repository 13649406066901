import { ScheduleOutlined, SettingOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const SidebarAnt = () => {
	const { t, i18n } = useTranslation();
	const items = [
		getItem(t('Setting.1'), 'sub1', <SettingOutlined />, [
			getItem(('Phòng ban'), 'phongban'),
			getItem(t('Other office staff.1'), 'canbo'),
			getItem(t('User.1'), 'users'),
		]),
		getItem(t('Work manager.1'), 'sub2', <ScheduleOutlined />, [
			getItem(t('All projects.1'), 'projects'),
			getItem(t('All tasks.1'), 'tasks'),
		]),
		getItem(t('Report.1'), 'sub3', <FileSearchOutlined />, [
			getItem('Báo cáo tháng', 'report-month'),
			getItem('System logs', 'syslogs'),
		]),
		getItem('Demo', 'sub4', <ScheduleOutlined />, [
			getItem('Form Components', 'form-components'),
			getItem('Form Ant Components', 'ant-form-components'),
			getItem('Demo drag', 'demo'),
		]),
	];
    const navigate = useNavigate();
    const onClick = (e) => {
        navigate(`/${e.keyPath[0]}`);
    };
    return (
        <>
            <Menu
                onClick={onClick}
                defaultSelectedKeys={['1']}
               // defaultOpenKeys={['sub1', 'sub2', 'sub3']}
                mode="inline"
                items={items}
            />
        </>
    );
};

export default SidebarAnt;
