import { Divider } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { faCircleXmark, faSpinner, faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeadlessTippy from '@tippyjs/react/headless';

import * as searchServices from '~/services/searchService';
import { Wrapper as PopperWrapper } from '~/components/Popper';
import TaskItem from '~/components/TaskItemsSearch';
import ProjectItem from '~/components/ProjectItemsSearch';
import { SearchIcon } from '~/components/Icons';
import { useDebounce } from '~/hooks';
import styles from './Search.module.scss';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
const cx = classNames.bind(styles);

function Search() {
	const { t, i18n } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [searchTaskResult, setSearchTaskResult] = useState([]);
    const [searchProjectResult, setSearchProjectResult] = useState([]);
    const [showResult, setShowResult] = useState(false);
    const [loading, setLoading] = useState(false);

    const debouncedValue = useDebounce(searchValue, 500);

    const inputRef = useRef();
    //console.log('search')
    useEffect(() => {
        if (!debouncedValue.trim()) {
            setSearchTaskResult([]);
			setSearchProjectResult([]);
            return;
        }

        const fetchApi = async () => {
            setLoading(true);

            const result = await searchServices.searchTasks(debouncedValue);
            const result1 = await searchServices.searchProjects(debouncedValue);

            setSearchTaskResult(result);
            setSearchProjectResult(result1);
            setLoading(false);
        };

        fetchApi();
    }, [debouncedValue]);

    const handleClear = () => {
        setSearchValue('');
        setSearchTaskResult([]);
        setSearchProjectResult([]);
        inputRef.current.focus();
    };

    const handleHideResult = () => {
        setShowResult(false);
    };

    const handleChange = (e) => {
        const searchValue = e.target.value;
        if (!searchValue.startsWith(' ')) {
            setSearchValue(searchValue);
        }
    };

	const [users, setUsers] = useState([]);
	useEffect(()=>{ console.log('load')
		let isCanceled = false;
		const fetchAllUsers = async () => { console.log('loadusers')
			const result = await searchServices.users();
			if (!isCanceled) { setUsers(result)}
		  };
		  fetchAllUsers();
		  //cleanup
		  return () => {
			isCanceled = true
		  }
	},[])

    return (
        // Using a wrapper <div> tag around the reference element solves
        // this by creating a new parentNode context.
        <div>
            <HeadlessTippy
                interactive
                visible={showResult && (searchTaskResult.length > 0 || searchProjectResult.length > 0)}
                render={(attrs) => (
                    <div className={cx('search-result')} tabIndex="-1" {...attrs}>
                        <PopperWrapper>
                            {searchProjectResult.length > 0 ? (
                                <>
                                     <h4 className={cx('search-title')}><FontAwesomeIcon icon={faFolder} /> Projects list result</h4>
                                    <div className={cx('projectlist')}>
                                        {searchProjectResult.map((result) => (
                                            <ProjectItem key={result.id} data={result} />
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {searchTaskResult.length > 0 ? (
                                <>
								{searchProjectResult.length > 0 && <Divider dashed /> }
								<h4 className={cx('search-title')}><FontAwesomeIcon icon={faFolder} /> Tasks list result</h4>
                                    {searchTaskResult.map((result) => (
                                        <TaskItem key={result.id} data={result} users={users} handleHideResult={handleHideResult} />
                                    ))}
                                </>
                            ) : (
                                <></>
                            )}
                        </PopperWrapper>
                    </div>
                )}
                onClickOutside={handleHideResult}
            >
                <div className={cx('search')}>
                    <input
                        ref={inputRef}
                        value={searchValue}
                        placeholder= {t('Search Projects and Tasks.1')}
                        spellCheck={false}
                        onChange={handleChange}
                        onFocus={() => setShowResult(true)}
                    />
                    {!!searchValue && !loading && (
                        <button className={cx('clear')} onClick={handleClear}>
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </button>
                    )}
                    {loading && <FontAwesomeIcon className={cx('loading')} icon={faSpinner} />}

                    <button className={cx('search-btn')} onMouseDown={(e) => e.preventDefault()}>
                        <SearchIcon />
                    </button>
                </div>
            </HeadlessTippy>
        </div>
    );
}

export default Search;
