import { Button, Form, Input, Select, DatePicker } from 'antd';
import { StopOutlined, SaveOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';
import * as tasksServices from '~/services/tasksService';
import * as searchServices from '~/services/searchService';
import * as projectsServices from '~/services/projectsService';
import * as canboServices from '~/services/canboService';
import DropdownStatus from '~/components/Ant/Dropdown_status';
import DropdownUser from '~/components/Ant/Dropdown_users';
import QuillEditor from '~/components/QuillEditor';
import { useTranslation } from 'react-i18next';

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 16,
    },
};
function EditPr() {
	const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const [users, setUsers] = useState([]);
    const [canbo, setCanbo] = useState([]);
    const [task, setTask] = useState([]);
    const [tags, setTags] = useState([]);
    const [taskStatus, setTaskStatus] = useState([]);
    const [project, setProject] = useState([]);
    // const [listemailassign, setListemailassign] = useState([]);
    const { Option } = Select;
    const { taskId } = useParams();
    // const [datechoice, setDatechoice] = useState(null);
    const [dateOrigin, setDateOrigin] = useState(task.dateOrigin);
    const navigate = useNavigate();
    const dateFormat = 'DD/MM/YYYY';

    useEffect(() => {
        const fetchApi = async () => {
            const result = await tasksServices.tasksbyid(taskId);
            setTask(result);
            //  setListemailassign(stringWidthQuote(result.task_assign));
            var arr = result.task_assign.split(',');
            setDateOrigin(result.date_original);
            form.setFieldsValue({
                task_name: result.task_name,
                task_des: result.task_des,
                task_project: result.task_project,
                task_canboyc: result.task_canboyc,
                task_assign: arr,
                task_tag: result.task_tag.toLocaleString().split(","),
                task_status: result.task_status,
                task_emailyc: result.task_emailyc,
                task_duedate: moment(result.date_original),
            });
        };
        fetchApi();

        const fetchApi1 = async () => {
            const result = await projectsServices.projects();
            setProject(result);
        };
        fetchApi1();

        const fetchApi2 = async () => {
            const result = await searchServices.users();
            setUsers(result);
        };
        fetchApi2();

        // const fetchUsersByListEmail = async (listemailassign) => {
        //   const result = await searchServices.listusersbyemail(listemailassign);
        // };
        //fetchUsersByListEmail(listemailassign);

        const getApiStatus = async () => {
            const result = await searchServices.taskstatus();
            setTaskStatus(result);
        };
        getApiStatus();

        const getApiTags = async () => {
            const result = await searchServices.getAllTags();
            setTags(result);
        };
        getApiTags();

        const listcanbo = async () => {
            const result = await canboServices.getallcanbo();
            setCanbo(result);
        };
        listcanbo();
    }, [taskId, form]);
    const getAssignIDFromArr = (data) => {
        var test = data.toString().split(',');
        let answer = users
            .filter((item) => test.includes(item.email))
            .map((item) => item.id);
        return answer;
    };
    const onFinish = async (values) => {
        if (values.task_tag) {
             values.task_tag = values.task_tag.sort().toString();
        } else {
            values.task_tag = '';
        }
        values.task_assign = values.task_assign.toLocaleString();
        values.task_assign_id = getAssignIDFromArr(values.task_assign.toLocaleString()).toLocaleString();
        values.task_duedate = moment(dateOrigin).format('YYYY-MM-DD');
        values.date_original = dateOrigin;
        values.id = taskId;
        const result = await tasksServices.editTask(values);
        navigate(`/tasks/${taskId}`, { state: result });
    };
    const cancelEdit = () => {
        navigate(`/tasks/${taskId}`);
    };
    const onChange = (date, dateString) => {
        //   setDatechoice(dateString);
        setDateOrigin(date);
    };
    return (
        <>
            <h2 className="mb-24">{t('Edit.1')}</h2>

            <div className="rounder">
                <Form {...layout} name="nest-messages" onFinish={onFinish} form={form}>
                    <Form.Item
                        name="task_name"
                        label={t('Task name.1')}
                        rules={[{ required: true, message: 'Insert task name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="task_project"
                        label={t('Project.1')}
                        rules={[{ required: true, message: 'Please select project' }]}
                    >
                        <Select showSearch optionFilterProp="children">
                            {project.map((e) => (
                                <Option key={e.id} value={e.id}>
                                    {e.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <DropdownUser data={users} labeltitle={t('Assign.1')} />
                    <Form.Item name="task_duedate" label={t('Duedate.1')}>
                        <DatePicker onChange={onChange} format={dateFormat} />
                    </Form.Item>
                    <DropdownStatus label={t('Status.1')} data={taskStatus} />
                    <QuillEditor />
                    <Form.Item name="task_tag" label={t('Tags.1')}>
                        <Select showSearch optionFilterProp="children" mode="multiple" allowClear="true">
                            {tags.map((e) => (
                                <Option key={e.id} value={e.name}>
                                    {e.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="task_canboyc" label={t('Office staff.2')}>
                        <Select showSearch optionFilterProp="children">
                            {canbo.map((e) => (
                                <Option key={e.id} value={e.id}>
                                    {e.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="task_emailyc" label="Email yêu cầu">
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
                        <div className="d-flex space-between">
                            <Button htmlType="button" icon={<StopOutlined />} onClick={cancelEdit}>
								{t('Cancel.1')}
                            </Button>
                            <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
								{t('Save.1')}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
}
export default EditPr;
