import React from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

function MobileLayoutNull({ children }) {
    return (
        <div className={cx('app')}>
            <div className={cx('body')}>
                <div className={cx('content')}>{children}</div>
            </div>
        </div>
    );
}

export default MobileLayoutNull;
